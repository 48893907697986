import classNames from "classnames";
import {useState} from "react";

/**
 * @param {String} labelText
 * @param {[object]} comboboxData
 * @param {String} nameText comboboxData에서 가져올 text key
 * @param {String} valueText comboboxData에서 가져올 value key
 * @param {String} selectValue 현재 선택된 comboboxData의 value 값
 * @param {function (string, number) : void}  onChange 선택된 값, 인덱스를 파라미터로 받는 콜백함수입니다.
 * @param {string} className 퍼블리싱에서 "selectBox"와 같은 계층에서 쓰이는 값을 사용해야 합니다.
 * */
export const LabelCombobox2 = ({
                                   labelText = null,
                                   comboboxData,
                                   nameText = 'text',
                                   valueText = 'id',
                                   selectValue = 'none',
                                   onChange,
                                   className,
                               }) => {

    const [open, setOpen] = useState(false);

    const getValue = (item) => {
        return item?.[valueText]
    }

    const getText = (item) => {
        return item?.[nameText]
    }

    const findSelectedText = () => {
        return comboboxData.find(it => getValue(it) === selectValue)?.[nameText]
    }

    return <>
        {(labelText !== null) && <div className="line_title">{labelText}</div>}
        <div className="select__wrap item"
             onClick={()=> {
                 setOpen((old)=> !old);
             }}
        >
            <div className={classNames({
                [`selectBox ${className}`]: true,
                'on': open,
            })}>
                <div className="select">
                    <div className="select_text">
                        <div className="option">
                            <div className="option_text"><span>{findSelectedText()}</span></div>
                        </div>
                    </div>
                    <ul className="select_option">
                        {
                            comboboxData.map((it, index) => <li
                                    className={classNames({
                                        'selected': selectValue === getValue(it)
                                    })}
                                    onClick={()=> {
                                        onChange?.(getValue(it), index)
                                    }}
                                >
                                    <div className="option">
                                        <div className="option_text"><span>{getText(it)}</span></div>
                                    </div>
                                </li>
                            )
                        }
                    </ul>
                </div>
            </div>
        </div>
    </>

}
