import {KFASSOConfig} from "../config/KFASSOConfig";
import {useNavigate} from "react-router-dom";
import axios from "axios";
import useAESCrypto from "./useAESCrypto";
import {useSelector} from "react-redux";

export default function useSSO() {

    const login = useSelector(state => state.userReducer.login);
    const isSSOUser = useSelector(state => state.userReducer.isSSOUser);
    const navigate = useNavigate();
    const {encrypt} = useAESCrypto();
    // const cookies = useCookie();
    // const [cookies, setCookie, removeCookie] = useCookies(['sso-kfa']);

    const getApiKey = async () => {
        let res = await axios.get(`${KFASSOConfig.apiBaseURl}/api/cms/sso/getApiKey`,);
        return res?.data?.jobId;
    }

    const ssoLoginRequest = (returnUrl = '/') =>{
        let url = makeSSOLoginUrl({returnUrl})
        console.log(url);
        window.location.href = url;
    }

    const makeSSOLoginUrl = ({returnUrl = window.location.origin} = {}) => {
        return `${KFASSOConfig.apiBaseURl}/api/cms/sso/ssologin?returnUrl=${encodeURIComponent(returnUrl)}`;
    }

    const ssoLogin = async ({state, code}) => {
        let apiKey = await getApiKey();
        let param  = {
            state,
            code,
            redirect_url: `${window.location.origin}/sso/kfa/callback`,
            auth: encrypt(`${state};;${code};;${apiKey}`),
        }

        return await axios.post(
            `${KFASSOConfig.apiBaseURl}/api/cms/sso/login`,
            param,
        )
    }

    const ssoLogout = async () => {
        let id_token = login.data?.data?.jwt?.id_token;
        await staticSsoLogout(id_token);
    }

    const _goWithPost = (url, param) => {
        const form = document.createElement("form");
        form.action = url;
        form.method = "POST";

        let keys = Object.keys(param);
        for(let i in keys) {
            const key = keys[i]
            const value = param[key];
            const input = document.createElement("input");
            input.type = "text";
            input.name = key;
            input.value = value;
            form.appendChild(input);
        }

        document.body.appendChild(form);
        form.submit();
    }

    const goSsoSignUp = async (returnUrl) => {
        let successRedirect = makeSSOLoginUrl({
            returnUrl: `${window.location.origin}`,
        });

        _goWithPost(
            KFASSOConfig.signUpUrl,
            {
                returnUrl: returnUrl ?? successRedirect,
            })
    }

    const goSSOPasswordChange = (returnUrl) => {
        window.open(KFASSOConfig.passwordChangeUrl, '_blank')
        // window.location.href = KFASSOConfig.passwordChangeUrl;
        // _goWithPost(
        // KFASSOConfig.passwordChangeUrl,
        //     {
        //         returnUrl: returnUrl ?? window.location.href,
        //     })
    }

    const goSSOInfoChange = (returnUrl) => {
        window.open(KFASSOConfig.infoChangeUrl, '_blank')
        // window.location.href = KFASSOConfig.infoChangeUrl;
        // _goWithPost(
        // KFASSOConfig.passwordChangeUrl,
        //     {
        //         returnUrl: returnUrl ?? window.location.href,
        //     })
    }

    const goSSOResign = (returnUrl) => {
        window.open(KFASSOConfig.resignUrl, '_blank')
    }

    const goServiceList = () => {
        window.location.href = KFASSOConfig.serviceList;
    }


    const goSSOHome = () => {
        window.location.href = KFASSOConfig.ssoBaseUrl;
    }

    /**
     * 일반 회원 로그인 시 전환 안내 연결
     */
    const goSSOIntegrate = () => {
        window.location.href = `${KFASSOConfig.integrateUrl}?returnUrl=${encodeURIComponent(window.location.href)}`
    }


    /**
     * 메인 통합회원 전환 팝업 연결
     */
    const goSSOIntegrate2 = () => {
        let successRedirect = makeSSOLoginUrl({
            // returnUrl: `${window.location.origin}`,
        });
        window.location.href = `${KFASSOConfig.integrateUrl}?returnUrl=${encodeURIComponent(successRedirect)}`
    }


    return {
        getApiKey,
        ssoLoginRequest,
        ssoLogin,
        ssoLogout,
        goSsoSignUp,
        goSSOPasswordChange,
        goSSOInfoChange,
        goSSOResign,
        goServiceList,
        goSSOHome,
        goSSOIntegrate,
        goSSOIntegrate2,
        isSSOUser,
    }
}

export const makeSSOLoginUrl = ({returnUrl} = {}) => {
    let _returnUrl = returnUrl ?? window.location.href;
    return `${KFASSOConfig.apiBaseURl}/api/cms/sso/ssologin?prompt=none&returnUrl=${encodeURIComponent(_returnUrl)}`;
}

export const ssoSilentLogin = async () => {
    window.location.href = makeSSOLoginUrl();
}

export const staticSsoLogout = async (idToken) => {
    try{
        if(idToken != null){
            await axios.get(
                KFASSOConfig.endSessionUri,
                {
                    params: {
                        id_token_hint: idToken,
                        checkUri: 'test',
                    }
                }
            );
        }
    }
    catch(e) {
        console.error(e);
    }
}