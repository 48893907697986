import Config from "../config/config";

/**
 * keysInOrder 에 있는 key 를 갖는 object 를 계층적으로 생성합니다. valueToSet 에 있는 객체는 최하위 계층에 set 됩니다.
 * @param keysInOrder
 * @param valueToSet
 * @return {{[p: number]: *}}
 */
const generateHierarchyObject = (keysInOrder, valueToSet) => {
    let objToReturn = {
        [keysInOrder[keysInOrder.length - 1]]: valueToSet
    };
    for (let i = keysInOrder.length - 2; i >= 0; i--) {
        const keyInCurrentLevel = keysInOrder[i];
        objToReturn = {
            [keyInCurrentLevel]: objToReturn
        };
    }
    return objToReturn;
}

/**
 * keysInOrder에 나와있는 순서대로 참조해 마지막 key에 할당을 시도하고 없으면 key-value 쌍을 만듭니다.
 * 탐색대상인 key에 대응되는 value가 없는 계층을 발견하면 나머지 field들도 포함시켜줍니다.
 * @param object
 * @param {Array<string>} keysInOrder
 * @param valueToSet
 */
const setOrCreate = (object, keysInOrder, valueToSet) => {
    let objToTry = object;
    for (let i = 0; i < keysInOrder.length; i++) {
        // 키값에 해당하는 값이 없을 때
        const target = objToTry[keysInOrder[i]];
        if (target === undefined) {
            const objToAssign = {...generateHierarchyObject(keysInOrder.slice(i, keysInOrder.length), valueToSet), ...objToTry}
            return (i === 0)
                ? objToAssign
                : generateHierarchyObject(keysInOrder.slice(0, i), objToAssign);

        } else if(Array.isArray(target)){
            const objToAssign = {...generateHierarchyObject(keysInOrder.slice(i, keysInOrder.length), valueToSet), ...objToTry}
            target.push(objToAssign);
        } else {
            objToTry = objToTry[keysInOrder[i]];
        }
    }
    return object;
}

export const addInMustArray = (query, value) => {
    let newQuery = {
        ...query,
    }

    if(newQuery.bool == null){
        newQuery.bool = {

        }
    }

    if(newQuery.bool.must == null){
        newQuery.bool.must = [

        ];
    }

    newQuery.bool.must.push(value);
    return newQuery;
}

/**
 * 키워드와 기존 쿼리를 이용해 쿼리문을 만듭니다.
 * @param {string} keyword
 * @param {object} orgQuery
 * @return {object}
 */
export const getQueryWithSingleKeyword = (keyword, orgQuery) => {
    // 기존 쿼리가 있으면 기존 쿼리 사용
    let query = !orgQuery
        ? {"match_all": {}}
        : orgQuery;

    // bool의 'must'란을 업데이트하게 했습니다.
    if (keyword != null || keyword !== undefined) {
        delete query["match_all"];

        query = (setOrCreate(query, ['bool', 'must', 'term', 'searchngram'], keyword));
    }
    return query
}

/**
 * 연도와 기존 쿼리를 이용해 쿼리문을 만듭니다.
 * @param {string[]} years
 * @param {object} orgQuery
 * @return {object}
 */
export const getQueryWithYears = (years, orgQuery) => {
    // 기존 쿼리가 있으면 기존 쿼리 사용
    let query = !orgQuery
        ? {"match_all": {}}
        : orgQuery;

    // bool의 'must'란을 업데이트하게 했습니다.
    if (years != null && years.length !== 0) {
        delete query["match_all"];
        query = addInMustArray(query, TermCondition.anyInArr('searchngram', years.map(it => `|${it}|`)))
    }
    return query
}

/**
 * 경기와 기존 쿼리를 이용해 쿼리문을 만듭니다.
 * @param {string[]} leagues
 * @param {object} orgQuery
 * @return {object}
 */
export const getQueryWithLeague = (leagues, orgQuery) => {
    // 기존 쿼리가 있으면 기존 쿼리 사용
    let query = !orgQuery
        ? {"match_all": {}}
        : orgQuery;

    // bool의 'must'란을 업데이트하게 했습니다.
    if (leagues != null && leagues.length !== 0) {
        delete query["match_all"];
        // query?.['bool']?.['filter']?.['menuId'] = leagues
        // query.bool.filter.terms
        query = setOrCreate(query, ['bool', 'filter', 'terms', 'menuId'], null);
        query.bool.filter.terms.menuId = leagues;
    }
    return query
}

export const getQueryWithLeague2 = (leagues, orgQuery) => {
    let query = !orgQuery
        ? {"match_all": {}}
        : orgQuery;

    if (leagues != null || leagues !== undefined) {
        delete query["match_all"];
        // setOrCreate(query, ['bool', 'filter', 'terms'], {});
        if(query.bool == null){
            query.bool = {};
        }
        if(query.bool.filter == null){
            query.bool.filter = [];
        }
        query.bool.filter.push({
            terms: {
                'menuId': leagues
            }
        });
    }
    return query
}

/**
 * 경기와 기존 쿼리를 이용해 쿼리문을 만듭니다.
 * @param {string[]} events
 * @param {object} orgQuery
 * @return {object}
 */
export const getQueryWithEvents = (events, orgQuery) => {
    // 기존 쿼리가 있으면 기존 쿼리 사용
    let query = !orgQuery
        ? {"match_all": {}}
        : orgQuery;

    if (events != null && events.length !== 0) {
        delete query["match_all"];
        query = setOrCreate(query, ['bool', 'filter', 'terms', 'event.flag_arc'], []);
        query.bool.filter.terms['event.flag_arc'].push(...events);
    }
    return query
}

/**
 * 경기와 기존 쿼리를 이용해 쿼리문을 만듭니다.
 * @return {object}
 */
export const getQueryWithViewYn = (prefix, viewYn = "Y", orgQuery) => {
    // 기존 쿼리가 있으면 기존 쿼리 사용
    let query = !orgQuery
        ? {"match_all": {}}
        : orgQuery;

    // bool의 'must'란을 업데이트하게 했습니다.
    if (viewYn != null) {
        delete query["match_all"];
        // query?.['bool']?.['filter']?.['menuId'] = leagues
        // query.bool.filter.terms
        query = addInMustArray(query,{
            "term": {
                [`${prefix}.view_yn`]: viewYn
            }
        });
        // query = setOrCreate(query, ['bool', 'must', 'term', 'videocontent.view_yn'], viewYn);
        // query.bool.filter.terms.menuId = leagues;
    }
    return query
}

/**
 * 경기와 기존 쿼리를 이용해 쿼리문을 만듭니다.
 * @return {object}
 */
export const getQueryWith = (key, value, orgQuery) => {
    // 기존 쿼리가 있으면 기존 쿼리 사용
    let query = !orgQuery
        ? {"match_all": {}}
        : orgQuery;

    // bool의 'must'란을 업데이트하게 했습니다.
    if (value != null) {
        delete query["match_all"];
        // query?.['bool']?.['filter']?.['menuId'] = leagues
        // query.bool.filter.terms
        query = addInMustArray(query,{
            "term": {
                [key]: value
            }
        });
        // query = setOrCreate(query, ['bool', 'must', 'term', 'videocontent.view_yn'], viewYn);
        // query.bool.filter.terms.menuId = leagues;
    }
    return query
}


/**
 * 키워드와 기존 쿼리를 이용해 쿼리문을 만듭니다.
 * @param {string[]} keywords
 * @param {object} orgQuery
 * @return {object}
 */
export const getQueryWithKeywords = (keywords, orgQuery) => {
    // 기존 쿼리가 있으면 기존 쿼리 사용
    let query = !orgQuery
        ? {"match_all": {}}
        : orgQuery;

    // bool의 'must'란을 업데이트하게 했습니다.
    if (keywords != null || keywords !== undefined) {
        delete query["match_all"];
        query = (setOrCreate(query, ['bool', 'must'], keywords.map(keyword => TermCondition.keywordParam(keyword))));
    }
    return query
}

/**
 * 이벤트코드와 기존 쿼리를 이용해 쿼리문을 만듭니다.
 * @param {string[]} eventCodes
 * @param {object} orgQuery
 * @return {object}
 */
export const getQueryWithEventCodes = (eventCodes, orgQuery) => {
    let query = !orgQuery
        ? {"match_all": {}}
        : orgQuery;

    if (eventCodes != null || eventCodes !== undefined) {
        delete query["match_all"];
        // setOrCreate(query, ['bool', 'filter', 'terms'], {});
        if(query.bool == null){
            query.bool = {};
        }
        if(query.bool.filter == null){
            query.bool.filter = [];
        }
        query.bool.filter.push({
            terms: {
                'event.flag_arc': eventCodes
            }
        });
    }
    return query
}

/**
 * 키워드와 기존 쿼리를 이용해 쿼리문을 만듭니다.
 * @param {Condition[]} conditions
 * @param {object} orgQuery
 * @return {object}
 */
export const getQueryWithConditions = (conditions, orgQuery) => {
    // 기존 쿼리가 있으면 기존 쿼리 사용
    let query = !orgQuery
        ? {"match_all": {}}
        : orgQuery;

    // bool의 'must'란을 업데이트하게 했습니다.
    if (conditions != null || conditions !== undefined || conditions.length === 0) {
        delete query["match_all"];

        query = (setOrCreate(query, ['bool', 'must'], conditions));
    }
    return query
}

/**
 * 경기와 기존 쿼리를 이용해 쿼리문을 만듭니다.
 * @param prefix
 * @param {string} hashtag
 * @param {object} orgQuery
 * @return {object}
 */
export const getQueryWithHashtag = (prefix, hashtag = '', orgQuery) => {
    // 기존 쿼리가 있으면 기존 쿼리 사용
    let query = !orgQuery
        ? {"match_all": {}}
        : orgQuery;

    if (hashtag !== '') {
        delete query["match_all"];
        query = addInMustArray(query,{
            "term": {
                [`${prefix}.hashNm`]: hashtag
            }
        });
    }
    return query
}

class Condition {
}

export class RangeCondition {
    static greaterThan(fieldName, value) {
        return {
            "range": {
                [fieldName]: {
                    "gte": value
                }
            }
        }
    }

    static lowerThan(fieldName, value) {
        return {
            "range": {
                [fieldName]: {
                    "lte": value
                }
            }
        }
    }
}

/**
 * 입력한 텍스트로 검색을 위한 키워드 목록을 생성합니다.
 * @param {string} keywordText
 * @returns {[string]}
 */
export const makeSearchKeywords = (keywordText) => {
    return keywordText.split(/[ /]/).map(it => it.trim()).filter(it => it !== '');
}


export class TermCondition extends Condition {
    constructor(fieldName, value) {
        super();

        const synonyms = TermCondition.getWithSynonym(value);
        if(synonyms.length <= 1) {
            this.term = {
                [fieldName]: value
            }
        }
        else {
            this.bool = {
                should: synonyms.map(it => {
                    return {
                        term: {
                            [fieldName]: it
                        }
                    }
                })
            }
        }

        // console.log("getWithSynonym", TermCondition.getWithSynonym(value), this)
    }

    static getWithSynonym(keyword) {
        let targetSynonym = Config.synonym.find(it => it.includes(keyword))
        if(targetSynonym != null) {
            return targetSynonym
        }
        else {
            return [keyword]
        }
    }

    static keywordParam(value) {
        return new TermCondition("searchngram", value.toLowerCase().substring(0, 10));
    }

    static anyInArr(fieldName, values) {
        return {
            terms: {
                [fieldName]: values
            }
        }
    }
}