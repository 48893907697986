import {useEffect, useState} from "react";
import DataTable from "../grid/DataTable";
import PageableModel from "../../../apis/dto/PageableModel";
import {makeThumbnailUrl, scheme} from "../../../util/url";
import {useNavigate} from "react-router-dom";
import {deleteFavoriteContent} from "../../../modules/userReducer";
import {useDispatch, useSelector} from "react-redux";
import GalleryModal2 from "../modal/GalleryModal2";

/**
 * @param favoriteMedia
 * @param {'image' | 'video'} mode
 * @param onPageMove
 * @param onDelete
 * @return {JSX.Element}
 * @constructor
 */
export default function FavoriteMediaTable({favoriteMedia, mode, onPageMove, onDelete}) {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const rowKeyGetter = (row) => row.cid;
    const loginData = useSelector(state => state.userReducer.loginData);
    const [currentImage, setCurrentImage] = useState(null);
    const [rows, setRows] = useState([]);
    const [selectedRows, setSelectedRows] = useState(new Set([]));
    const imageColumns = [
        // {key: "imgSize", name: "사이즈"}
        {key: "thumbnail", name: "이미지"},
    ]
    const videoColumns = [
        {key: "match_year", name: "년도"},
        {key: "match_date", name: "겅기일자", width: '15%'},
        {key: "menu02", name: "리그명"},
    ]

    const columns = [
        {key: "_itemNo", name: "번호"},
        {key: "regDt", name: "관심등록날짜"},
        // {key: "thumbnail", name: "이미지"},
        ...(mode === "image"
            ? imageColumns
            : mode === "video"
                ? videoColumns
                : null),
        {key: "menu01", name: "분류"},
        {key: "title", name: "내용", width: '30%'},
    ];

    useEffect(() => {
        if(favoriteMedia != null){
            setRows(createRows());
        }
    }, [favoriteMedia])

    const createRows = () => {
        return favoriteMedia?.content?.map((media) => ({
            ...media,
            regDt: new Date(media.regDt).format("yyyy-MM-dd HH:mm:ss"),
            thumbnail: <img style={{maxHeight: '100px'}} src={makeThumbnailUrl(media)}/>,
            // imgSize: <span style={{color: "red"}}>사이즈 데이터가 없습니다!</span>,
        }))
    }

    const handleRowClick = (row) => {
        console.log(row)
        if(row.contentType == 'V'){
            navigate(`/video/${row.cid}`)
        }
        else if(row.contentType == 'E'){
            navigate(`/video/${row.cid}`)
        }
        else if(row.contentType == 'I'){
            setCurrentImage(row);
        }
    }

    return <div className="order__item">
        <div className="content_body">
            <DataTable
                className={mode === "image" ? "favor2P_table" : mode === "video" ? "favor_table" : ""}
                rows={rows}
                rowKeyGenerate={rowKeyGetter}
                selectedRows={selectedRows}
                setSelectedRows={setSelectedRows}
                onMovePage={onPageMove}
                columns={columns}
                pageableModel={favoriteMedia}
                checkBoxInFirstColumn={false}
                onRowClick={handleRowClick}
            >
                <button
                    className={`btn btn-default ${selectedRows.size === 0 ? "inactive" : ""}`}
                    onClick={() => {
                        onDelete(selectedRows)
                    }}
                >
                    삭제
                </button>
            </DataTable>
        </div>
        {
            currentImage != null &&
            <GalleryModal2
                index={0}
                list={[currentImage]}
                onClose={e => setCurrentImage(null)}
                user={loginData}
            />
        }
    </div>
}
