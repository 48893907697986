/**
 *
 * @param {[{data, loading: bool, error}]}states
 * @return {loading: bool, error: bool}
 */
import {useEffect, useState} from "react";
import {popLoading, putLoading} from "../../modules/loadingReducer";
import {useDispatch} from "react-redux";

export const useLoading = (states) => {
    const [isLoading, setIsLoading] = useState(states.find(it => it.loading === true));
    const [hasError, setHasError] = useState(false)

    useEffect(()=> {

        setIsLoading(
            states.find(it => it.loading) != null
        )

        setHasError(
            states.find(it => it.error != null) != null
        )

    }, [states])

    return {isLoading, hasError}
}


export const useLoadingModal = (states, disableModal = false) => {
    const dispatch = useDispatch();
    const [isLoading, setIsLoading] = useState(states.find(it => it.loading === true));
    const [hasError, setHasError] = useState(false)

    useEffect(()=> {
        setIsLoading(
            states.find(it => it.loading) != null
        )

        setHasError(
            states.find(it => it.error != null) != null
        )

    }, [states])

    useEffect(()=> {
        if(isLoading && !disableModal){
            const id = new Date().getTime();
            dispatch(putLoading(id))
            return () => {
                dispatch(popLoading(id))
            }
        }
    }, [isLoading])

    return {isLoading, hasError}
}