import moment from "moment-timezone";
import {dateFormat, secToHHmmss} from "../../lib/timeUtil";
import {useEffect, useState} from "react";

export default function LiveCountdown ({liveStartTime} = {}) {

    const {remainTime} = useCountdown({targetDt: liveStartTime});

    if(liveStartTime == null){
        return (
            <span className="sm:text-[48px] font-bold text-[26px] w-full h-full flex justify-center items-center live_text_border">
                생방송이 준비중입니다.
            </span>
        )
    }

    const encStartDt = moment.tz(liveStartTime, "Asia/Seoul");
    const now = moment.tz("Asia/Seoul");

    if(now >= encStartDt) {
        return (
            <span className="sm:text-[48px] font-bold text-[26px] w-full h-full flex justify-center items-center live_text_border">
              곧 생방송이 시작됩니다.
            </span>
        )
    }
    else {
        const isOverADay = encStartDt - now > 24 * 3600 * 1000;
        if(isOverADay) {
            return (
                <>
                    <span className="sm:text-[48px] font-bold text-[26px] text-white live_text_border2">방송 시작 예정</span>
                    <span className="sm:text-[40px] font-bold text-[22px] live_text_border">
                        {dateFormat({
                            value: liveStartTime,
                            format: 'MM월 dd일 a/p HH:mm',
                            apText: ['AM', 'PM'],
                        })}
                    </span>
                </>
            )
        }
        else {
            return (
                <>
                    <span className="sm:text-[48px] font-bold text-[26px] text top live_text_border2">
                      곧 생방송이 시작됩니다.
                    </span>
                    <span className="sm:text-[40px] font-bold text-[22px] countdown live_text_border3">
                        {secToHHmmss(remainTime / 1000)}
                  </span>
                </>
            )
        }
    }
};


const useCountdown = ({targetDt}) => {
    const [remainTime, setRemainTime] = useState();

    useEffect(() => {
        const updateRemainTime = () => {
            const target = moment.tz(targetDt, "Asia/Seoul");
            const now = moment.tz("Asia/Seoul");
            const remain = target - now;
            console.log('tick');
            setRemainTime(Math.max(0, remain));
            if(remain <= 0 && interval != null){
                clearInterval(interval);
                interval = null;
            }
        }
        let interval = setInterval(updateRemainTime, 1000);
        updateRemainTime(); // 즉시 계산
        return () => {
            if(interval != null){
                clearInterval(interval);
                interval = null;
            }
        }
    }, [targetDt]);

    return {
        remainTime,
    }
}