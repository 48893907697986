import {formatBytes, userFileDownload} from "../../../../lib/dataUtils";
import {mmssToSec, secToMmss} from "../../../../lib/videoOffsetCalcUtil";
import {clearRequestVODDownload, requestDownload} from "../../../../modules/userReducer";
import {useDispatch, useSelector} from "react-redux";
import {useEffect} from "react";
import {ShowAlertModal} from "../../../../common/component/modal/AlertModal";
import useDownloadRequestModal from "./useDownloadRequest";

export class VideoDownloadInfo {
    constructor({
                    url,
                    title,
                    source,
                    duration,
                    size,
                } = {}) {
        this.url = url;
        this.title = title;
        this.source = source;
        this.duration = duration;
        this.size = size;
    }
}

/**
 *
 * @param {[VideoDownloadInfo]} list
 * @constructor
 */
export default function VideoDownloadTable({list}){

    const dispatch = useDispatch();
    const loginData = useSelector(state => state.userReducer.loginData);

    useDownloadRequestModal();

    const handleDownload = (data, subtype) => {
        console.log(data)
        dispatch(requestDownload({
            uid: loginData.uid,
            video_cid: data.cid,
            video_fileId: data.fileId,
            sourceType: 'V',
            downloadType: '전체',
            downloadSubType: subtype
        }));
    }

    if(list == null || list.length === 0){
        return "";
    }

    return <article className="edit_item mb_64">
        <div className="content_head mb_32">
            <div className="head_title">
                <div className="txt_head4">영상 파일정보</div>
            </div>
        </div>
        <div className="div__table__area">
            {
                list?.map((it, index) =>
                    <div key={`download_file_item_${index}`}>
                        <div className="row">
                            <dl>
                                <dt>{it.recType ?? ''}</dt>
                                <dd><span>{it.orgFile ?? ''}</span></dd>
                            </dl>
                        </div>
                        <div className="row">
                            <dl>
                                <dt>영상 길이/크기</dt>
                                <dd>
                                    <span>{secToMmss(mmssToSec(it.duration ?? ''))} / {formatBytes(it.orgSize ?? 0)}</span>
                                    <a
                                        onClick={e => handleDownload(it, 'arc')}
                                        className='btn btn-sm'>
                                        다운로드 요청
                                    </a>
                                </dd>
                            </dl>
                        </div>
                        <div className="row">
                            <dl>
                                <dt>백업 원본 영상 길이/크기</dt>
                                <dd>
                                    <span>{secToMmss(mmssToSec(it.duration ?? ''))} / {formatBytes(it.uploadSize ?? 0)}</span>
                                    <a
                                        onClick={e => handleDownload(it, 'up')}
                                        className='btn btn-sm'>
                                        백업 원본 다운로드 요청
                                    </a>
                                </dd>
                            </dl>
                        </div>
                    </div>
                )
            }
        </div>
    </article>
}