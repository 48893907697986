import {createRef, forwardRef, useImperativeHandle, useState} from "react";
import {createRoot} from "react-dom/client";
import {useSelector} from "react-redux";
import classNames from "classnames";

const LoadingModal = forwardRef( ({show}, ref) => {
    // const [isShow, setIsShow] = useState(show ?? true)
    //
    // useImperativeHandle(ref, () => ({
    //     showModal() {
    //         setIsShow(true)
    //     },
    //     hideModel() {
    //         setIsShow(false)
    //     },
    // }));
    //
    // const handleConfirm = () => {
    //     setIsShow(false);
    // }

    const loadingStack = useSelector(state => state.loadingReducer.loadingStack)

    // console.log('render modal-ok');

    return (
        // <div className="modal-ok">
        //     <div className="alert_black_bg show_modal" style={{
        //         display: isShow? 'block' : 'none'
        //     }}>
        //         <div className="white_bg">
        //             {/*<div className="alert_inner">{message}</div>*/}
        //             <div className="check_box">
        //                 <a className="alert_check btn_modal"
        //                    style={{
        //                        width: '100%',
        //                    }}
        //                    onClick={handleConfirm}
        //                 >확인</a>
        //             </div>
        //         </div>
        //     </div>
        // </div>
        <div
            className={classNames({
                'modal-dialog': true,
                'fade': true,
                'hide': loadingStack.length === 0,
                // 'hide': loadingStack.length > 0,
            })}
            // onAnimationStart={(e) => {
            //     console.log(e);
            //     setIsShow(true)
            // }}
            // onAnimationEnd={(e) => {
            //     console.log(e);
            //     setIsShow(false)
            // }}
            // style={{
            //     display: isShow ? 'block': 'none',
            //     // display: 'block',
            //     // display: loadingStack.length > 0 ? 'block' : 'none'
            // }}
        >
            <div className="dim"></div>
            <div className="loading__area">
                <div className="loading_ani">
                    <img src="/image/asset/logo/logo_mark.png" style={{zIndex: 999}}/>
                    {/*<div className="loading__progress">*/}
                    {/*    <div className="bar" style="width: 40%"></div>*/}
                    {/*</div>*/}
                </div>
            </div>
        </div>
    );
})

// /**
//  * DOM에 <ModalOk/> 언선 이후 사용 가능
//  * @returns {JSX.Element}
//  * @constructor
//  */
// export const ShowLoading = ()=> {
//     try{
//         const ref = createRef();
//         const newModal = <LoadingModal
//             ref={ref}
//             show={true}
//         />
//
//         const container = document.querySelectorAll('.modal-ok')[0];
//         const modalRoot = createRoot(container);
//         modalRoot.render(newModal)
//         return ref;
//     }
//     catch(e){
//         console.error(e);
//     }
// }

export default LoadingModal;