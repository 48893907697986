import EventPlayPage from "./index";
import {LoginAwareWrapper} from "../../../common/component/LoginAwareWrapper";

export default function EventPlayPageContainer() {
    return (
        <LoginAwareWrapper
            fill={true}
        >
            <EventPlayPage/>
        </LoginAwareWrapper>
    )
}