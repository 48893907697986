/**
 *
 */
import {useNavigate} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {useEffect, useState} from "react";
import {ShowAlertModal} from "../../../common/component/modal/AlertModal";
import {changePassword, clearPasswordChange, clearSignup} from "../../../modules/userReducer";
import {validatePassword} from "../../../util";
import classNames from "classnames";
import PasswordInput from "../../../common/component/PasswordInput";

export default function ChangePassPage() {

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const changePasswordResult = useSelector(state => state.userReducer.changePasswordResult);
    const loginData = useSelector(state => state.userReducer.loginData);

    const [currentPassword, setCurrentPassword] = useState("")
    const [newPassword1, setNewPassword1] = useState("")
    const [newPassword2, setNewPassword2] = useState("")
    const [newPassword1Ok, setNewPassword1Ok] = useState(false);
    const [newPassword2Ok, setNewPassword2Ok] = useState(false);

    const [canChange, setCanChange] = useState(false);

    useEffect(()=> {
        setCanChange(
            currentPassword.length >= 6
            && validatePassword(newPassword1)
            && newPassword2 === newPassword1
        )

        setNewPassword1Ok(newPassword1.length === 0 || validatePassword(newPassword1))
        setNewPassword2Ok(newPassword2.length === 0 || (validatePassword(newPassword1) && newPassword1 === newPassword2))
    }, [currentPassword, newPassword1, newPassword2]);

    useEffect(()=> {
        if(changePasswordResult.error){
            ShowAlertModal({
                message: '오류가 발생하엿습니다.',
            })
            dispatch(clearPasswordChange());
        }
        else if(changePasswordResult.data) {
            ShowAlertModal({
                message: '비밀번호가 변경되었습니다.',
                onConfirm: ()=> {
                    navigate(-1);
                }
            })
            dispatch(clearPasswordChange());
        }
    }, [changePasswordResult])

    const handleSubmit = () => {
        dispatch(changePassword({
            uid: loginData.uid,
            userPwd: currentPassword,
            _new_password: newPassword1,
        }))
    }

    return <div className="inner fl_center">
        <div className="sign-form-area">
            <div className="txt_head2 mb_48">비밀번호 변경하기</div>
            <div className="mb_12">현재 비밀번호</div>
            <div className="field__inp mb_24">
                <PasswordInput type="password" placeholder="비밀번호를 입력하세요." value={currentPassword} onChange={e => setCurrentPassword(e.target.value.trim())}/>
            </div>
            <div className="mb_12">새로운 비밀번호</div>
            <div className={classNames({
                'field__inp mb_24 vaild__inp ': true,
                'verify ': !newPassword1Ok,
            })}>
                <PasswordInput type="password" className="inp-valid" placeholder="변경할 비밀번호를 입력하세요." value={newPassword1} onChange={e => setNewPassword1(e.target.value.trim())}/>
            </div>
            <div className="mb_12">새로운 비밀번호 확인</div>
            <div className={classNames({
                'field__inp mb_24 vaild__inp ': true,
                'verify ': !newPassword2Ok,
            })}>
                <PasswordInput type="password" className="inp-valid" placeholder="비밀번호를 다시 한번 입력하세요." value={newPassword2} onChange={e => setNewPassword2(e.target.value.trim())}/>
            </div>
            <div className="txt_alert txt_sub">8자리 이상, 영문, 숫자, 특수문자을(를) 사용하세요.</div>
            <div className="field__btn mt_24">
                <div className="btn btn-cancel" onClick={() => navigate(-1)}>취소</div>
                <button className="btn submit" disabled={!canChange} onClick={handleSubmit}>변경하기</button>
            </div>
        </div>
    </div>
}