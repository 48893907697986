/**
 * 00:00 시간을 초로 변환
 * @param {string} mmss HH:mm:ss, H:m:s, m:s, s 형식 지원
 * @returns {number}
 */
export const mmssToSec = (mmss) => {
  const split = mmss.split(':');
  if (split.length === 3) {
    const [hourStr, minuteStr, secondStr] = mmss.split(':');
    return hourStr * 60 * 60 + minuteStr * 60 + secondStr * 1;
  } else if (split.length === 2) {
    const [minuteStr, secondStr] = mmss.split(':');
    return minuteStr * 60 + secondStr * 1;
  } else if (split.length === 1) {
    const [secondStr] = mmss.split(':');
    return secondStr * 1;
  } else {
    return 0;
  }
};
/**
 * 초 값을 00:00으로 변환
 * @param sec
 * @returns {`${string}:${string}`} ex) 105:30, 03:21
 */
export const secToMmss = (sec) => {
  return `${Math.floor(sec / 60)
    .toString()
    .padStart(2, '0')}:${Math.round(sec % 60)
    .toString()
    .padStart(2, '0')}`;
};

/**
 * 초 값을 00:00:00 으로 변환
 * @param sec
 * @returns {`${string}:${string}:${string}`} ex) 03:22:31
 */
export const secToHHmmss = (sec) => {
  sec = Math.floor(sec);
  return `${Math.floor(sec / 3600)
    .toString()
    .padStart(2, '0')}:${Math.floor((sec / 60) % 60)
    .toString()
    .padStart(2, '0')}:${Math.round(sec % 60)
    .toString()
    .padStart(2, '0')}`;
};

/**
 * 초 값을 00:00으로 변환
 * @param sec
 * @returns {`${string}:${string}:${string}`} ex) 03:22:31
 */
export const secToHHmm = (sec) => {
  return `${Math.floor(sec / 3600)
    .toString()
    .padStart(2, '0')}:${Math.floor((sec / 60) % 60)
    .toString()
    .padStart(2, '0')}`;
};

/**
 * Date를 format형식에 맞추어 텍스트 치환합니다.
 * @param {Date|string} value
 * @param {string} format
 * @param {[string]} apText 오전/오후 텍스트. 기본값 ['오전', '오후']
 * @param {[string]} weekdayText 요일 텍스트. 기본값 ["일요일", "월요일", "화요일", "수요일", "목요일", "금요일", "토요일"]
 * @returns {string}
 */
export const dateFormat = ({ value, format, apText, weekdayText }) => {
  if (value == null || !value.valueOf()) return ' ';

  let _apText = apText ?? ['오전', '오후'];
  let _weekdayText = weekdayText ?? [
    '일요일',
    '월요일',
    '화요일',
    '수요일',
    '목요일',
    '금요일',
    '토요일',
  ];
  let d;
  if (value instanceof Date) {
    d = value;
  } else {
    d = newDate(value);
  }
  let h;

  return format.replace(/(yyyy|yy|MM|M|dd|d|E|hh|mm|ss|a\/p)/gi, function ($1) {
    switch ($1) {
      case 'yyyy':
        return d.getFullYear();
      case 'yy':
        return d.getFullYear() % 100;
      case 'M':
        return d.getMonth() + 1;
      case 'MM':
        return ('0' + (d.getMonth() + 1)).slice(-2);
      case 'dd':
        return d.getDate().toString().padStart(2, '0');
      case 'd':
        return d.getDate().toString();
      case 'E':
        return _weekdayText[d.getDay()];
      case 'HH':
        return ('0' + d.getHours()).slice(-2);
      case 'hh': {
        const h = d.getHours() % 12;
        return h ? h : 12;
      }
      case 'mm':
        return ('0' + d.getMinutes()).slice(-2);
      case 'ss':
        return d.getSeconds().toString().padStart(2, '0');
      case 'a/p':
        return d.getHours() < 12 ? _apText[0] : _apText[1];
      default:
        return $1;
    }
  });
};

export const getTodayPath = () => {
  let now = new Date();
  return dateFormat({
    value: now,
    format: 'yyyy/MM/dd',
  });
};

/**
 * HH:mm:ss 값에서 0인 부분을 제거합니다.
 * @param duration
 * @returns {string}
 */
export const trimDuration = (duration) => {
  return duration?.replace(/^(00:0|00:|0)/, '');
};

export const newDate = (value) => {
  if (typeof value === 'string') {
    return new Date(value.replace(' ', 'T'));
  } else {
    return new Date(value);
  }
};
