import {createPromiseThunk, handleAsyncActions, reducerUtils,} from '../lib/asyncUtils';
import * as liveApi from "../apis/api/live";

export class SearchParam {
    /**
     *
     * @param {number} size
     * @param {number}  page
     * @param {[number]}  menus
     */
    constructor({
                    size = 8,
                    page = 0,
                    keyword,
                }) {
        this.size = size;
        this.page = page;
        this.from = page * size;
        if(keyword != null && keyword !== ""){
            this.keyword = keyword;
        }
        this.query = {
            "match_all": {}
        }

        if(this.keyword != null){
            delete this.query["match_all"];
            this.query["bool"] = {
                "must": {
                    "term": {
                        "searchngram": this.keyword,
                    }
                }
            }
        }
    }
}


//액션 타입 정의
const API_ACTIONS = {
    GET_LIVE_LIST: 'GET_LIVE_LIST',
    GET_LIVE_LIST_SUCCESS: 'GET_LIVE_LIST_SUCCESS',
    GET_LIVE_LIST_ERROR: 'GET_LIVE_LIST_ERROR',

    GET_LIVE: 'GET_LIVE',
    GET_LIVE_ERROR: 'GET_LIVE_ERROR',
    GET_LIVE_SUCCESS: 'GET_LIVE_SUCCESS',

};

const ACTIONS = {
    CLEAR_LIVE: 'CLEAR_LIVE',
}

Object.freeze(API_ACTIONS);
Object.freeze(ACTIONS);

//외부에서 dispatch할 때 사용
export const getLive = createPromiseThunk(API_ACTIONS.GET_LIVE, liveApi.getLive);
export const getLiveList = createPromiseThunk(API_ACTIONS.GET_LIVE_LIST, liveApi.searchLive);
export const clearLive = ()=> ({type: ACTIONS.CLEAR_LIVE});

const initialState = {
    live: reducerUtils.initial(),
    liveList: reducerUtils.initial(),
};

/**
 * 영상업로드 관리에서 영상 목록을 불러오고 관리하거나, 영상관리에서 영상 파일 목록을 조회할때 사용
 * @param state
 * @param action
 * @returns {*|{videoFile: {data: null, loading: boolean, error: null}}}
 */
export default function liveReducer(state = initialState, action) {
    switch (action.type) {
        case API_ACTIONS.GET_LIVE:
        case API_ACTIONS.GET_LIVE_ERROR:
        case API_ACTIONS.GET_LIVE_SUCCESS:
            return handleAsyncActions(API_ACTIONS.GET_LIVE, 'live')(state, action);

        case API_ACTIONS.GET_LIVE_LIST:
        case API_ACTIONS.GET_LIVE_LIST_ERROR:
        case API_ACTIONS.GET_LIVE_LIST_SUCCESS:
            return handleAsyncActions(API_ACTIONS.GET_LIVE_LIST, 'liveList')(state, action);

        case ACTIONS.CLEAR_LIVE:
            return {
                ...state,
                live: reducerUtils.initial(),
                liveList: reducerUtils.initial(),
            }

        default:
            return state;
    }
}