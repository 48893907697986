import {searchEngineApiInstance} from '../utils/index';

export const getPlayerList = (keywords, cancel)=>{
    return searchEngineApiInstance.post(
        '',
        {
            "query": {
                "bool": {
                    "filter": {
                        "terms": {
                            "searchngram": keywords,
                        }
                    }
                }
            },
            "from": 0,
            "size": 50
        },

        {
            cancelToken: cancel,
            headers: {
                'subUrl': `/player/_search`,
                "Content-Type": "application/json",
            }
        }
    )
}
