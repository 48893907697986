import {createPromiseThunk, handleAsyncActions, reducerUtils,} from '../lib/asyncUtils';
import * as userApi from "../apis/api/user";
import ReactGA from "react-ga4";
import {setAuthKey} from "../util/storageUtil";
import {call, put, select, takeEvery} from 'redux-saga/effects';
import {staticSsoLogout} from "../lib/useSSO";


//액션 타입 정의
const API_ACTIONS = {
    LOGIN: 'LOGIN',
    LOGIN_SUCCESS: 'LOGIN_SUCCESS',
    LOGIN_ERROR: 'LOGIN_ERROR',

    LOGIN_REFRESH: 'LOGIN_REFRESH',
    LOGIN_REFRESH_SUCCESS: 'LOGIN_REFRESH_SUCCESS',
    LOGIN_REFRESH_ERROR: 'LOGIN_REFRESH_ERROR',

    ID_DUP_CHECK: 'ID_DUP_CHECK',
    ID_DUP_CHECK_SUCCESS: 'ID_DUP_CHECK_SUCCESS',
    ID_DUP_CHECK_ERROR: 'ID_DUP_CHECK_ERROR',

    EMAIL_DUP_CHECK: 'EMAIL_DUP_CHECK',
    EMAIL_DUP_CHECK_SUCCESS: 'EMAIL_DUP_CHECK_SUCCESS',
    EMAIL_DUP_CHECK_ERROR: 'EMAIL_DUP_CHECK_ERROR',

    SIGNUP: 'SIGNUP',
    SIGNUP_SUCCESS: 'SIGNUP_SUCCESS',
    SIGNUP_ERROR: 'SIGNUP_ERROR',

    EMAIL_AUTH: 'EMAIL_AUTH',
    EMAIL_AUTH_SUCCESS: 'EMAIL_AUTH_SUCCESS',
    EMAIL_AUTH_ERROR: 'EMAIL_AUTH_ERROR',

    RESEND_EMAIL_AUTH: 'RESEND_EMAIL_AUTH',
    RESEND_EMAIL_AUTH_SUCCESS: 'RESEND_EMAIL_AUTH_SUCCESS',
    RESEND_EMAIL_AUTH_ERROR: 'RESEND_EMAIL_AUTH_ERROR',

    CHANGE_EMAIL: 'CHANGE_EMAIL',
    CHANGE_EMAIL_SUCCESS: 'CHANGE_EMAIL_SUCCESS',
    CHANGE_EMAIL_ERROR: 'CHANGE_EMAIL_ERROR',

    GET_USER_TYPE_LIST: 'GET_USER_TYPE_LIST',
    GET_USER_TYPE_LIST_SUCCESS: 'GET_USER_TYPE_LIST_SUCCESS',
    GET_USER_TYPE_LIST_ERROR: 'GET_USER_TYPE_LIST_ERROR',

    GET_ALERT_LIST: 'GET_ALERT_LIST',
    GET_ALERT_LIST_SUCCESS: 'GET_ALERT_LIST_SUCCESS',
    GET_ALERT_LIST_ERROR: 'GET_ALERT_LIST_ERROR',

    GET_ALERT_COUNT: 'GET_ALERT_COUNT',
    GET_ALERT_COUNT_SUCCESS: 'GET_ALERT_COUNT_SUCCESS',
    GET_ALERT_COUNT_ERROR: 'GET_ALERT_COUNT_ERROR',

    DELETE_ALERT_LIST: 'DELETE_ALERT_LIST',
    DELETE_ALERT_LIST_SUCCESS: 'DELETE_ALERT_LIST_SUCCESS',
    DELETE_ALERT_LIST_ERROR: 'DELETE_ALERT_LIST_ERROR',

    FIND_ID: 'FIND_ID',
    FIND_ID_SUCCESS: 'FIND_ID_SUCCESS',
    FIND_ID_ERROR: 'FIND_ID_ERROR',

    RESET_PASSWORD: 'RESET_PASSWORD',
    RESET_PASSWORD_SUCCESS: 'RESET_PASSWORD_SUCCESS',
    RESET_PASSWORD_ERROR: 'RESET_PASSWORD_ERROR',

    CHANGE_PASSWORD: 'CHANGE_PASSWORD',
    CHANGE_PASSWORD_SUCCESS: 'CHANGE_PASSWORD_SUCCESS',
    CHANGE_PASSWORD_ERROR: 'CHANGE_PASSWORD_ERROR',

    CHANGE_USER_INFO: 'CHANGE_USER_INFO',
    CHANGE_USER_INFO_SUCCESS: 'CHANGE_USER_INFO_SUCCESS',
    CHANGE_USER_INFO_ERROR: 'CHANGE_USER_INFO_ERROR',

    RESIGN_USER: 'RESIGN_USER',
    RESIGN_USER_SUCCESS: 'RESIGN_USER_SUCCESS',
    RESIGN_USER_ERROR: 'RESIGN_USER_ERROR',

    GET_FAVORITE_CONTENTS: 'GET_FAVORITE_CONTENTS',
    GET_FAVORITE_CONTENTS_SUCCESS: 'GET_FAVORITE_CONTENTS_SUCCESS',
    GET_FAVORITE_CONTENTS_ERROR: 'GET_FAVORITE_CONTENTS_ERROR',

    GET_IMAGE_FAVORITE_CONTENTS: 'GET_IMAGE_FAVORITE_CONTENTS',
    GET_IMAGE_FAVORITE_CONTENTS_SUCCESS: 'GET_IMAGE_FAVORITE_CONTENTS_SUCCESS',
    GET_IMAGE_FAVORITE_CONTENTS_ERROR: 'GET_IMAGE_FAVORITE_CONTENTS_ERROR',

    ADD_FAVORITE_CONTENT: 'ADD_FAVORITE_CONTENT',
    ADD_FAVORITE_CONTENT_SUCCESS: 'ADD_FAVORITE_CONTENT_SUCCESS',
    ADD_FAVORITE_CONTENT_ERROR: 'ADD_FAVORITE_CONTENT_ERROR',

    DELETE_FAVORITE_CONTENT: 'DELETE_FAVORITE_CONTENT',
    DELETE_FAVORITE_CONTENT_SUCCESS: 'DELETE_FAVORITE_CONTENT_SUCCESS',
    DELETE_FAVORITE_CONTENT_ERROR: 'DELETE_FAVORITE_CONTENT_ERROR',

    GET_VIDEO_DOWNLOAD_LOG: 'GET_VIDEO_DOWNLOAD_LOG',
    GET_VIDEO_DOWNLOAD_LOG_SUCCESS: 'GET_VIDEO_DOWNLOAD_LOG_SUCCESS',
    GET_VIDEO_DOWNLOAD_LOG_ERROR: 'GET_VIDEO_DOWNLOAD_LOG_ERROR',

    GET_IMAGE_DOWNLOAD_LOG: 'GET_IMAGE_DOWNLOAD_LOG',
    GET_IMAGE_DOWNLOAD_LOG_SUCCESS: 'GET_IMAGE_DOWNLOAD_LOG_SUCCESS',
    GET_IMAGE_DOWNLOAD_LOG_ERROR: 'GET_IMAGE_DOWNLOAD_LOG_ERROR',

    REQUEST_DOWNLOAD: 'REQUEST_DOWNLOAD',
    REQUEST_DOWNLOAD_SUCCESS: 'REQUEST_DOWNLOAD_SUCCESS',
    REQUEST_DOWNLOAD_ERROR: 'REQUEST_DOWNLOAD_ERROR',

    DELETE_DOWNLOAD_LOG: 'DELETE_DOWNLOAD_LOG',
    DELETE_DOWNLOAD_LOG_SUCCESS: 'DELETE_DOWNLOAD_LOG_SUCCESS',
    DELETE_DOWNLOAD_LOG_ERROR: 'DELETE_DOWNLOAD_ERROR',

    DOWNLOAD_VOD: 'DOWNLOAD_VOD',
    DOWNLOAD_VOD_SUCCESS: 'DOWNLOAD_VOD_SUCCESS',
    DOWNLOAD_VOD_ERROR: 'DOWNLOAD_VOD_ERROR',

    DOWNLOAD_IMG: 'DOWNLOAD_IMG',
    DOWNLOAD_IMG_SUCCESS: 'DOWNLOAD_IMG_SUCCESS',
    DOWNLOAD_IMG_ERROR: 'DOWNLOAD_IMG_ERROR',
};

const ACTIONS = {
    LOGIN_SSO_RESULT: 'LOGIN_SSO_RESULT',
    CLEAR_CHANGE_PASSWORD: 'CLEAR_CHANGE_PASSWORD',
    CLEAR_LOGIN: 'CLEAR_LOGIN',
    CLEAR_SIGNUP: 'CLEAR_SIGNUP',
    SET_ALERT_SEARCH_PARAM: 'SET_ALERT_SEARCH_PARAM',
    SET_VIDEO_DOWNLOAD_LOG_SEARCH_PARAM: 'SET_VIDEO_DOWNLOAD_LOG_SEARCH_PARAM',
    SET_IMAGE_DOWNLOAD_LOG_SEARCH_PARAM: 'SET_IMAGE_DOWNLOAD_LOG_SEARCH_PARAM',
    SET_FAVORITE_VIDEO_SEARCH_PARAM: 'SET_FAVORITE_VIDEO_SEARCH_PARAM',
    SET_FAVORITE_IMAGE_SEARCH_PARAM: 'SET_FAVORITE_IMAGE_SEARCH_PARAM',
    CLEAR_RESEND_EMAIL: 'CLEAR_RESEND_EMAIL',
    CLEAR_RESIGN_USER: 'CLEAR_RESIGN_USER',
    CLEAR_UPDATE_FAVORITE_CONTENT_RESULT: 'CLEAR_UPDATE_FAVORITE_CONTENT_RESULT',
    CLEAR_REQUEST_VOD_DOWNLOAD: 'CLEAR_REQUEST_VOD_DOWNLOAD',
    CLEAR_VOD_DOWNLOAD: 'CLEAR_VOD_DOWNLOAD',
    CLEAR_IMG_DOWNLOAD: 'CLEAR_IMG_DOWNLOAD',
    CLEAR_ALERT_DELETE: 'CLEAR_ALERT_DELETE',
    CLEAR_DOWNLOAD_DELETE: 'CLEAR_DOWNLOAD_DELETE',
    DELETE_ALERT: 'DELETE_ALERT',
    DELETE_FAVORITE: 'DELETE_FAVORITE',
    DELETE_DOWNLOAD: 'DELETE_DOWNLOAD',
}

Object.freeze(API_ACTIONS);
Object.freeze(ACTIONS);

export const login = createPromiseThunk(API_ACTIONS.LOGIN, userApi.login);
export const loginRefresh = createPromiseThunk(API_ACTIONS.LOGIN_REFRESH, userApi.loginRefresh);
export const idDupCheck = createPromiseThunk(API_ACTIONS.ID_DUP_CHECK, userApi.idDupCheck);
export const emailDupCheck = createPromiseThunk(API_ACTIONS.EMAIL_DUP_CHECK, userApi.emailDupCheck);
export const userSignup = createPromiseThunk(API_ACTIONS.SIGNUP, userApi.signup);
export const emailAuth = createPromiseThunk(API_ACTIONS.EMAIL_AUTH, userApi.emailAuth);
export const resendEmail = createPromiseThunk(API_ACTIONS.RESEND_EMAIL_AUTH, userApi.emailResend);
export const changeEmailAuth = createPromiseThunk(API_ACTIONS.CHANGE_EMAIL, userApi.changeEmail);
export const getUserTypeList = createPromiseThunk(API_ACTIONS.GET_USER_TYPE_LIST, userApi.userType);
export const getAlertList = createPromiseThunk(API_ACTIONS.GET_ALERT_LIST, userApi.alertList);
export const getAlertCount = createPromiseThunk(API_ACTIONS.GET_ALERT_COUNT, userApi.alertCount);
export const requestFindId = createPromiseThunk(API_ACTIONS.FIND_ID, userApi.findId);
export const requestResetPassword = createPromiseThunk(API_ACTIONS.RESET_PASSWORD, userApi.resetPassword);
export const changePassword = createPromiseThunk(API_ACTIONS.CHANGE_PASSWORD, userApi.changePassword);
export const changeUserInfo = createPromiseThunk(API_ACTIONS.CHANGE_USER_INFO, userApi.changeUserInfo);
export const resignUser = createPromiseThunk(API_ACTIONS.RESIGN_USER, userApi.resignUser);
export const getFavoriteContent = createPromiseThunk(API_ACTIONS.GET_FAVORITE_CONTENTS, userApi.getFavoriteContents);
export const getImageFavoriteContent = createPromiseThunk(API_ACTIONS.GET_IMAGE_FAVORITE_CONTENTS, userApi.getFavoriteContents);
export const addFavoriteContent = createPromiseThunk(API_ACTIONS.ADD_FAVORITE_CONTENT, userApi.addFavoriteContent);
export const deleteFavoriteContent = createPromiseThunk(API_ACTIONS.DELETE_FAVORITE_CONTENT, userApi.deleteFavoriteContent);
export const deleteDownloadContent = createPromiseThunk(API_ACTIONS.DELETE_DOWNLOAD_LOG, userApi.deleteFavoriteContent);
export const getDownloadVideoLog = createPromiseThunk(API_ACTIONS.GET_VIDEO_DOWNLOAD_LOG, userApi.getDownloadLog);
export const getDownloadImageLog = createPromiseThunk(API_ACTIONS.GET_IMAGE_DOWNLOAD_LOG, userApi.getDownloadLog);
export const requestDownload = createPromiseThunk(API_ACTIONS.REQUEST_DOWNLOAD, userApi.requestDownload);
export const downloadVOD = createPromiseThunk(API_ACTIONS.DOWNLOAD_VOD, userApi.downloadVOD);
export const downloadImg = createPromiseThunk(API_ACTIONS.DOWNLOAD_IMG, userApi.downloadVOD);


export const ssoLoginResultSet =  (param) => ({type: ACTIONS.LOGIN_SSO_RESULT, payload: param});
export const setAlertSearchParam = (param) => ({type: ACTIONS.SET_ALERT_SEARCH_PARAM, payload: param});
export const setDownloadVideoLogSearchParam = (param) => ({type: ACTIONS.SET_VIDEO_DOWNLOAD_LOG_SEARCH_PARAM, payload: param});
export const setDownloadImageLogSearchParam = (param) => ({type: ACTIONS.SET_IMAGE_DOWNLOAD_LOG_SEARCH_PARAM, payload: param});
export const setFavoriteVideoSearchParam = (param) => ({type: ACTIONS.SET_FAVORITE_VIDEO_SEARCH_PARAM, payload: param});
export const setFavoriteImageSearchParam = (param) => ({type: ACTIONS.SET_FAVORITE_IMAGE_SEARCH_PARAM, payload: param});
export const clearLogin = () => ({type: ACTIONS.CLEAR_LOGIN});
export const clearSignup = () => ({type: ACTIONS.CLEAR_SIGNUP});
export const clearPasswordChange = () => ({type: ACTIONS.CLEAR_CHANGE_PASSWORD});
export const clearResendEmail = () => ({type: ACTIONS.CLEAR_RESEND_EMAIL});
export const clearResignUser = () => ({type: ACTIONS.CLEAR_RESIGN_USER});
export const clearUpdateFavoriteContentResult = () => ({type: ACTIONS.CLEAR_UPDATE_FAVORITE_CONTENT_RESULT});
export const clearRequestVODDownload = () => ({type: ACTIONS.CLEAR_REQUEST_VOD_DOWNLOAD});
export const clearVODDownload = () => ({type: ACTIONS.CLEAR_VOD_DOWNLOAD});
export const clearImgDownload = () => ({type: ACTIONS.CLEAR_IMG_DOWNLOAD});
export const clearAlertDelete = () => ({type: ACTIONS.CLEAR_ALERT_DELETE});
export const clearDownloadDelete = () => ({type: ACTIONS.CLEAR_DOWNLOAD_DELETE});
export const deleteAlert = (param) => ({type: ACTIONS.DELETE_ALERT, payload: param});
export const deleteFavorite = (param) => ({type: ACTIONS.DELETE_FAVORITE, payload: param});
export const deleteDownload = (param) => ({type: ACTIONS.DELETE_DOWNLOAD, payload: param});

const initialState = {
    userTypeList: reducerUtils.initial(),

    login: reducerUtils.initial(),
    loginData: null,
    isSSOUser: null,
    isSSOLogin: null,
    idDupResult: reducerUtils.initial(),
    emailDupResult: reducerUtils.initial(),
    signupResult: reducerUtils.initial(),
    emailAuthResult: reducerUtils.initial(),
    resendEmailResult: reducerUtils.initial(),
    findIdResult: reducerUtils.initial(),
    resetPasswordResult: reducerUtils.initial(),
    changePasswordResult: reducerUtils.initial(),
    changeUserInfoResult: reducerUtils.initial(),
    changeEmailResult: reducerUtils.initial(),
    resignUserResult: reducerUtils.initial(),

    // 알림 관련
    alertSearchParam: reducerUtils.initial(),
    alertList: reducerUtils.initial(),
    alertDeleteResult: reducerUtils.initial(),
    deleteAlertIds: [],
    alertCount: reducerUtils.initial(),

    // 관심 자료 관련
    favoriteVideoSearchParam: null,
    favoriteImageSearchParam: null,
    favoriteContents: reducerUtils.initial(),
    favoriteImageContents: reducerUtils.initial(),
    addFavoriteContentResult: reducerUtils.initial(),
    deleteFavoriteList: [],
    deleteFavoriteContentResult: reducerUtils.initial(),

    // 다운로드 관련
    downloadVideoLogSearchParam: null,
    downloadImageLogSearchParam: null,
    downloadVideoLogList: reducerUtils.initial(),
    downloadImageLogList: reducerUtils.initial(),
    deleteDownloadList: [],
    deleteDownloadResult: reducerUtils.initial(),

    // 다운로드 요청 관련
    requestDownload: reducerUtils.initial(),
    downloadVOD: reducerUtils.initial(),
    downloadImg: reducerUtils.initial(),

};

function* getAlertListFlow() {
    const params = yield select(state => state.userReducer.alertSearchParam);
    yield put(getAlertList(params));
}

function* deleteAlertListFlow() {
    try{
        const loginData = yield select(state => state.userReducer.loginData);
        const ids = yield select(state => state.userReducer.deleteAlertIds);
        yield put({type: API_ACTIONS.DELETE_ALERT_LIST, payload: ids});
        if(ids == null || ids.length == 0){
            throw new Error("삭제할 항목이 없습니다");
        }
        for(let i in ids) {
            const id = ids[i];
            const data = yield call(userApi.deleteAlert, {
                uid: loginData.uid,
                itemId: id,
            });
            if(data.data.code != "00"){
                return yield put({type: API_ACTIONS.DELETE_ALERT_LIST_ERROR, payload: data});
            }
        }
        yield put({type: API_ACTIONS.DELETE_ALERT_LIST_SUCCESS, payload: ids});
    }
    catch(e){
        console.error(e);
        return yield put({type: API_ACTIONS.DELETE_ALERT_LIST_ERROR, payload: e});
    }
}

function* deleteDownloadListFlow() {
    try{
        const loginData = yield select(state => state.userReducer.loginData);
        const ids = yield select(state => state.userReducer.deleteDownloadList);
        yield put({type: API_ACTIONS.DELETE_DOWNLOAD_LOG, payload: ids});
        if(ids == null || ids.length == 0){
            throw new Error("삭제할 항목이 없습니다");
        }
        for(let i in ids) {
            const id = ids[i];
            const data = yield call(userApi.deleteDownload, {
                uid: loginData.uid,
                downloadId: id,
            });
            if(data.data.code != "00"){
                return yield put({type: API_ACTIONS.DELETE_DOWNLOAD_LOG_ERROR, payload: data});
            }
        }
        yield put({type: API_ACTIONS.DELETE_DOWNLOAD_LOG_SUCCESS, payload: ids});
    }
    catch(e){
        console.error(e);
        return yield put({type: API_ACTIONS.DELETE_DOWNLOAD_LOG_ERROR, payload: e});
    }
}

function* deleteFavoriteListFlow() {
    try{
        const loginData = yield select(state => state.userReducer.loginData);
        const params = yield select(state => state.userReducer.deleteFavoriteList);
        yield put({type: API_ACTIONS.DELETE_FAVORITE_CONTENT, payload: params});
        if(params == null || params.length == 0){
            throw new Error("삭제할 항목이 없습니다");
        }
        for(let i in params) {
            const param = params[i];
            const data = yield call(userApi.deleteFavoriteContent, {
                userId: loginData.uid,
                ...param,
            });
            if(data.data.code != "00"){
                return yield put({type: API_ACTIONS.DELETE_FAVORITE_CONTENT_ERROR, payload: data});
            }
        }
        yield put({type: API_ACTIONS.DELETE_FAVORITE_CONTENT_SUCCESS, payload: params});
    }
    catch(e){
        console.error(e);
        return yield put({type: API_ACTIONS.DELETE_FAVORITE_CONTENT_ERROR, payload: e});
    }
}

export function* alertSaga() {
    yield takeEvery(ACTIONS.SET_ALERT_SEARCH_PARAM, getAlertListFlow);
    yield takeEvery(ACTIONS.DELETE_ALERT, deleteAlertListFlow);
    yield takeEvery(ACTIONS.DELETE_FAVORITE, deleteFavoriteListFlow);
    yield takeEvery(ACTIONS.DELETE_DOWNLOAD, deleteDownloadListFlow);
}

function* getVideoDownloadLogListFlow() {
    const params = yield select(state => state.userReducer.downloadVideoLogSearchParam);
    yield put(getDownloadVideoLog(params));
}

function* getImageDownloadLogListFlow() {
    const params = yield select(state => state.userReducer.downloadImageLogSearchParam);
    yield put(getDownloadImageLog(params));
}

function* getFavoriteVideoFlow() {
    const params = yield select(state => state.userReducer.favoriteVideoSearchParam);
    yield put(getFavoriteContent(params));
}

function* getFavoriteImageFlow() {
    const params = yield select(state => state.userReducer.favoriteImageSearchParam);
    yield put(getImageFavoriteContent(params));
}

export function* downloadLogListSaga() {
    yield takeEvery(ACTIONS.SET_VIDEO_DOWNLOAD_LOG_SEARCH_PARAM, getVideoDownloadLogListFlow);
    yield takeEvery(ACTIONS.SET_IMAGE_DOWNLOAD_LOG_SEARCH_PARAM, getImageDownloadLogListFlow);
    yield takeEvery(ACTIONS.SET_FAVORITE_VIDEO_SEARCH_PARAM, getFavoriteVideoFlow);
    yield takeEvery(ACTIONS.SET_FAVORITE_IMAGE_SEARCH_PARAM, getFavoriteImageFlow);
}

/**
 * @param state
 * @param action
 * @returns {*|{videoFile: {data: null, loading: boolean, error: null}}}
 */
export default function userReducer(state = initialState, action) {
    switch (action.type) {
        case API_ACTIONS.LOGIN:
        case API_ACTIONS.LOGIN_ERROR:
            return handleAsyncActions(API_ACTIONS.LOGIN, 'login')(state, action);
        case ACTIONS.LOGIN_SSO_RESULT:
        case API_ACTIONS.LOGIN_SUCCESS: {
            const loginData = action.payload?.data?.data?.[0]
                // ?? action.payload?.data?.[0]; // sso
            setAuthKey(loginData?.authKey);
            ReactGA.set({user_id: loginData?.uid});
            // loginData.userType = 'M'

            if(action.payload?.data?.code !== '00'){
                return {
                    ...state,
                    login: reducerUtils.error(action.payload?.data?.message),
                    loginData: null,
                    isSSOUser: null,
                }
            }
            return {
                ...state,
                login: reducerUtils.success(action.payload),
                loginData: loginData,
                isSSOLogin: action.type === ACTIONS.LOGIN_SSO_RESULT,
                isSSOUser: loginData?.ssoYn === 'Y',
            }
        }

        case API_ACTIONS.LOGIN_REFRESH:
        case API_ACTIONS.LOGIN_REFRESH_ERROR:
            return handleAsyncActions(API_ACTIONS.LOGIN_REFRESH, 'login')(state, action);
        case API_ACTIONS.LOGIN_REFRESH_SUCCESS: {
            const loginData = action.payload?.data?.data?.[0];
            setAuthKey(loginData?.authKey);
            ReactGA.set({user_id: loginData?.uid});
            // loginData.userType = 'M'
            return {
                ...state,
                login: reducerUtils.success(action.payload),
                loginData: loginData,
                isSSOUser: loginData?.ssoYn === 'Y',
            }
        }

        case API_ACTIONS.ID_DUP_CHECK:
        case API_ACTIONS.ID_DUP_CHECK_ERROR:
        case API_ACTIONS.ID_DUP_CHECK_SUCCESS:
            return handleAsyncActions(API_ACTIONS.ID_DUP_CHECK, 'idDupResult')(state, action);

        case API_ACTIONS.EMAIL_DUP_CHECK:
        case API_ACTIONS.EMAIL_DUP_CHECK_ERROR:
        case API_ACTIONS.EMAIL_DUP_CHECK_SUCCESS:
            return handleAsyncActions(API_ACTIONS.EMAIL_DUP_CHECK, 'emailDupResult')(state, action);

        case API_ACTIONS.GET_USER_TYPE_LIST:
        case API_ACTIONS.GET_USER_TYPE_LIST_ERROR:
            return handleAsyncActions(API_ACTIONS.GET_USER_TYPE_LIST, 'userTypeList')(state, action);
        case API_ACTIONS.GET_USER_TYPE_LIST_SUCCESS:

            return {
                ...state,
                userTypeList: reducerUtils.success(action.payload?.data?.data?.filter(it => it.itemCd !== ''))
            }

        case ACTIONS.SET_ALERT_SEARCH_PARAM:
            return {
                ...state,
                alertSearchParam: action.payload
            }
        case ACTIONS.SET_VIDEO_DOWNLOAD_LOG_SEARCH_PARAM:
            return {
                ...state,
                downloadVideoLogSearchParam: action.payload
            }

        case ACTIONS.SET_IMAGE_DOWNLOAD_LOG_SEARCH_PARAM:
            return {
                ...state,
                downloadImageLogSearchParam: action.payload
            }


        case API_ACTIONS.GET_ALERT_LIST:
        case API_ACTIONS.GET_ALERT_LIST_ERROR:
            return handleAsyncActions(API_ACTIONS.GET_ALERT_LIST, 'alertList')(state, action);
        case API_ACTIONS.GET_ALERT_LIST_SUCCESS: {
            const alertList = action.payload?.data?.data;
            // 데이터가 비어있지 않다면 첫번째 요소의 _itemTotal 를 SearchParam 의 _pageSize 로 나눈 것을 넣는다. 비어 있다면 0으로 둔다.
            const totalPage = alertList != null && alertList.length > 0 ?
                Math.ceil(alertList[0]._itemTotal / state.alertSearchParam._pageSize)
                : 0;
            return {
                ...state,
                alertList: reducerUtils.success({
                    content: alertList,
                    totalPage,
                    _itemTotal: alertList != null && alertList.length > 0 ? alertList[0]._itemTotal : 0,
                    _pageNo: state.alertSearchParam._pageNo,
                    _pageSize: state.alertSearchParam._pageSize,
                })
            }
        }

        case API_ACTIONS.GET_ALERT_COUNT:
        case API_ACTIONS.GET_ALERT_COUNT_ERROR:
        case API_ACTIONS.GET_ALERT_COUNT_SUCCESS:
            return handleAsyncActions(API_ACTIONS.GET_ALERT_COUNT, 'alertCount')(state, action);

        case API_ACTIONS.DELETE_ALERT_LIST:
        case API_ACTIONS.DELETE_ALERT_LIST_ERROR:
        case API_ACTIONS.DELETE_ALERT_LIST_SUCCESS:
            return handleAsyncActions(API_ACTIONS.DELETE_ALERT_LIST, 'alertDeleteResult')(state, action);

        case ACTIONS.CLEAR_ALERT_DELETE: {
            return {
                ...state,
                deleteAlertIds: [],
                alertDeleteResult: reducerUtils.initial(),
            }
        }

        case ACTIONS.DELETE_ALERT: {
            return {
                ...state,
                deleteAlertIds: action.payload,
            }
        }

        case ACTIONS.DELETE_FAVORITE: {
            return {
                ...state,
                deleteFavoriteList: action.payload,
            }
        }

        case ACTIONS.CLEAR_DOWNLOAD_DELETE: {
            return {
                ...state,
                deleteDownloadList: [],
                deleteDownloadResult: reducerUtils.initial(),
            }
        }

        case ACTIONS.DELETE_DOWNLOAD: {
            return {
                ...state,
                deleteDownloadList: action.payload,
            }
        }


        case API_ACTIONS.SIGNUP:
        case API_ACTIONS.SIGNUP_ERROR:
        case API_ACTIONS.SIGNUP_SUCCESS:
            return handleAsyncActions(API_ACTIONS.SIGNUP, 'signupResult')(state, action);

        case API_ACTIONS.EMAIL_AUTH:
        case API_ACTIONS.EMAIL_AUTH_ERROR:
        case API_ACTIONS.EMAIL_AUTH_SUCCESS:
            return handleAsyncActions(API_ACTIONS.EMAIL_AUTH, 'emailAuthResult')(state, action);

        case API_ACTIONS.RESEND_EMAIL_AUTH:
        case API_ACTIONS.RESEND_EMAIL_AUTH_ERROR:
        case API_ACTIONS.RESEND_EMAIL_AUTH_SUCCESS:
            return handleAsyncActions(API_ACTIONS.RESEND_EMAIL_AUTH, 'resendEmailResult')(state, action);

        case API_ACTIONS.CHANGE_EMAIL:
        case API_ACTIONS.CHANGE_EMAIL_ERROR:
        case API_ACTIONS.CHANGE_EMAIL_SUCCESS:
            return handleAsyncActions(API_ACTIONS.CHANGE_EMAIL, 'changeEmailResult')(state, action);

        case API_ACTIONS.FIND_ID:
        case API_ACTIONS.FIND_ID_ERROR:
        case API_ACTIONS.FIND_ID_SUCCESS:
            return handleAsyncActions(API_ACTIONS.FIND_ID, 'findIdResult')(state, action);

        case API_ACTIONS.RESET_PASSWORD:
        case API_ACTIONS.RESET_PASSWORD_ERROR:
        case API_ACTIONS.RESET_PASSWORD_SUCCESS:
            return handleAsyncActions(API_ACTIONS.RESET_PASSWORD, 'resetPasswordResult')(state, action);

        case API_ACTIONS.CHANGE_PASSWORD:
        case API_ACTIONS.CHANGE_PASSWORD_ERROR:
        case API_ACTIONS.CHANGE_PASSWORD_SUCCESS:
            return handleAsyncActions(API_ACTIONS.CHANGE_PASSWORD, 'changePasswordResult')(state, action);

        case API_ACTIONS.CHANGE_USER_INFO:
        case API_ACTIONS.CHANGE_USER_INFO_ERROR:
        case API_ACTIONS.CHANGE_USER_INFO_SUCCESS:
            return handleAsyncActions(API_ACTIONS.CHANGE_USER_INFO, 'changeUserInfoResult')(state, action);

        case API_ACTIONS.RESIGN_USER:
        case API_ACTIONS.RESIGN_USER_ERROR:
        case API_ACTIONS.RESIGN_USER_SUCCESS:
            return handleAsyncActions(API_ACTIONS.RESIGN_USER, 'resignUserResult')(state, action);


        case ACTIONS.SET_FAVORITE_VIDEO_SEARCH_PARAM:
            return {
                ...state,
                favoriteVideoSearchParam: action.payload
            }

        case ACTIONS.SET_FAVORITE_IMAGE_SEARCH_PARAM:
            return {
                ...state,
                favoriteImageSearchParam: action.payload
            }


        case API_ACTIONS.GET_FAVORITE_CONTENTS:
        case API_ACTIONS.GET_FAVORITE_CONTENTS_ERROR:
            return handleAsyncActions(API_ACTIONS.GET_FAVORITE_CONTENTS, 'favoriteContents')(state, action);
        case API_ACTIONS.GET_FAVORITE_CONTENTS_SUCCESS: {
            const list = action.payload?.data?.data;
            // 데이터가 비어있지 않다면 첫번째 요소의 _itemTotal 를 SearchParam 의 _pageSize 로 나눈 것을 넣는다. 비어 있다면 0으로 둔다.
            const pageSize = state.favoriteVideoSearchParam._pageSize;
            const totalPage = list != null && list.length > 0 ?
                Math.ceil(list[0]._itemTotal / pageSize)
                : 0;
            return {
                ...state,
                favoriteContents: reducerUtils.success({
                    content: list,
                    totalPage,
                    _itemTotal: list != null && list.length > 0 ? list[0]._itemTotal : 0,
                    _pageNo: state.favoriteVideoSearchParam._pageNo,
                    _pageSize: pageSize,
                })
            }
        }


        case API_ACTIONS.GET_IMAGE_FAVORITE_CONTENTS:
        case API_ACTIONS.GET_IMAGE_FAVORITE_CONTENTS_ERROR:
            return handleAsyncActions(API_ACTIONS.GET_IMAGE_FAVORITE_CONTENTS, 'favoriteImageContents')(state, action);
        case API_ACTIONS.GET_IMAGE_FAVORITE_CONTENTS_SUCCESS: {
            const list = action.payload?.data?.data;
            // 데이터가 비어있지 않다면 첫번째 요소의 _itemTotal 를 SearchParam 의 _pageSize 로 나눈 것을 넣는다. 비어 있다면 0으로 둔다.
            const pageSize = state.favoriteImageSearchParam._pageSize;
            const totalPage = list != null && list.length > 0 ?
                Math.ceil(list[0]._itemTotal / pageSize)
                : 0;
            return {
                ...state,
                favoriteImageContents: reducerUtils.success({
                    content: list,
                    totalPage,
                    _itemTotal: list != null && list.length > 0 ? list[0]._itemTotal : 0,
                    _pageNo: state.favoriteImageSearchParam._pageNo,
                    _pageSize: pageSize,
                })
            }
        }

        case API_ACTIONS.ADD_FAVORITE_CONTENT:
        case API_ACTIONS.ADD_FAVORITE_CONTENT_ERROR:
        case API_ACTIONS.ADD_FAVORITE_CONTENT_SUCCESS:
            return handleAsyncActions(API_ACTIONS.ADD_FAVORITE_CONTENT, 'addFavoriteContentResult')(state, action);

        case API_ACTIONS.DELETE_FAVORITE_CONTENT:
        case API_ACTIONS.DELETE_FAVORITE_CONTENT_ERROR:
        case API_ACTIONS.DELETE_FAVORITE_CONTENT_SUCCESS:
            return handleAsyncActions(API_ACTIONS.DELETE_FAVORITE_CONTENT, 'deleteFavoriteContentResult')(state, action);

        case API_ACTIONS.DELETE_DOWNLOAD_LOG:
        case API_ACTIONS.DELETE_DOWNLOAD_LOG_ERROR:
        case API_ACTIONS.DELETE_DOWNLOAD_LOG_SUCCESS:
            return handleAsyncActions(API_ACTIONS.DELETE_DOWNLOAD_LOG, 'deleteDownloadResult')(state, action);

        case API_ACTIONS.REQUEST_DOWNLOAD:
        case API_ACTIONS.REQUEST_DOWNLOAD_ERROR:
        case API_ACTIONS.REQUEST_DOWNLOAD_SUCCESS:
            return handleAsyncActions(API_ACTIONS.REQUEST_DOWNLOAD, 'requestDownload')(state, action);

        case API_ACTIONS.DOWNLOAD_VOD:
        case API_ACTIONS.DOWNLOAD_VOD_ERROR:
        case API_ACTIONS.DOWNLOAD_VOD_SUCCESS:
            return handleAsyncActions(API_ACTIONS.DOWNLOAD_VOD, 'downloadVOD')(state, action);

        case API_ACTIONS.DOWNLOAD_IMG:
        case API_ACTIONS.DOWNLOAD_IMG_ERROR:
        case API_ACTIONS.DOWNLOAD_IMG_SUCCESS:
            return handleAsyncActions(API_ACTIONS.DOWNLOAD_IMG, 'downloadImg')(state, action);

        case API_ACTIONS.GET_VIDEO_DOWNLOAD_LOG:
        case API_ACTIONS.GET_VIDEO_DOWNLOAD_LOG_ERROR:
            return handleAsyncActions(API_ACTIONS.GET_VIDEO_DOWNLOAD_LOG, 'downloadVideoLogList')(state, action);
        case API_ACTIONS.GET_VIDEO_DOWNLOAD_LOG_SUCCESS: {
            const downloadLogList = action.payload?.data?.data;
            // 데이터가 비어있지 않다면 첫번째 요소의 _itemTotal 를 SearchParam 의 _pageSize 로 나눈 것을 넣는다. 비어 있다면 0으로 둔다.
            const totalPage = downloadLogList != null && downloadLogList.length > 0 ?
                Math.ceil(downloadLogList[0]._itemTotal / state.downloadVideoLogSearchParam._pageSize)
                : 0;
            return {
                ...state,
                downloadVideoLogList: reducerUtils.success({
                    content: downloadLogList,
                    totalPage,
                    _itemTotal: downloadLogList != null && downloadLogList.length > 0 ? downloadLogList[0]._itemTotal : 0,
                    _pageNo: state.downloadVideoLogSearchParam._pageNo,
                    _pageSize: state.downloadVideoLogSearchParam._pageSize,
                })
            }
        }

        case API_ACTIONS.GET_IMAGE_DOWNLOAD_LOG:
        case API_ACTIONS.GET_IMAGE_DOWNLOAD_LOG_ERROR:
            return handleAsyncActions(API_ACTIONS.GET_IMAGE_DOWNLOAD_LOG, 'downloadImageLogList')(state, action);
        case API_ACTIONS.GET_IMAGE_DOWNLOAD_LOG_SUCCESS: {
            const downloadLogList = action.payload?.data?.data;
            // 데이터가 비어있지 않다면 첫번째 요소의 _itemTotal 를 SearchParam 의 _pageSize 로 나눈 것을 넣는다. 비어 있다면 0으로 둔다.
            const totalPage = downloadLogList != null && downloadLogList.length > 0 ?
                Math.ceil(downloadLogList[0]._itemTotal / state.downloadImageLogSearchParam._pageSize)
                : 0;
            return {
                ...state,
                downloadImageLogList: reducerUtils.success({
                    content: downloadLogList,
                    totalPage,
                    _itemTotal: downloadLogList != null && downloadLogList.length > 0 ? downloadLogList[0]._itemTotal : 0,
                    _pageNo: state.downloadImageLogSearchParam._pageNo,
                    _pageSize: state.downloadImageLogSearchParam._pageSize,
                })
            }
        }

        case ACTIONS.CLEAR_CHANGE_PASSWORD:
            return {
                ...state,
                changePasswordResult: reducerUtils.initial(),
            }

        case ACTIONS.CLEAR_RESEND_EMAIL:
            return {
                ...state,
                resendEmailResult: reducerUtils.initial(),
            }

        case ACTIONS.CLEAR_RESIGN_USER:
            return {
                ...state,
                resignUserResult: reducerUtils.initial(),
            }

        case ACTIONS.CLEAR_UPDATE_FAVORITE_CONTENT_RESULT:
            return {
                ...state,
                addFavoriteContentResult: reducerUtils.initial(),
                deleteFavoriteContentResult: reducerUtils.initial(),
            }

        case ACTIONS.CLEAR_REQUEST_VOD_DOWNLOAD:
            return {
                ...state,
                requestDownload: reducerUtils.initial(),
            }

        case ACTIONS.CLEAR_VOD_DOWNLOAD:
            return {
                ...state,
                downloadVOD: reducerUtils.initial(),
            }


        case ACTIONS.CLEAR_IMG_DOWNLOAD:
            return {
                ...state,
                downloadImg: reducerUtils.initial(),
            }

        case ACTIONS.CLEAR_SIGNUP:
            return {
                ...state,
                idDupResult: reducerUtils.initial(),
                emailDupResult: reducerUtils.initial(),
                signupResult: reducerUtils.initial(),
                emailAuthResult: reducerUtils.initial(),
                findIdResult: reducerUtils.initial(),
                resetPasswordResult: reducerUtils.initial(),
                changePasswordResult: reducerUtils.initial(),
                changeUserInfoResult: reducerUtils.initial(),
                changeEmailResult: reducerUtils.initial(),
                requestDownload: reducerUtils.initial(),
                downloadVOD: reducerUtils.initial(),
                downloadImg: reducerUtils.initial(),
                deleteAlertIds: [],
                alertCount: reducerUtils.initial(),
            }

        case ACTIONS.CLEAR_LOGIN:
            setAuthKey(null);
            staticSsoLogout(state?.login.data?.data?.jwt?.id_token)
            ReactGA.set({user_id: null});
            return {
                ...state,
                login: reducerUtils.initial(),
                loginData: null,
                isSSOUser: null,
                isSSOLogin: null,
                idDupResult: reducerUtils.initial(),
                emailDupResult: reducerUtils.initial(),
                signupResult: reducerUtils.initial(),
                emailAuthResult: reducerUtils.initial(),
                findIdResult: reducerUtils.initial(),
                resetPasswordResult: reducerUtils.initial(),
                changePasswordResult: reducerUtils.initial(),
                changeUserInfoResult: reducerUtils.initial(),
                favoriteContents: reducerUtils.initial(),
                favoriteImageContents: reducerUtils.initial(),
                changeEmailResult: reducerUtils.initial(),
                requestDownload: reducerUtils.initial(),
                downloadVOD: reducerUtils.initial(),
                downloadImg: reducerUtils.initial(),
                downloadVideoLogList: reducerUtils.initial(),
                downloadImageLogList: reducerUtils.initial(),
                deleteAlertIds: [],
                alertCount: reducerUtils.initial(),
            }
        default:
            return state;
    }
}