import useLocation2 from "../../../../util/useLocation2";
import {useEffect, useMemo, useState} from "react";
import queryString from "query-string";
import {useDispatch, useSelector} from "react-redux";
import {ssoLoginResultSet} from "../../../../modules/userReducer";
import {useNavigate} from "react-router-dom";
import FlexFillCenter from "../../FlexFillCenter";
import useSSO from "../../../../lib/useSSO";


export default function KFASSOCallbackPage() {

    const navigate = useNavigate();
    const {goSSOHome, ssoLogin} = useSSO();
    const loginData = useSelector(state => state.userReducer.loginData);
    const login = useSelector(state => state.userReducer.login);
    const dispatch = useDispatch();
    // const [cookies, setCookie, removeCookie] = useCookies(['sso-kfa']);
    const { query } = useLocation2();
    const {state, session_state, code, error, error_description} = query;
    const _state = useMemo(() => queryString.parse(state), [state]);
    const {secret, returnUrl} = useMemo(() => _state, [_state]);
    // const {secret: cookieSecret, returnUrl: cookieReturnUrl} = useMemo(() => queryString.parse(decodeURIComponent(cookies.state) ?? ''), [cookies]);
    // const cookieStateMatch = useMemo(() => secret === cookieSecret && returnUrl === cookieReturnUrl, [secret, returnUrl, cookieSecret, cookieReturnUrl]);
    const [claimState, setClaimState] = useState('ready');
    const [claimMessage, setClaimMessage] = useState(null);


    const sendToken = async ({state, code}) => {
        setClaimState('loading');
        let loginCode;
        let loginMessage;
        try{
            let res = await ssoLogin({
                state,
                code,
            });

            console.log(res);
            loginCode = res?.data?.code;
            loginMessage = res?.data?.message
            if(loginCode === "00"){
                setClaimMessage(null);
                setClaimState('success');
                dispatch(ssoLoginResultSet(res));
            }
            else if(loginCode === '33') {
                // 통합ID에 PLUS 연동 안되어있음
            }
            else {
                setClaimMessage(loginMessage);
                setClaimState('error');
            }
        }
        catch (e) {
            console.error(e);
            setClaimState('error');
            setClaimMessage(e.message);
        }

        navigate(`/?sso=fail&ssoCode=${loginCode}&ssoMessage=${loginMessage}`)
    }

    useEffect(() => {
        // if(cookieStateMatch === true){
        if(code == null){
            goWithSSOFail();
        }
        else {
            sendToken({state, code});
        }
        // }
    }, []);

    useEffect(() => {
        if(loginData != null){
            console.log('ssoSilentLogin', returnUrl);
            window.location.href = returnUrl;
        }
    }, [loginData]);

    const goWithSSOFail = () => {
        if(returnUrl?.includes('?')){
            if(returnUrl.includes('sso=fail')){
                window.location.href = returnUrl;
            }
            else {
                window.location.href = `${returnUrl}&sso=fail`;
            }
        }
        else {
            window.location.href = `${returnUrl}?sso=fail`;
        }
    }

    return (
        <FlexFillCenter
            absolute={true}
        >
            {
                claimState === 'ready' &&
                <span>로그인 중입니다.</span>
            }
            {
                claimState === 'loading' &&
                <span>로그인 중입니다.</span>
            }
            {
                claimState === 'success' &&
                <>
                    <span>로그인 완료. 홈으로 이동합니다.</span>
                </>
            }
            {
                claimState === 'error' &&
                <>
                    <span>로그인 오류입니다. 다시 시도해 주세요.</span>
                    <span>{claimMessage}</span>

                   <div className="flex">
                       <div className="field__btn mt-4">
                           <button
                               className="btn submit"
                               onClick={e => goSSOHome()}
                           >
                               SSO 홈으로
                           </button>
                       </div>
                       <div className="mr-4"/>
                       <div className="field__btn mt-4">
                           <button
                               className="btn submit"
                               onClick={e => goWithSSOFail()}
                           >
                               돌아가기
                           </button>
                       </div>
                   </div>
                </>
            }
            {
                error != null && error !== '' &&
                <>
                    <span>{error}</span>
                    <span>{error_description}</span>
                </>
            }
        </FlexFillCenter>
    )
}