import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import rootReducer, {rootSaga} from "./modules";
import {configureStore} from "@reduxjs/toolkit";
import createSagaMiddleware from "redux-saga";
import {Provider} from "react-redux";
import {logger} from "redux-logger/src";

const sagaMiddleware = createSagaMiddleware();

const middlewares = [
    sagaMiddleware,
]

if (!process.env.NODE_ENV || process.env.REACT_APP_MODE === 'development' || process.env.REACT_APP_MODE === 'development2') {
    middlewares.push(logger)
} else {
    console.log = () => {};
}

const store = configureStore({
    reducer: rootReducer,
    middleware:(getDefaultMiddleware) => getDefaultMiddleware({
        immutableCheck: false, // 파일 업로드를 위해, 파일객체를 redux로 다루면서 필수로 설정해야함.
        serializableCheck: false,
    }).concat(middlewares),
});

sagaMiddleware.run(rootSaga);

const root = ReactDOM.createRoot(document.getElementById('root'));
// root.render(
//   <React.StrictMode>
//     <App />
//   </React.StrictMode>
// );

root.render(
    <Provider store={store}>
        <App />
    </Provider>
);


// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
