import {makeImagePosterUrl, makeThumbnailUrl} from "../../util/url";

export default class ImageContentItem{
    constructor({
                    category,
                    file,
                    hash,
                    menuId,
                    imagecontent,
                } = {}) {
        this.imagecontent = imagecontent?.[0];
        this.cid = this.imagecontent?.cid;
        this.menuId = menuId;
        this.category = category;
        this.menuId = menuId;
        this.file = file?.[0];
        this.hash = hash?.map(it => it.hashNm);
        this.thumbnail = makeThumbnailUrl(this.imagecontent)
        this.poster = makeImagePosterUrl(this.file)
    }

}