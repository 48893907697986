import PreviewGridItem from "../../common/component/previewItem/PreviewGridItem";
import {useLocation, useSearchParams} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {findMenuWithMenusAndCode, findMenuWithMenusAndDfs, getAsFlat, getOnlyChildren} from "../../lib/menu/menuUtil";
import {useEffect, useState} from "react";
import {useLoadingModal} from "../../common/component/LoadingState";
import PreviewItemModel from "../../common/component/previewItem/model";
import PageIndicator from "../../common/component/PageIndicator";
import {searchEventBoard} from "../../modules/eventBoardReducer";
import {EventSearchParam} from "../../apis/dto/SearchParam";
import EventModel from "../play/model/EventModel";
import {makePreviewEventTitle} from "../../util/eventUtil";
import FlexFillCenter from "../../common/component/FlexFillCenter";
import NotFound from "../../common/component/NotFound";
import queryString from "query-string";
import EventBoardSearchBox from "./component/EventBoardSearchBox";
import {Tab, TabList, TabPanel, Tabs} from "react-tabs";
import classNames from "classnames";
import PreviewEventListItem from "../../common/component/previewItem/PreviewEventListItem";

export default function EventBoard({menu}) {
    const pageSize = 8;
    const dispatch = useDispatch();
    const [currentMenu, setCurrentMenu] = useState(menu);
    const loginData = useSelector(state => state.userReducer.loginData);
    const location = useLocation();
    const [locationSearchParam, setLocationSearchParam] = useSearchParams();
    const menus = useSelector(state => state.menuReducer.menus);
    const searchList = useSelector(state => state.eventBoardReducer.eventBoardSearchResult);
    const [showList, setShowList] = useState([]);
    const [searchParam, setSearchParam] = useState(null);
    const [styleTabIndex, setStyleTabIndex] = useState(0);
    const [codeList, setCodeList] = useState([]);

    const _setSearchParam = (param, options) => {
        setLocationSearchParam(param.toHistory(), options)
    }

    const setPage = (page) => {
        _setSearchParam(new EventSearchParam({
            ...searchParam,
            page: page,
            menu: menu,
        }))
    }

    const copyrightAc = () => {
        return loginData?.copyright_list_ac === 1 ? null : 'Y';
    }

    const getMenu = (pathname) => {
        let paths = pathname.split('/');
        let targetPath = pathname;
        if (paths.length > 2) { // '/*', '/*/*'
            targetPath = `${paths[paths.length - 1]}`
        }
        console.log('path', pathname, targetPath);
        const list = getAsFlat(menus.data);
        return list.find(it => it.menuUrl === targetPath)
    }


    const getRootMenu = (pathname) => {
        let currentMenu = getMenu(pathname);
        const list = getAsFlat(menus.data);
        let lastParent = currentMenu;
        while (true) {
            // 메뉴 정렬상 순환시 부모가 먼저 나옴
            let parent = list?.find(it => it.children?.find(ch => ch.menuId === currentMenu.menuId) != null)
            if (parent == null || lastParent == parent) {
                break;
            }
            lastParent = parent;
        }
        return lastParent;
    }

    useEffect(() => {
        const rootMenu = getRootMenu(location.pathname);
        const codes = [];
        const menuList = getOnlyChildren(rootMenu);
        menuList.forEach(it => {
            it.memucode?.forEach(c => {
                codes.push({...it, menuId: c.code3, menuNm: c.name3})
            })
        })
        setCodeList(codes);

        if (location.search) {
            const query = queryString.parse(location.search);
            let param = EventSearchParam.fromHistory(query, menus.data, copyrightAc());

            setSearchParam(new EventSearchParam({
                ...param,
                page: (!param.page || isNaN(param.page)) ? 0 : param.page,
                size: pageSize,
                copyright: copyrightAc(),
                menu: menu,
                menus: menus.data,
            }));
        } else {
            const currentMenu = getMenu(location.pathname);
            setCurrentMenu(currentMenu);

            _setSearchParam(new EventSearchParam({
                page: 0,
                size: pageSize,
                copyright: copyrightAc(),
                menu: menu,
            }), {replace: true});
        }
    }, [location.pathname, location.search]);

    const handleParamChange = (newParam) => {
        _setSearchParam(new EventSearchParam({
            ...newParam,
            page: 0,
            menu: menu,
        }))
    }

    useEffect(() => {
        if (searchParam != null) {
            dispatch(searchEventBoard(searchParam))
        }
    }, [searchParam])

    useEffect(() => {
        if (menus.data != null && searchParam != null) {
            if (searchList.data != null) {
                const menuList = getAsFlat(menus.data);
                const list = searchList.data?.data?.hits?.hits?.map(it => EventModel.of2(it._source))
                console.log(list);
                setShowList(
                    list?.map((it, index) => new PreviewItemModel({
                        id: it.eventId,
                        title: makePreviewEventTitle(it),
                        titleForList: `${it.matchName} ${it.teamHome} vs ${it.teamAway}`,
                        thumbnail: it.thumbnail,
                        category: menuList.find(m => m.menuId === it.menuId)?.menuNm ?? it.matchName,
                        date: it.videocontent.match_date,
                        // date: new Date(it.regDt).format('yyyy-MM-dd'),
                        event: it.eventNm,
                        player: it.hname,
                        home: it.teamHome,
                        away: it.teamAway,
                        time: `${it.timeMisec}${it.injuryTimeMisec != null ? `+${it.injuryTimeMisec}` : ''}`,
                        link: `./video/${it.eventId}?boardParam=${encodeURIComponent(queryString.stringify({
                            ...searchParam.toHistory(),
                            index: index
                        }))}`,
                        state: {eventId: it.eventId},
                    }))
                )
            }
        }
    }, [searchList.data, menus.data])

    useLoadingModal([menus, searchList])

    if (currentMenu == null) {
        return <div
            style={{
                display: 'flex',
                minHeight: '300px',
                height: '50vh',
                alignItems: 'center',
                justifyContent: 'center',
            }}>
            알 수 없는 메뉴입니다.
        </div>
    }

    const getTitle = () => {
        const menuIdOrCode = getMenuIdOrCode();

        if(menuIdOrCode.length > 1) {
            const cur1 = findMenuWithMenusAndCode(menus.data, menuIdOrCode[0]);
            const cur2 = findMenuWithMenusAndCode(menus.data, menuIdOrCode[1]);
            if(cur1 === cur2) return cur1.menuNm;
            else return menu.menuNm;
        } else {
            const currentMenu = findMenuWithMenusAndCode(menus.data, ...menuIdOrCode) ?? findMenuWithMenusAndDfs(menus.data, Number(...menuIdOrCode));
            return currentMenu?.menuNm ?? menu.menuNm;
        }
    }

    const getMenuIdOrCode = () => {
        const query = queryString.parse(location.search);

        let menus = [];

        const menu2 = query.league;
        if(menu2) {
            if(menu2 instanceof Array) menus.push(...menu2);
            else menus.push(menu2)
        }

        const menu3 = query.leagueParent;
        if(menu3) {
            if(menu3 instanceof Array) menus.push(...menu3);
            else menus.push(menu3)
        }

        if(menus.length === 1 || menus.length === 2) return menus;
        else return [];
    }

    const getLeagueParents = () => {
        if(!searchParam?.leagueParent) return [];

        return [...searchParam.leagueParent].map(code => findMenuWithMenusAndDfs(menus.data, code));
    }

    return (
        <>
            <div className="inner">
                <EventBoardSearchBox
                    currMenu={currentMenu.menuId}
                    initialParam={searchParam}
                    onParamChanged={handleParamChange}
                    menus={codeList}
                    showingLeagueParent={getLeagueParents()}
                />

                <article className="content__wrap mt_64">
                    <Tabs
                        forceRenderTabPanel={true}
                        onSelect={setStyleTabIndex}
                    >
                        <article className="content__tab__area mb_24">
                            <div className="content_head">
                                <div className="head_title">
                                    <div className="txt_head2">{getTitle()}</div>
                                    <div
                                        className="txt_head6 txt_sub">{searchList?.data?.data?.hits?.total?.value}</div>
                                </div>
                                <TabList>
                                    <div className="head__tool">
                                        <Tab>
                                            <div
                                                className={classNames({
                                                    "btn btn-revers btn-lg": true,
                                                    "active": styleTabIndex === 0,
                                                })}
                                            >
                                                <i className="icon gas-block"></i>
                                            </div>
                                        </Tab>
                                        <Tab>
                                            <div
                                                className={classNames({
                                                    "btn btn-revers btn-lg": true,
                                                    "active": styleTabIndex === 1,
                                                })}
                                            >
                                                <i className="icon gas-buger"></i>
                                            </div>
                                        </Tab>
                                    </div>
                                </TabList>
                            </div>
                        </article>
                        <TabPanel>
                            <article className="game__area mt_48">
                                <div className={classNames({
                                    'block__list': true,
                                    '!h-full': showList?.length > 0,
                                })}>
                                    {
                                        (showList?.length ?? 0) === 0 &&
                                        <FlexFillCenter children={<NotFound message={'검색 결과가 없습니다.'}/>}/>
                                    }
                                    <ul>
                                        {
                                            showList?.map(data =>
                                                <li>
                                                    <PreviewGridItem
                                                        {...data}
                                                    />
                                                </li>
                                            )
                                        }
                                    </ul>
                                </div>
                                <div className="pagenate__area">
                                    <PageIndicator
                                        pageSize={pageSize}
                                        page={searchParam?.page ?? 0}
                                        setPage={setPage}
                                        itemCount={searchList?.data?.data?.hits?.total?.value}
                                        usePageInput={true}
                                    />
                                </div>
                            </article>
                        </TabPanel>
                        <TabPanel>
                            <div className="table__area table__list mb_48">
                                <table>
                                    <colgroup>
                                        <col width="94px"/>
                                        <col width=""/>
                                        <col width="100px"/>
                                        <col width="100px"/>
                                        <col width=""/>
                                        <col width="94px"/>
                                        <col width=""/>
                                        <col width=""/>
                                    </colgroup>
                                    <thead>
                                    <tr>
                                        <th>번호</th>
                                        <th>대표이미지</th>
                                        {/*<th>년도</th>*/}
                                        <th>경기 이벤트</th>
                                        <th>선수명</th>
                                        <th>대회명</th>
                                        {/*<th>경기 날짜</th>*/}
                                        <th>시간</th>
                                        <th>홈팀</th>
                                        <th>원정팀</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {
                                        showList?.map(data =>
                                            <PreviewEventListItem
                                                {
                                                    ...data
                                                }
                                            />
                                        )
                                    }
                                    </tbody>
                                </table>
                            </div>
                            <div className="pagenate__area">
                                <PageIndicator
                                    pageSize={pageSize}
                                    page={searchParam?.page ?? 0}
                                    setPage={setPage}
                                    itemCount={searchList?.data?.data?.hits?.total?.value}
                                    usePageInput={true}
                                />
                            </div>
                        </TabPanel>
                    </Tabs>

                </article>
            </div>
        </>
    )
}