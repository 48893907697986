import {createPromiseThunk, handleAsyncActions, reducerUtils,} from '../lib/asyncUtils';
import * as videoApi from "../apis/api/video";
import * as imageApi from "../apis/api/image";
import {call, put, select, takeEvery} from 'redux-saga/effects';
import VideoContentItem from "../apis/dto/VideoContentItem";

//액션 타입 정의
const API_ACTIONS = {
    GET_VIDEO_RELATION_LIST: 'GET_VIDEO_RELATION_LIST',
    GET_VIDEO_RELATION_LIST_SUCCESS: 'GET_VIDEO_RELATION_LIST_SUCCESS',
    GET_VIDEO_RELATION_LIST_ERROR: 'GET_VIDEO_RELATION_LIST_ERROR',

    GET_IMAGE_RELATION_LIST: 'GET_IMAGE_RELATION_LIST',
    GET_IMAGE_RELATION_LIST_SUCCESS: 'GET_IMAGE_RELATION_LIST_SUCCESS',
    GET_IMAGE_RELATION_LIST_ERROR: 'GET_VIDEO_RELATION_LIST_ERROR',

};

const ACTIONS = {
    CLEAR_RELATION_LIST: 'CLEAR_RELATION_LIST',
}

const SAGA_ACTIONS = {
    GET_VIDEO_RELATION: 'GET_VIDEO_RELATION',
}

Object.freeze(API_ACTIONS);
Object.freeze(ACTIONS);
Object.freeze(SAGA_ACTIONS);

export const getImageRelation = createPromiseThunk(API_ACTIONS.GET_IMAGE_RELATION_LIST, imageApi.getImages);
export const clearRelationList = ()=> ({type: ACTIONS.CLEAR_RELATION_LIST});


export const getTotalVideoRelation = (payload) => ({type: SAGA_ACTIONS.GET_VIDEO_RELATION, payload}); //  getVideoInEventOne 실행

const initialState = {
    videoRelationList: reducerUtils.initial(),
    imageRelationList: reducerUtils.initial(),
    videoRelationPayload: null,
};


export function* videoRelationSaga() {
    yield takeEvery(SAGA_ACTIONS.GET_VIDEO_RELATION, getTotalVideoRelationAction);
}

function* getTotalVideoRelationAction () {
    yield put({type: API_ACTIONS.GET_VIDEO_RELATION_LIST});
    try{
        const loginData = yield select(state => state.userReducer.loginData);
        const copyright = loginData?.copyright_list_ac === 1 ? null : 'Y';
        const payload = yield select(state => state.relationReducer.videoRelationPayload);
        const cid = payload.cid;
        const relationCids = payload.relationCids;
        const singleIdx = payload.singleIdx;
        const matchIdx = payload.matchIdx;
        const matchClass = payload.matchClass;

        const autoRelatedVideosRes = yield call(videoApi.getGameRelatedVideos, singleIdx, matchIdx, matchClass, cid, copyright)
        const autoRelatedVideos = autoRelatedVideosRes.data?.hits?.hits?.map(it => new VideoContentItem(it._source));
        const relatedVideosRes = yield call(videoApi.getVideos, relationCids, copyright);
        const ignoreIds = autoRelatedVideos.map(it => it.cid)
        const relatedVideos = relatedVideosRes.data?.hits?.hits?.map(it => new VideoContentItem(it._source)).filter(it => !ignoreIds.includes(it.cid));

        const combined = [
            ...autoRelatedVideos,
            ...relatedVideos,
        ]

        return yield put({type: API_ACTIONS.GET_VIDEO_RELATION_LIST_SUCCESS, payload: combined});
    }
    catch(e) {
        return yield put({type: API_ACTIONS.GET_VIDEO_RELATION_LIST_ERROR});
    }

}


/**
 * @param state
 * @param action
 * @returns {*|{videoFile: {data: null, loading: boolean, error: null}}}
 */
export default function relationReducer(state = initialState, action) {
    switch (action.type) {
        case API_ACTIONS.GET_VIDEO_RELATION_LIST:
        case API_ACTIONS.GET_VIDEO_RELATION_LIST_ERROR:
        case API_ACTIONS.GET_VIDEO_RELATION_LIST_SUCCESS:
            return handleAsyncActions(API_ACTIONS.GET_VIDEO_RELATION_LIST, 'videoRelationList')(state, action);

        case API_ACTIONS.GET_IMAGE_RELATION_LIST:
        case API_ACTIONS.GET_IMAGE_RELATION_LIST_ERROR:
        case API_ACTIONS.GET_IMAGE_RELATION_LIST_SUCCESS:
            return handleAsyncActions(API_ACTIONS.GET_IMAGE_RELATION_LIST, 'imageRelationList')(state, action);

        case SAGA_ACTIONS.GET_VIDEO_RELATION: {
            return {
                ...state,
                videoRelationPayload: action.payload,
            }
        }

        case ACTIONS.CLEAR_RELATION_LIST:
            return {
                ...state,
                videoRelationList: reducerUtils.initial(),
                imageRelationList: reducerUtils.initial(),
                videoRelationPayload: null,
            }

        default:
            return state;
    }
}