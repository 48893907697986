import React, {createRef, forwardRef, useEffect, useImperativeHandle, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {scheme, ThumbCDNUrl} from "../../util/url";
import {selectEvent} from "../../modules/videoReducer";
import useWindowDimensions from "../useWindowDimensions";
import {clearPip, PipInfo, popPip, putPip} from "../../modules/pipReducer";
import {useLocation, useNavigate} from "react-router-dom";
import {escapeUri} from "../../util/resourceUtil";
import classNames from "classnames";

/**
 *
 * @property {boolean} isMobile
 * @property {string} spriteUrl
 * @property {[MatchInfo]} matchInfoList
 * @property {[]} eventList
 * @property {[]} bookMarkList
 * @property {[]} videoSources
 * @property {boolean} useFloating
 * @property {WatermarkInfo} watermakrInfo
 */
export class PlayerOption{
    constructor({
                    isMobile = false,
                    path,
                    fileId,
                    spriteCount,
                    matchInfoList,
                    eventList,
                    bookMarkList,
                    videoSources,
                    useFloating = false,
                    canFloating = false,
                    offset,
                }) {
        this.isMobile = isMobile;
        this.fileId = fileId;
        this.path = path;
        this.spriteCount = spriteCount;
        this.matchInfoList = matchInfoList;
        this.eventList = eventList;
        this.bookMarkList = bookMarkList;
        this.videoSources = videoSources?.map(it => ({...it, src: escapeUri(it.src)}));
        this.useFloating = useFloating;
        this.canFloating = canFloating;
        this.offset = offset;
    }
}

export class WatermarkInfo{
    constructor({
                    url,
                    width,
                    height,
                    opacity = 0.6,
                    top,
                    right,
                    left = 0,
                    bottom = 0,
                }) {
        this.url = url;
        this.width = width;
        this.height = height;
        this.opacity = opacity;
        this.top = top;
        this.right = right;
        this.left = left;
        this.bottom = bottom;
    }
    static of({
                  cdnUrl,
                  resourcePath,
                  resourceFile,
                  width,
                  height,
                  opacity = 0.6,
                  top,
                  right,
                  left = 0,
                  bottom = 0,
              }) {
        return new WatermarkInfo({
            url: `${scheme}${`${cdnUrl}/${resourcePath}/${resourceFile}`.replaceAll("//", "/")}`,
            width,
            height,
            opacity,
            top,
            right,
            left,
            bottom,
        })
    }
}

export class MatchInfo{
    constructor({
                    id,
                    type,
                    time,
                    show,
                    direction,
                    label,
                }) {
        this.id = id;
        this.type = type;
        this.time = time;
        this.show = show;
        this.direction = direction;
        this.label = label;
    }

    static fromKey(key, playTime) {
        return new MatchInfo({
            type: 'scene',
            id: this.toMarker(key),
            time: playTime,
            direction: key.toLowerCase().includes('start') ? 'right' : 'left',
            show: this.toLabel(key) !== '',
            label: this.toLabel(key),
        })
    }

    static toMarker(key) {
        switch (key){
            case 'firstHalfStartPos':
                return 'FS';
            case 'firstHalfEndPos':
                return 'FE';
            case 'secondHalfStartPos':
                return 'SS';
            case 'secondHalfEndPos':
                return 'SE';
            case 'extraFirstHalfStartPos':
                return 'EFS';
            case 'extraFirstHalfEndPos':
                return 'EFE';
            case 'extraSecondHalfStartPos':
                return 'ESS';
            case 'extraSecondHalfEndPos':
                return 'ESE';
            case 'shootOutStartPos':
                return 'PSS';
            case 'shootOutEndPos':
                return 'PSE';
            default:
                return '';
        }
    }

    static toLabel(key) {
        switch (key){
            case 'firstHalfStartPos':
                return '전반';
            case 'firstHalfEndPos':
                return '';
            case 'secondHalfStartPos':
                return '후반';
            case 'secondHalfEndPos':
                return '';
            case 'extraFirstHalfStartPos':
                return '연장 전반';
            case 'extraFirstHalfEndPos':
                return '';
            case 'extraSecondHalfStartPos':
                return '연장 후반';
            case 'extraSecondHalfEndPos':
                return '';
            case 'shootOutStartPos':
                return '승부차기';
            case 'shootOutEndPos':
                return '';
            default:
                return '';
        }
    }
}

export class EventInfo{
    constructor({
                    id,
                    // type,
                    time,
                    name,
                    type,
                }) {
        // 이벤트  id가 숫자 기반이면 삭제시 오류남. event_를 prefix로 사용
        this.id = `event_${id}`;
        this.type = type;
        this.time = time;
        this.name = name;
        this.iconType = type;
    }
}

/**
 *
 * @type {React.ForwardRefExoticComponent<React.PropsWithoutRef<PlayerOption> & React.RefAttributes<unknown>>}
 */
const VideoPlayer = forwardRef(({playOption, id: playerId = 'player'}, ref) => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const containerRef = createRef();
    const location = useLocation();
    const [playerInitialized, setPlayerInitialized] = useState(false);
    const [canPlay, setCanPlay] = useState(false);
    const [player, setPlayer] = useState();
    const [scriptLoaded, setScriptLoaded] = useState(typeof window !== 'undefined')
    const [option, setOption] = useState();
    const [showEvent, setShowEvent] = useState(true);
    // const [events, setEvents] = useState([]);
    const [hasSection, setHasSection] = useState(false);
    const [timeUpdateCallback, setTimeUpdateCallback] = useState([]);
    const [eventClickCallback, setEventClickCallback] = useState([]);
    const oldPipInfo = useSelector(state => state.pipReducer.oldPipInfo);
    const pipInfo = useSelector(state => state.pipReducer.pipVideoInfo);
    const [canPlayCallback, setCanPlayCallback] = useState([]);
    const [playerInitCallback, setPlayerInitCallback] = useState([]);
    const { width } = useWindowDimensions();
    const [isMobile, setIsMobile] = useState(false);
    const [usePip, setUsePip] = useState(false);
    const watermarkData = useSelector(state => state.watermarkReducer.videoWatermark);

    useEffect(()=> {
        setIsMobile( width <= 1024);
    }, [width]);

    useEffect(()=> {
        if(option == null){
            return;
        }
        setOption({
            ...option,
            isMobile: isMobile,
        })
    }, [isMobile])

    useEffect(()=> {
        return () => {
            dispose();
        }
    }, [playerId])

    useEffect(()=> {
        if(option == null){
            return;
        }
        if(showEvent){
            // console.log(option.eventList)
            option.matchInfoList.forEach(it => addMathInfo(it));
            option.eventList.forEach(it => addEvent(it));
        }
        else {
            console.log(option)
            removeMatchInfos(option.matchInfoList.map(it => it.id));
            removeEventsRaw(option.eventList.map(it => it.id));
        }
    }, [showEvent])

    let userFlag = false;

    const dispose = () => {
        console.log(`videoPlayer ${playerId} dispose`)
        // console.log(containerRef)
        try{
            // console.log(window.KFAPlayer.instance);
            window.KFAPlayer.instance?.[playerId]?.player?.dispose();
            delete window.KFAPlayer.instance?.[playerId];
            player?.player?.dispose();
            setCanPlay(false);
        }
        catch(e){
            // console.error(e);
        }
        try{
            document.getElementById(`${playerId}-container`).id = playerId;
        }
        catch (e) {
            // console.error(e);
        }
    };

    useEffect(()=> {
        canPlayCallback.forEach(it => it?.(canPlay));
        // if(oldPipInfo != null){
        //     seek(oldPipInfo.time ?? 0);
        // }
    }, [canPlay])

    useEffect(()=> {
        playerInitCallback.forEach(it => it?.(canPlay));
        // if(oldPipInfo != null){
        //     seek(oldPipInfo.time ?? 0);
        // }
    }, [playerInitialized])

    useEffect(()=> {
        if(oldPipInfo != null && (canPlay === true || playerInitialized == true) && !usePip) {
            if(oldPipInfo.backUrl === location.pathname){
                seek(oldPipInfo.time ?? 0);
                play();
            }
            dispatch(clearPip());
        }
    }, [playerInitialized, canPlay, oldPipInfo])

    const seek = (sec) => {
        userFlag = false;
        return player?.player.currentTime(sec);
    }

    const play = () => {
        return player?.player.play();
    }

    const pause = () => {
        return player?.player.pause();
    }

    useImperativeHandle(ref, ()=>({
        initialize(playOption = {}){
            console.log('videoPlayer outer initialize', playOption)
            setOption(playOption)
        },
        dispose() {
            return dispose();
        },
        seek(sec){
            return seek(sec);
        },
        focus(){
            return player?.player.focus();
        },
        play(){
            return play();
        },
        pause(){
            return pause();
        },

        makePip() {
            setUsePip(true);
            if(containerRef.current != null && !containerRef.current?.className?.includes('KFA-floating-mode')){
                containerRef.current.className = containerRef.current.className + " KFA-floating-mode" ;
            }
        },
        addPlayerLoadedCallback(callback) {
            setCanPlayCallback(c => [
                ...c,
                callback,
            ])
        },
        removePlayerLoadedCallback(callback){
            setCanPlayCallback(c => [
                ...c.filter(it => it !== callback),
            ]);
        },
        addPlayerInitCallback(callback) {
            setPlayerInitCallback(c => [
                ...c,
                callback,
            ])
        },
        removePlayerInitCallback(callback){
            setPlayerInitCallback(c => [
                ...c.filter(it => it !== callback),
            ]);
        },
        addEventClickCallback(callback){
            setEventClickCallback([
                ...eventClickCallback,
                callback,
            ])
        },
        removeEventClickCallback(callback){
            setEventClickCallback([
                ...eventClickCallback.filter(it => it !== callback),
            ]);
        },
        addTimeUpdateCallback(callback){
            timeUpdateCallback.push(callback)
        },
        removeTimeUpdateCallback(callback){
            setTimeUpdateCallback(timeUpdateCallback.filter(it => it !== callback))
        },
        /**
         * @returns {number} 초단위
         */
        getCurrentPlayTime(){
            return player?.player?.currentTime();
        },
        getOption() {
            return option;
        },
        /**
         *
         * @param {MatchInfo} data
         */
        addMathInfo(data){
            addMathInfo(data);
        },
        /**
         *
         * @param {MatchInfo} data
         */
        modifyMatchInfo(data){
            window.KFAPlayer.instance?.[playerId]?._modifyMatchMarker(data);
        },

        /**
         *
         * @param {number} id
         */
        removeMatchInfo(id){
            removeMatchInfo(id)
        },

        /**
         *
         * @param {EventInfo} data
         */
        addEvent(data){
            addEvent(data)
        },

        /**
         *
         * @param id
         */
        removeEvent(id){
            removeEvent(id)
        },

        /**
         *
         * @param ids
         */
        removeEvents(ids){
            removeEvents(ids);
        },

        setSection(start, end) {
            // console.log(`setSection ${start} ${end}`)
            const data = {
                start: start,
                end: end,
                isRepeat: false,
            }
            window.KFAPlayer.instance?.[playerId]?._setSection(data);
            setHasSection(true);
        },

        removeSection() {
            if(hasSection){
                window.KFAPlayer.instance?.[playerId]?._removeSection();
            }
            setHasSection(false);
        },

        /**
         *
         * @param {EventInfo} data
         */
        modifyEvent(data){
            window.KFAPlayer.instance?.[playerId]?._modifyEventMarker(data);
        },
        activeEvent(eventId){
            window.KFAPlayer.instance?.[playerId]?._clickEventMarker({
                id: `event_${eventId}`,
                active: true,
            });
        }
    }));

    /**
     *
     * @param {EventInfo} data
     */
    const addEvent = (data) => {
        if(!showEvent) return;
        window.KFAPlayer.instance?.[playerId]?._drawEventMarker(data);
    }

    /**
     *
     * @param id
     */
    const removeEvent = (id)=> {
        window.KFAPlayer.instance?.[playerId]?._removeEventMarker({_eventId: [`event_${id}`]});
    }

    /**
     *
     * @param ids
     */
    const removeEvents = (ids) => {
        window.KFAPlayer.instance?.[playerId]?._removeEventMarker({_eventId: ids.map(id => `event_${id}`)});
    }

    /**
     *
     * @param {string} id
     */
    const removeEventRaw = (id) => {
        window.KFAPlayer.instance?.[playerId]?._removeEventMarker({_eventId: [id]});
    }

    /**
     *
     * @param {string} ids
     */
    const removeEventsRaw = (ids) => {
        window.KFAPlayer.instance?.[playerId]?._removeEventMarker({_eventId: ids});
    }
    /**
     *
     * @param {MatchInfo} data
     */
    const addMathInfo = (data) => {
        if(!showEvent) return;
        window.KFAPlayer.instance?.[playerId]?._drawMatchMarker(data);
    }

    /**
     *
     * @param {number} id
     */
    const removeMatchInfo = (id) => {
        window.KFAPlayer.instance?.[playerId]?._removeMatchMarker({_matchId: [id]});
    }

    /**
     *
     * @param {number} id
     */
    const removeMatchInfos = (ids) => {
        window.KFAPlayer.instance?.[playerId]?._removeMatchMarker({_matchId: ids});
    }

    useEffect(()=>{
        // console.log(option);
        console.log(`videoPlayer setOption`, option);
        if(option != null && scriptLoaded){
            playerInitialize();
        }
    }, [option]);

    useEffect(()=> {
        if(player != null){
            // if(player.player == null ){
            //     console.error('player not ready');
            //     return;
            // }
            console.log(player);
            // 최초 재생 가능 상태시, loadCallback 호출
            player.player.one('canplay', () => {
                // FIXME Safari canPlay 이벤트 발생 안함.
                console.log('videoPlayer canplay');
                setCanPlay(true)
                // setPlayerLoaded(true);
                // loadCallback.forEach(it => it?.(true));
            });

            player.player.on('play', ()=> {
                setCanPlay(true)
                if(option?.useFloating !== true) {
                    dispatch(popPip());
                }
            });

            var previousTime = 0;
            var currentTime = 0;
            var seekStart = null;
            player.player.on('timeupdate', function() {
                previousTime = currentTime;
                currentTime = player.player.currentTime();
                // console.log(`timeupdate ${player.player.currentTime()}`)
                // timeUpdateCallback.forEach(it => it.call(player.player.currentTime()));
            });
            player.player.on('seeking', function() {
                if(seekStart === null) {
                    seekStart = previousTime;
                }
            });
            player.player.on('seeked', function() {
                // 사용자 탐색 이벤트 처리
                const time = Math.floor(currentTime);
                // console.log(time);
                for(let i in window.timeUpdateCallback){
                    window.timeUpdateCallback[i](time, userFlag);
                }
                userFlag = true;
                // console.log('seeked from', seekStart, 'to', currentTime, '; delta:', currentTime - previousTime);
                seekStart = null;
            });

            player.player.on("seeking", function (e) {
                // console.log("Video seeking: " + player.player.currentTime());
            });
        }
    }, [player])

    const playerInitialize = () => {
        console.log('videoPlayer inner initialize')
        dispose();
        if(option.videoSources == null || option.videoSources.length === 0){
            setCanPlay(false);
            return;
        }
        const mobile = isMobile;

        // 초기화 시 가 아닌, 재생 시 제거
        // if(option.useFloating === false) {
        //     dispatch(popPip());
        // }

        let sprites = [];

        if(option.path != null && option.fileId != null && option.spriteCount){
            for (let i = 0; i < option.spriteCount; i++) {
                sprites.push({
                    url: `${scheme}${ThumbCDNUrl}${option.path}${option.fileId}/sprite/${i}.jpg`, // 이미지 URL
                });
            }
        }
        // setEvents(option.eventList);

        let watermarkInfo;
        if(watermarkData != null){
            watermarkInfo = WatermarkInfo.of({...watermarkData._source});
        }
        const playerOptions = {
            /** 플레이어 비디오 소스 */
            src: option.videoSources,
            /** 해상도 */
            qualitySelector: !mobile, //해상도 수동
            qualities: option.videoSources,
            qualityDefault: 'FHD',

            /** 스프라이트 섬네일 */
            spriteThumbnail: {
                use: sprites?.length > 0,
                url: sprites,
                interval: 2,
                width: 160,
                height: 90,
                frame: 200
            },
            /** 구간 스킵 */
            sectionSkip: !mobile,
            screenSectionSkip: !mobile,

            /** 설정 메뉴 */
            settingsMenuItem: {
                //세팅된메뉴(배속, 자막, 해상도)
                qualitySelector: true, //해상도
                subsCapsButton: false, //자막
                playbackRateMenuButton: true, //배속
                /** 경기 기록 보기 */
                gameRecordButton: {
                    /** 사용여부 */
                    use: true,
                    /** 체크여부 */
                    defaultChecked: true,
                    /** 클릭 이벤트 콜백 */
                    callback: (e, checked) => {
                        console.log("videoplayer 경기기록 보기", checked);
                        setShowEvent(checked);
                    },
                },
            },

            offset: option.offset ?? { use: false },

            matchInfo: {
                matchInfoUse: option.matchInfoList != null, // 컨트롤바 중앙 경기 정보 표시 여부
                matchMarkerUse: option.matchInfoList != null && option.matchInfoList.length !== 0,  // 프로그레스바 경기 정보 마커 표시 여부
                list: option.matchInfoList,
            },

            bookmark: {
                use: option.bookMarkList != null && option.bookMarkList.length !== 0,
                list: option.bookMarkList,
                setCallback: function(e, _player) {
                    console.log("북마크 생성!");
                },
                removeCallback: function(e, _player){
                    console.log("북마크 삭제!");
                }
            },

            eventmark: {
                use: option.eventList != null && option.eventList.length !== 0,
                list: option.eventList,
                clickCallback: function(e, _eventId) {
                    console.log(`이벤트 마커 클릭: ${_eventId}`);
                    // eventClickCallback.forEach(it => it?.(e));
                    dispatch(selectEvent(Number(_eventId.replaceAll("event_", ""))));
                }
            },

            floatingMode: {
                use: option.canFloating, // container class > KFA-floating-mode toggle
                clickCallback: (active) => {
                    // console.log(`floating ${active}`);
                    // pip 플레이어에서 일반 클릭 이벤트시 여기 진입되면서 floating mode 풀리는 문제 있음
                    const container = document.querySelector(`#${playerId}-container`)
                    if(option.useFloating){
                        if(container != null && !container.className.includes("KFA-floating-mode")){
                            container.className = container.className + " KFA-floating-mode";
                        }
                    }
                    else if(active) {
                        // 플레이어 일시장저
                        window.KFAPlayer.instance?.[playerId]?.player?.pause();

                        // 플레이어가 제동으로 추가되는 floating 클래스 제거
                        if(container != null && container.className.includes("KFA-floating-mode")){
                            container.className = container.className.replaceAll("KFA-floating-mode", "");
                        }

                        dispatch(putPip(new PipInfo({
                                playerOption: option,
                                backUrl: location.pathname,
                                time:window.KFAPlayer.instance?.[playerId]?.player?.currentTime(),
                            },
                        )));
                        // dispose();
                    }
                }
            },

            mobile,
            customSettingBtn: {
                onclick: function(e, active, _player) {
                    console.log(`커스텀 설정 메뉴 클릭 ${active}`);
                }
            },

            waterMark: {
                use: watermarkInfo != null,
                ...watermarkInfo,
            },

            chromecast: false,
        };

        try{
            // const beforeTime = window.KFAPlayer.instance?.player?.player?.currentTime();
            // console.log(beforeTime)
            // console.log(playerOptions)
            const KFAPlayer = window.KFAPlayer['default'];
            const _player = new KFAPlayer(playerId, playerOptions, function(video, player){
                console.log('videoPlayer ready');
                setPlayerInitialized(true);
                // loadCallback.forEach(it => it?.());
                // console.log(containerRef);
            });

            if(window.KFAPlayer.instance == null){
                window.KFAPlayer.instance = {};
            }
            window.KFAPlayer.instance[playerId] = _player;
            // console.log(containerRef);
            // console.log(_player);
            // console.log(window.KFAPlayer.instance);
            setPlayer(_player);
        }
        catch (e) {
            console.error(e)
        }
    }

    useEffect(() => {
        window.timeUpdateCallback = timeUpdateCallback;
    }, [timeUpdateCallback])

    return <div style={{
        paddingBottom: '50px',
    }}>
        <div style={{
            backgroundColor: '#000',
            color: '#fff',
            aspectRatio: 16 / 9,
        }}>
            {
                scriptLoaded  && <div style={{
                    display: 'block',
                }}>
                    <div className={classNames({
                        'notranslate': true,
                        'KFA-floating-mode': option?.useFloating ?? false,
                    })}
                         translate="no" id={playerId} ref={containerRef} style={{
                        display: !playerInitialize ? 'none' : null,
                        aspectRatio: 16 / 9,
                    }}/>
                </div>
            }
            {
                !playerInitialize &&
                <div className="flex flex-col items-center justify-center h-full" >
                    <div className={"loader"}></div>
                    <span>불러오는 중입니다.</span>
                </div>
            }
        </div>
    </div>
})

export default VideoPlayer;