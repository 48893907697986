import {useEffect, useMemo, useState} from "react";
import ThumbnailImage from "../../../../common/component/ThumbnailImage";
import PageIndicator from "../../../../common/component/PageIndicator";
import {useDispatch, useSelector} from "react-redux";
import classNames from "classnames";
import {selectEvent} from "../../../../modules/videoReducer";
import {makeEventTitle, makeMatchTeamVerse} from "../../../../util/eventUtil";
import FlexFillCenter from "../../../../common/component/FlexFillCenter";
import NotFound from "../../../../common/component/NotFound";
import Config from "../../../../config/config";
import ErrorBoundary from "../../../../common/component/ErrorBoundary";
import EventFilter from "../../../../common/component/event/EventFilter";
import {eventCodeList} from "../../../../util/dummy";
import VideoOffsetUtil from "../../../../lib/videoOffsetCalcUtil2";

/**
 *
 * @param {[EventModel]} eventList
 * @param onEventFilterChange
 * @returns {JSX.Element}
 * @constructor
 */
export default function EventList({eventList, onEventFilterChange}, ) {
    const dispatch = useDispatch();
    const pageSize = Config.videoSideContentPageSize;
    const [filterList, setFilterList] = useState([]);
    const [showList, setShowList] = useState(eventList);
    const [showPageList, setShowPageList] = useState([]);
    const [page, setPage] = useState(0);
    const selectedEvent = useSelector(state => state.videoReducer.selectEvent);
    const video = useSelector(state => state.videoReducer.videoContent)

    const videoOffsetUtil = useMemo(() => {
        return VideoOffsetUtil.fromPortalVideo(video);
    }, []);

    useEffect(()=> {
        setShowList(eventList.filter(it => filterList.includes(it.eventCd)))
        // console.log(eventList)
    }, [eventList, filterList]);

    useEffect(()=> {
        setPage(0);
        setShowPageList([...showList].splice(0, pageSize))
        // console.log(showList)
    }, [showList])

    useEffect(()=> {
        setShowPageList([...showList].splice(page * pageSize, pageSize))
        // console.log([...showList].splice(page * pageSize, pageSize))
    }, [page])

    useEffect(()=>{
        if(selectedEvent != null){
            const event = eventList.find(it => it.eventId == selectedEvent);
            if(event != null){
                findPage(event);
            }
        }
    }, [selectedEvent]);

    const onEventClick = (event) => {
        dispatch(selectEvent(event.eventId));
    }

    const findPage = (event) => {
        const index = showList.findIndex(it => it.eventId == event.eventId);
        if(index >= 0){
            setPage(Math.floor(index / pageSize))
        }
    }

    const timeTextWrap = (text) => {
        if(text.includes('+')){
            let textList = text.split('+');
            return `${textList[0].substring(0, 2)}\n${textList[0].substring(2)}\n+${textList[1]}`;
        }
        else {
            return text;
        }
    }

    const makeInfo = (event) => {
        return `${event.videocontent.match_date} / ${event.matchName}`
    }

    const onFilterChange = (filterList) => {
        // console.log('onFilterChange', filterList);
        setFilterList(filterList.map(it => it.eventCd));
        onEventFilterChange?.(filterList);
    };

    return (
        <ErrorBoundary>
            {
                eventList.length === 0 &&
                <article className="game__area">
                    <div className="block__list size__mb">
                        <FlexFillCenter children={<NotFound message={'경기기록이 없습니다.'}/>}/>
                    </div>
                </article>
            }
            {
                eventList.length > 0 &&
                <>
                    <EventFilter
                        codeList={eventCodeList}
                        onFilterChange={onFilterChange}
                    />
                    <article className="game__area">
                        <div className="block__list size__mb">
                            {
                                showPageList.length === 0 &&
                                <FlexFillCenter children={<NotFound message={'경기기록이 없습니다.'}/>}/>
                            }
                            <ul>
                                {
                                    showPageList.map((it, index) =>
                                        <li
                                            key={`event_list_item_${index}`}
                                            className={classNames({
                                                'active': it.eventId == selectedEvent
                                            })}
                                        >
                                            <a onClick={(e)=> onEventClick?.(it)}>
                                                <div
                                                    className="video__card"
                                                    style={{
                                                        alignItems: 'center',
                                                    }}
                                                >
                                                    <div className="txt_subject time">{timeTextWrap(videoOffsetUtil.getEventTimeOffsetText(it.timeMisec, it.injuryTimeMisec))}</div>
                                                    <div className="video__prev">
                                                        <div className="video_thum">
                                                            <ThumbnailImage src={it.thumbnail}/>
                                                        </div>
                                                    </div>
                                                    <div className="video__info">
                                                        <div className="txt_bold txt_primary">{it.eventNm}</div>
                                                        <div className="txt_subject ellipsis-line-1">{makeEventTitle(it)}</div>
                                                        <div className="txt_subject ellipsis-line-1" title={makeMatchTeamVerse(it)}>{makeMatchTeamVerse(it)}</div>
                                                        <div className="txt_subject ellipsis-line-1">{makeInfo(it)}</div>
                                                    </div>
                                                </div>
                                            </a>
                                        </li>
                                    )
                                }
                            </ul>
                        </div>
                        <PageIndicator
                            pageSize={pageSize}
                            page={page}
                            setPage={setPage}
                            itemCount={showList.length}
                            showPageSize={3}
                        />
                    </article>
                </>
            }
        </ErrorBoundary>
    )
}