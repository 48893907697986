
export default function GalleryGridItem({index, item, showDialog, className}) {
    return  <div
        key={`image_${item.cid}`}
        className={`${className} image_card`}
        onClick={e => showDialog(item, index)}
    >
        <div className="css-grid__link">
            <img className="absolute-bg" style={{objectFit: 'contain'}} src={item.thumbSrc}/>
            <div className="css-grid-desc p-4 h-full flex-col justify-end">
                <div className="txt_sub txt_bold mb_4">{item.menuNm}</div>
                <div style={{
                    overflow: "hidden",
                    textOverflow: 'ellipsis',
                    lineClamp: 2,
                }}>{item.title}</div>
            </div>
        </div>
    </div>
}