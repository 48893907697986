import {Outlet} from "react-router-dom";
import RouteChangeTracker from "../../common/component/RouteChangeTracker";

export default function GAContainer() {

    RouteChangeTracker();

    return (
        <Outlet/>
    )
}