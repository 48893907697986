import {useDispatch, useSelector} from "react-redux";
import {useEffect, useMemo, useRef, useState} from "react";
import {clearVideo, getEventList, getVideo, selectEvent} from "../../../modules/videoReducer";
import {useLocation, useNavigate, useParams} from "react-router-dom";
import VideoContentItem from "../../../apis/dto/VideoContentItem";
import VideoPlayer, {EventInfo, MatchInfo, PlayerOption} from "../../../common/component/videoPlayer";
import classNames from "classnames";
import {Tab, TabList, TabPanel, Tabs} from "react-tabs";
import EventList from "./component/EventList";
import EventModel from "../model/EventModel";
import RelativeVideoList from "./component/RelativeVideoList";
import RelativeImageList from "./component/RelativeImageList";
import {scheme} from "../../../util/url";
import {useLoadingModal} from "../../../common/component/LoadingState";
import {eventCodeList} from "../../../util/dummy";
import {applyMatchOffset, findIconType} from "../../../lib/videoOffsetCalcUtil";
import {getWatermark} from "../../../modules/watermarkReducer";
import {getAsFlat} from "../../../lib/menu/menuUtil";
import Attachments from "../../../common/component/Attachments";
import Hashtags from "../../../common/component/Hashtags";
import {ShowAlertModal} from "../../../common/component/modal/AlertModal";
import {
    addFavoriteContent,
    clearUpdateFavoriteContentResult,
    deleteFavoriteContent,
    requestDownload
} from "../../../modules/userReducer";
import PartialDownloadTable, {PartialDownloadState} from "./component/PartialDownloadTable";
import VideoDownloadTable from "./component/VideoDownloadTable";
import useDownloadRequestModal from "./component/useDownloadRequest";
import {handleContentCode} from "../../../util/contentUtil";
import {getTotalVideoRelation} from "../../../modules/relationReducer";
import VideoOffsetUtil from "../../../lib/videoOffsetCalcUtil2";

export default function GamePlayPage({}) {
    const navigate = useNavigate();
    const location = useLocation();
    const param = useParams();
    const dispatch = useDispatch();

    const videoPlayerRef = useRef();

    useDownloadRequestModal();

    const menus = useSelector(state => state.menuReducer.menus);
    const watermark = useSelector(state => state.watermarkReducer.watermarkResult);
    const videoWatermark = useSelector(state => state.watermarkReducer.videoWatermark);
    const video = useSelector(state => state.videoReducer.videoContent)
    const event = useSelector(state => state.videoReducer.eventList);
    const loginData = useSelector(state => state.userReducer.loginData);

    const [data, setData] = useState(new VideoContentItem());
    const [videoTypeList, setVideoTypeList] = useState([]);
    const [selectedVideoTypeIndex, setSelectedVideoTypeIndex] = useState(null);
    const [videoPlayOption, setVideoPlayOption] = useState();
    const [oldVideoPlayOption, setOldVideoPlayOption] = useState();
    const selectedEvent = useSelector(state => state.videoReducer.selectEvent);
    const [showEventList, setShowEventList] = useState(true);

    const {isLoading, hasError} = useLoadingModal([video, event, watermark, menus]);

    const [isFavorite, setIsFavorite] = useState(false);
    const addFavoriteContentResult = useSelector(state => state.userReducer.addFavoriteContentResult);
    const deleteFavoriteContentResult = useSelector(state => state.userReducer.deleteFavoriteContentResult);
    const requestDownloadResult = useSelector(state => state.userReducer.requestDownload);
    const [canDownload, setCanDownload] = useState(false);
    const [partialDownloadState, setPartialDownloadState] = useState(null);
    const [eventFilter, setEventFilter] = useState([]);

    const videoOffsetUtil = useMemo(() => {
        return VideoOffsetUtil.fromPortalVideo(video);
    }, [video]);


    useEffect(() => {
        if (!isLoading) {
            if (location.state?.eventId != null) {
                videoPlayerRef.current?.addPlayerLoadedCallback(() => {
                    dispatch(selectEvent(location.state.eventId))
                })
            }
        }
        return () => {
            if (location.state?.eventId != null) {
                dispatch(selectEvent(null))
            }
        }
    }, [isLoading])

    const primaryEventList = useMemo(() => {
        if(event.data == null){
            return [];
        }
        else {
            let data = event.data?.data?.hits?.hits?.map(it => it._source).map(it => EventModel.of2(it));
            data.sort((a,b) => Number(a.eventId) - Number(b.eventId));
            // console.log('primaryEventList', data);
            return data;
        }
    }, [event.data, event.loading, event.error])

    const filteredEventList = useMemo(() => {
        // console.log('eventFilter changed', eventFilter);
        const codeList = eventFilter.map(it => it.eventCd);
        // console.log('filteredEventList changed', primaryEventList.filter(it => codeList.includes(it.eventCd)));
        return primaryEventList.filter(it => codeList.includes(it.eventCd));
    }, [primaryEventList, eventFilter])

    useEffect(() => {
        dispatch(getVideo(param.id))
        dispatch(getEventList(param.id))
        dispatch(getWatermark());
        setCanDownload(loginData?.download_ac >= 2)

        // ShowLoading()
        return () => {
            dispatch(clearVideo())
        }
    }, [param.id])

    useEffect(() => {
        if (isLoading === false) {
            console.log('isLoading false', watermark, event, video);
            handleContentCode(video.data.data, loginData, dispatch); // video
            const data = video?.data?.data?._source;
            setIsFavorite(video?.data?.data?.faverYN === "Y");
            const tokenUrl = {};
            Object.keys(video?.data?.data).filter(it => it.startsWith('tokenUrl')).forEach(key => tokenUrl[key] = video?.data?.data?.[key]);
            const newVideoData = new VideoContentItem({
                    ...data,
                    tokenUrl,
                }
            )
            setData(newVideoData);

            dispatch(getTotalVideoRelation({
                cid: newVideoData.videocontent.cid, // 이벤트는 cid 필터 제외
                singleIdx: newVideoData.videocontent.single_idx,
                matchIdx: newVideoData.videocontent.match_idx,
                matchClass: newVideoData.videocontent.match_class,
                relationCids: newVideoData.videorelation.map(it => it.refcid)
            }));

            setVideoTypeList(
                data.file
            )
            // console.log('menus',menus);
            const menuList = getAsFlat(menus.data);
            const menu = menuList.find(it => it.menuId === data.menuId);
            if (menu != null) {
                if (menu.matchMappingYN === "N") {
                    setShowEventList(false);
                }
                else {
                    setShowEventList(true);
                }
            }
        }
    }, [isLoading])

    useEffect(() => {
        if (videoTypeList.length > 0) {
            // console.log('videoTypeList', videoTypeList)
            setSelectedVideoTypeIndex(0);
        }
    }, [videoTypeList])

    useEffect(() => {
        // console.log('selectedVideoTypeIndex', selectedVideoTypeIndex)
        if (event.data != null) {
            if (selectedVideoTypeIndex != null) {
                if (videoTypeList[selectedVideoTypeIndex] != null) {
                    initializePlayer(videoTypeList[selectedVideoTypeIndex])
                    // dispatch(selectEvent(null)); // 영상 변경 시 선택 이벤트 해제
                }
            }
        }
    }, [videoTypeList, selectedVideoTypeIndex, filteredEventList])

    useEffect(() => {
        // console.log('videoPlayOption', videoPlayOption)
        if (videoPlayOption != null) {
            let time = videoPlayerRef.current?.getCurrentPlayTime();
            // let oldEvent = selectedEvent;
            dispatch(selectEvent(null));
            const callback = () => {
                videoPlayerRef.current?.removePlayerLoadedCallback(callback);
                if(time != null){
                    let oldMatchInfoList = oldVideoPlayOption?.matchInfoList;
                    let newMatchInfoList = videoPlayOption?.matchInfoList;
                    const canCalculate = ( oldMatchInfoList != null && newMatchInfoList != null )
                    if(!canCalculate){
                        // 단순 이전 재생 시간 복구
                        videoPlayerRef.current?.seek(time);
                    }
                    else {
                        console.log('time', time, 'oldMatchInfoList', oldMatchInfoList, 'newMatchInfoList', newMatchInfoList);
                        const offsetTime = videoOffsetUtil.videoOffsetToEventTime(time, oldMatchInfoList);
                        const newTime = videoOffsetUtil.eventToVideoOffsetWithListWithSec(offsetTime.time, offsetTime.injuryTime, newMatchInfoList, null, true);
                        videoPlayerRef.current?.seek(newTime);
                    }
                }
            };
            videoPlayerRef.current?.addPlayerLoadedCallback(callback);

            videoPlayerRef.current?.initialize(videoPlayOption)
        }
    }, [videoPlayOption])


    const toggleFavorite = () => {
        if (!isFavorite) {
            dispatch(addFavoriteContent({
                userId: loginData.uid,
                userLevelCd: 1,
                cid: data.cid,
                contenttype: "V"
            }));
        } else {
            dispatch(deleteFavoriteContent({
                userId: loginData.uid,
                cid: data.cid,
                contenttype: "V",
            }));
        }
    }
    useEffect(() => {
        if (addFavoriteContentResult.error) {
            ShowAlertModal({
                message: "찜 목록 추가에 실패했습니다.",
            });
            dispatch(clearUpdateFavoriteContentResult())

        } else if (addFavoriteContentResult.data) {
            if (addFavoriteContentResult.data.data?.code !== '00') {
                ShowAlertModal({
                    message: "찜 목록 추가에 실패했습니다.",
                });
            } else {
                ShowAlertModal({
                    message: "찜 목록에 추가되었습니다.",
                });
                setIsFavorite(true)
            }
            dispatch(clearUpdateFavoriteContentResult())
        }
    }, [addFavoriteContentResult])


    useEffect(() => {
        if (deleteFavoriteContentResult.error) {
            ShowAlertModal({
                message: "찜 목록 제거에 실패했습니다.",
            });
            dispatch(clearUpdateFavoriteContentResult())

        } else if (deleteFavoriteContentResult.data) {
            if (deleteFavoriteContentResult.data.data?.code !== '00') {
                ShowAlertModal({
                    message: "찜 목록 제거에 실패했습니다.",
                });
            } else {
                ShowAlertModal({
                    message: "찜 목록에서 제거되었습니다.",
                });
                setIsFavorite(false)
            }
            dispatch(clearUpdateFavoriteContentResult())
        }
    }, [deleteFavoriteContentResult])

    const initializePlayer = (videoSource) => {
        // console.log('initializePlayer', videoSource)
        if (videoSource?.cid != null) {
            const videoSources = [];
            const tokenUrl = data.tokenUrl[`tokenUrl_${videoSource.fileId}`];
            if (videoSource.proxyFile1) {
                videoSources.push({
                    src: `${scheme}${videoSource.proxy_cdnUrl}${videoSource.orgPath}${videoSource.proxyFile1}${tokenUrl}`,
                    type: "video/mp4",
                    label: "FHD",
                    ...videoSource,
                })
            }
            if (videoSource.proxyFile2) {
                videoSources.push({
                    src: `${scheme}${videoSource.proxy_cdnUrl}${videoSource.orgPath}${videoSource.proxyFile2}${tokenUrl}`,
                    type: "video/mp4",
                    label: "HD",
                    ...videoSource,
                })
            }
            if (videoSource.proxyFile3) {
                videoSources.push({
                    src: `${scheme}${videoSource.proxy_cdnUrl}${videoSource.orgPath}${videoSource.proxyFile3}${tokenUrl}`,
                    type: "video/mp4",
                    label: "SD",
                    ...videoSource,
                })
            }
            // console.log(event.data);
            // console.log(Object.keys(videoSource).filter(it => videoSource[it] != null ).filter(it => it.toLowerCase().endsWith("pos")).map(it => MatchInfo.fromKey(it, videoSource[it])))
            let matchInfoList = Object.keys(videoSource)
                .filter(it => videoSource[it] != null)
                .filter(it => it.toLowerCase().endsWith("pos"))
                .map(it => MatchInfo.fromKey(it, videoSource[it]));
            matchInfoList = videoOffsetUtil.applyMatchOffset(matchInfoList)

            console.log('matchInfoList', matchInfoList);
            // console.log(event.data);
            // const eventList = event.data?.filter(it => eventFilter.find(filter => filter.value === it.eventCd) != null).map(it => {
            const eventList = filteredEventList.map(it => {
                const iconType = findIconType(eventCodeList, it.eventCd)
                // console.log(eventThoVideoOffset(it.timeMisec, it.injuryTimeMisec, matchInfoList[0]))
                return new EventInfo({
                    id: `${it.eventId}`,
                    time: videoOffsetUtil.eventToVideoOffsetWithList(it.timeMisec, it.injuryTimeMisec, matchInfoList),
                    name: `${videoOffsetUtil.getEventTimeOffsetText(it.timeMisec, it.injuryTimeMisec)} ${it.hname ?? ''} ${it.eventNm}`,
                    type: iconType,
                })
            }).filter(it => it.type != null && it.type !== '');
            console.log('eventList', eventList);
            // console.log(eventList.filter(it => it.type != null && it.type !== ''),);
            setOldVideoPlayOption(videoPlayOption);
            setVideoPlayOption(new PlayerOption({
                videoSources: videoSources,
                matchInfoList: matchInfoList,
                eventList: eventList.filter(it => it.type != null && it.type !== ''),
                fileId: videoSource.fileId,
                spriteCount: videoSource.spriteFiles,
                path: videoSource.proxyPath,
                canFloating: true,
            }));


            setPartialDownloadState(new PartialDownloadState({
                // fileTitle: videoSource.orgFile ?? data.videocontent?.title?.replace(" ", "_"),
                fileTitle: `${data.videocontent?.ma_title ?? ''}_${data.videocontent?.title ?? ''}`.replaceAll(" ", "_"),
                video_fileId: videoSource.fileId,
                video_cid: data.cid,
                uid: loginData.uid,
                sourceType: 'V',
            }))
        }
    };

    // 이벤트 선택 시 플레이어 이동 및 구간 지정
    useEffect(() => {
        let hasIconType = false;
        videoPlayerRef.current?.removeSection();
        let event = null;
        if (selectedEvent != null && videoPlayOption != null) {
            event = primaryEventList.find(it => it.eventId == selectedEvent);
            if (event != null) {
                const iconType = findIconType(eventCodeList, event.eventCd)
                hasIconType = iconType != null && iconType !== ''
                if (hasIconType) {
                    videoPlayerRef.current?.activeEvent(event.eventId);
                } else {
                    // 아이콘이 없는 이벤트는 추가되어있지 않아, 추가하고 선택
                    const eventInfo = new EventInfo({
                        id: event.eventId,
                        time: videoOffsetUtil.eventToVideoOffsetWithList(event.timeMisec, event.injuryTimeMisec, videoPlayOption.matchInfoList),
                        name: event.eventNm,
                    })
                    videoPlayerRef.current?.addEvent(eventInfo);
                    videoPlayerRef.current?.activeEvent(event.eventId);
                }

                // const startTimes = event.startTime.replaceAll(" " , "").split("+")
                // const endTimes = event.endTime.replaceAll(" " , "").split("+")
                const {startTime, endTime} = videoOffsetUtil.getSection(event, videoPlayOption.matchInfoList);
                // const startTime = videoOffsetUtil.eventToVideoOffsetWithList(startTimes[0], startTimes[1], videoPlayOption.matchInfoList);
                // const endTime = videoOffsetUtil.eventToVideoOffsetWithList(endTimes[0], endTimes[1], videoPlayOption.matchInfoList);
                videoPlayerRef.current?.seek(startTime);
                const callback = () => {
                    console.log('seeked')
                    videoPlayerRef.current?.removeTimeUpdateCallback(callback);
                    videoPlayerRef.current?.setSection(startTime, endTime);
                }
                videoPlayerRef.current?.addTimeUpdateCallback(callback)
            }
        }

        return () => {
            if (!hasIconType && event != null) {
                // 아이콘 타입이 없는 이벤트는 미선택시 남아있으면 안되어 삭제
                videoPlayerRef.current?.removeEvent(event.eventId);
            }
        }
    }, [videoPlayOption, selectedEvent])

    const handlePartialDownload = (state) => {
        dispatch(requestDownload(state));
    }

    if (isLoading) {
        return ""; // 로딩중
    }

    return (
        <>
            <div className="inner">
                <article className="content__wrap order__wrap">
                    <article className="content__wrap order__item">
                        <div className="content_head mb_32">
                            <div className="head_title">
                                <div className="txt_head3">{data.videocontent?.title ?? ''}</div>
                            </div>
                            <div className="head__tool">
                                <div className={classNames({
                                    "btn btn-sm w-max": true,
                                    "btn-default": !isFavorite,
                                })} onClick={toggleFavorite}>
                                    <i className="icon gas-like"></i><span>찜하기</span></div>
                            </div>
                        </div>
                        <VideoPlayer
                            id={`player${param.id}`}
                            playOption={videoPlayOption}
                            ref={videoPlayerRef}
                        />
                        <div className="video__edit__wrap mt_24">
                            <article className="edit__tool mb_64">
                                {
                                    videoTypeList.map((it, index) =>
                                        <div
                                            key={`rec_type_${index}`}
                                            onClick={(e) => setSelectedVideoTypeIndex(index)}
                                            className={classNames({
                                                'btn btn-default btn-tiny': true,
                                                'active': index === selectedVideoTypeIndex,
                                            })}>
                                            {it.recType}
                                        </div>
                                    )
                                }
                            </article>
                            {
                                canDownload &&
                                <PartialDownloadTable
                                    videoPlayerRef={videoPlayerRef}
                                    initialState={partialDownloadState}
                                    onSubmit={handlePartialDownload}
                                />
                            }

                            <article className="edit_item mb_64">
                                <div className="content_head mb_32">
                                    <div className="head_title">
                                        <div className="txt_head4">경기 정보</div>
                                    </div>
                                </div>
                                <div className="div__table__area">
                                    <div className="row">
                                        <dl>
                                            <dt>등록날짜</dt>
                                            <dd><span>{data.videocontent?.regDt}</span></dd>
                                        </dl>
                                        <dl>
                                            <dt>경기날짜</dt>
                                            <dd><span>{data.videocontent?.match_dt}</span></dd>
                                        </dl>
                                    </div>
                                    <div className="row">
                                        <dl>
                                            <dt>리그명</dt>
                                            <dd><span>{data.videocontent?.ma_title}</span></dd>
                                        </dl>
                                        <dl>
                                            <dt>카테고리</dt>
                                            <dd><span>{data.videocontent?.category}</span></dd>
                                        </dl>
                                    </div>
                                    <div className="row">
                                        <dl>
                                            <dt>홈팀</dt>
                                            <dd><span>{data.videocontent?.team_home}</span></dd>
                                        </dl>
                                        <dl>
                                            <dt>원정팀</dt>
                                            <dd><span>{data.videocontent?.team_away}</span></dd>
                                        </dl>
                                    </div>
                                    <div className="row">
                                        <dl>
                                            <dt>제목</dt>
                                            <dd><span>{data.videocontent?.title}</span></dd>
                                        </dl>
                                    </div>
                                </div>
                            </article>
                        </div>

                        <VideoDownloadTable
                            list={data?.file}
                        />

                        <Attachments
                            list={data?.attach}
                        />
                        <Hashtags
                            list={data?.hash}
                        />
                    </article>
                    <article className="content__wrap order__item order__sub">
                        <Tabs
                            // 탭 전체 렌더 https://github.com/reactjs/react-tabs/issues/294#issuecomment-669142441
                            forceRenderTabPanel={true}
                        >
                            <article className="content__tab__area mb_24">
                                <TabList
                                    className="content_tab">
                                    {
                                        showEventList &&
                                        <Tab>
                                            <div className="txt_head4 tab_title">경기기록</div>
                                        </Tab>
                                    }
                                    <Tab>
                                        <div className="txt_head4 tab_title">관련영상</div>
                                    </Tab>
                                    <Tab>
                                        <div className="txt_head4 tab_title">관련사진</div>
                                    </Tab>
                                </TabList>
                            </article>
                            {
                                showEventList &&
                                <TabPanel>
                                    <EventList
                                        eventList={primaryEventList}
                                        onEventFilterChange={setEventFilter}
                                    />
                                </TabPanel>
                            }
                            <TabPanel>
                                <RelativeVideoList
                                    video={data}
                                />
                            </TabPanel>
                            <TabPanel>
                                <RelativeImageList
                                    list={data.imagerelation}
                                />
                            </TabPanel>

                        </Tabs>
                    </article>
                </article>
            </div>


        </>
    )
}