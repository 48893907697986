const myPageMenu = [
    {
        path: "/mypage/info",
        icon: "info",
        text: "나의 정보",
    },
    {
        path: "/mypage/notification",
        icon: "notice",
        text: "알림",
    },
    {
        path: "/mypage/upload",
        icon: "upload",
        text: "업로드 관리",
        upload_ac: [1, 2, 3]
    },
    {
        path: "/mypage/download",
        icon: "download",
        text: "다운로드 내역",
        download_ac: [1, 2, 3, 4],
    },
    {
        path: "/mypage/favorite",
        icon: "like",
        text: "관심 영상/사진",
        children: [
            {
                path: "/mypage/favorite/image",
                icon: "like",
                text: "관심사진",
            },
            {
                path: "/mypage/favorite/video",
                icon: "like",
                text: "관심영상",
            },
        ]
    },
]

export const acList = [
    {text: '권한없음', code: 0},
    {text: '이미지', code: 1},
    {text: '동영상', code: 2},
    {text: '모든권한', code: 3},
    {text: '모든권한+자동승인', code: 3},
]

export const getUserType = (userCode) => {
    return UserType[userCode] || '기타';
}

export const UserType = {
    'A': '협회',
    'M': '미디어',
    'C': '구단',
    'ETC': '기타',
}

export const acValue = {
    'none': 0,
    'image': 1,
    'video': 2,
    'all': 3,
    'all_auto': 4,
};

export const getMediaDownAcType = (userCode) => {
    return MediaDownAcType[userCode] || '기타';
}

export const MediaDownAcType = {
    '10': '모든권한',
    '20': 'WEB 이미지 권한',
    '99': '권한없음',
}

export default myPageMenu