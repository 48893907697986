import {createRef, forwardRef, useEffect, useImperativeHandle, useState} from "react";
import {createRoot} from "react-dom/client";
import classNames from "classnames";

const ConfirmModal = forwardRef( ({ message, onConfirm, onCancel, show }, ref) =>  {
    const [isShow, setIsShow] = useState(show ?? false)
    const [firstShow, setFirstShow] = useState(true);
    useImperativeHandle(ref, () => ({
        showModal() {
            setIsShow(true)
        },
        hideModal() {
            setIsShow(false)
        },
    }));

    useEffect(()=> {
        if(isShow){
            setFirstShow(false);
        }
    }, [isShow])


    const handleConfirm = () => {
        if(onConfirm){
            onConfirm(ref);
        }
    }

    const handleCancel = () => {
        setIsShow(false)
        if(onCancel){
            onCancel(ref);
        }
    }

    return (
        <div className="modal-confirm">

            <div
                className={classNames({
                    'modal-dialog': true,
                    'fade': true,
                    'hide': !isShow,
                })}

                style={{
                    display: firstShow ? 'none': 'unset',
                }}
            >
                <div className="dim"></div>
                <div className="modal-alert">
                    <div>
                        <div className="message" dangerouslySetInnerHTML={{__html: message}}></div>
                    </div>
                    <div className="btn_area">
                        <div className="btn btn-default" onClick={handleCancel}>취소</div>
                        <div className="btn" onClick={handleConfirm}>확인</div>
                    </div>
                </div>
            </div>
        </div>
    )
})

/**
 * DOM에 <ConfirmModal/> 언선 이후 사용 가능
 * @param message
 * @param onConfirm
 * @param onCancel
 * @returns {JSX.Element}
 * @constructor
 */
export const ShowConfirmModal = ({message, onConfirm, onCancel})=> {
    const ref = createRef();
    const newModal = <ConfirmModal
        ref={ref}
        show={true}
        message={message}
        onConfirm={onConfirm}
        onCancel={onCancel}
    />

    const container = document.querySelectorAll('.modal-confirm')[0];
    const modalRoot = createRoot(container);
    modalRoot.render(newModal)
    return ref;
}


export default ConfirmModal;