import React from "react";

const SearchFilter = (props) => {
    const childrenWithProps = React.Children.map(props.children, (child, index) => {
        if (React.isValidElement(child)) {
            return React.cloneElement(child, {...props, key: `search_filter_${index}`});
        }
        return child;
    });
    return <div key={`search_filter_wrap_${props.id}`} className="searchFilter_wrap" id={`search_${props.id}`}>{childrenWithProps}</div>
}

export default SearchFilter