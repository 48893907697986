
import "./KFASSOIntegratePageStyle.css"
import useSSO from "../../../../lib/useSSO";
import {useNavigate} from "react-router-dom";
import React from "react";
import {KFASSOConfig} from "../../../../config/KFASSOConfig";

export default function KFASSOIntegratePage() {

    const { goSSOIntegrate,} = useSSO();
    const navigate = useNavigate();

    return (
       <div className="kfa-integrate">
           <div id="join_wrap" className="">
               <section className="login_sec join_sec wrapper">
                   <div className="content join">
                       <div className="content-section">
                           <div className="main flex">
                               <div className="bg-crop">
                                   <img className="bg-tiger" src="/image/kfa_105_11_R.png"/>
                               </div>
                               <div className="right_div">
                                   <h1 className="mb-8"> 회원님은 KFA 통합 회원 전환 대상자입니다.<span className="cursor">&nbsp;</span></h1>
                                   <div className="comment">
                                       <dl>
                                           <dt>약관 동의 하시면 기존 PLUS 서비스 ID 로 회원 전환합니다.</dt>
                                           <dt>단, 이미 다른 사용자가 사용 중이시면 다른 ID로 전환해야 합니다.</dt>
                                           <dd>회원전환하시면 KFA 통합 회원 ID 하나로 모든 서비스를 편리하게 이용할 수 있습니다.</dd>
                                       </dl>
                                       <dl>
                                           <dd><a href={KFASSOConfig.faqUrl}>KFA 통합 회원 ID 란 ?</a></dd>
                                       </dl>
                                   </div>
                               </div>
                           </div>

                           <div className="m-l-5r btns-group">
                               <button id="btn_previous" type="button" className="btn-skew-normal-blue"
                                       title="다음에 전환하기" onClick={() => navigate('/')}>다음에 전환하기
                               </button>
                               <button className="btn-skew-normal-red" type="button"
                                       onClick={goSSOIntegrate} value="통합회원 전환하기"
                                       title="통합회원 전환하기">통합회원 전환하기
                               </button>
                           </div>

                           <div className="bottom contact-info">
                               <div>연락처: 대한축구협회 통합회원 CS</div>
                               <div>유선전화: 02-2002-0718</div>
                               <div>이메일: id@kfa.or.kr</div>
                           </div>
                       </div>
                   </div>
               </section>
           </div>
       </div>
    )
}