export default function FlexFillCenter({children, absolute = false}){
    return (
        <>
            {
                absolute &&
                <div className="absolute m-auto top-0 right-0 bottom-0 left-0">
                    <div className="flex flex-col h-full justify-center items-center">
                        {children}
                    </div>
                </div>
            }

            {
                !absolute &&
                <div className="flex flex-col h-full justify-center items-center">
                    {children}
                </div>
            }
        </>
    )
}