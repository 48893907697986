import ProgressBar from "../../../common/component/ProgressBar";
import {uploadFile, UploadParam} from "../../../common/component/file/FileUploader";
import {useDispatch, useSelector} from "react-redux";
import {clearUpload, imageUploadStart, imageUploadUpdate} from "../../../modules/imageUploadReducer";
import {useEffect, useRef, useState} from "react";
import * as uploadApi from "../../../apis/api/upload";
import {ShowAlertModal} from "../../../common/component/modal/AlertModal";
import {formatBytes} from "../../../lib/dataUtils";
import {ShowConfirmModal} from "../../../common/component/modal/ConfirmModal";

export default function ImageUploadPage() {
    const dispatch = useDispatch();
    const loginData = useSelector(state => state.userReducer.loginData);
    const videoUploadStartResult = useSelector(state => state.imageUploadReducer.startUploadResult)
    const [uploadTargetFile, setUploadFile] = useState(null);
    const [uploadPercent, setUploadPercent] = useState(null);
    const [uploadState, setUploadState] = useState(null);
    const workflowId = 2; // 사진
    const menuId = 138 // 메뉴 (기타)
    const fileRef = useRef();

    const handleFileChange = (e) => {
        const file = e.target?.files?.[0];
        setUploadFile(file);
        setUploadState(null);
        setUploadPercent(null);
        dispatch(clearUpload());
    }

    useEffect(() => {
        if(uploadPercent != null){
            updateProgress(uploadPercent, 'working');
        }
    }, [uploadPercent])

    useEffect(() => {
        const {data, error, loading} = videoUploadStartResult;
        if(error){
            ShowAlertModal({
                message: '업로드 요청 중 오류가 발생했습니다',
                onConfirm: () => {
                    dispatch(clearUpload());
                }
            });
        }
        else if(data != null) {
            const option = data.data?.data?.[0];
            if(option != null){
                const jobGroupId = option.jobGroupId;
                const jobId = option.jobId;
                const fileId = option.fileId;
                uploadFile(new UploadParam({
                    bucketName: option.bucketNm,
                    file: uploadTargetFile,
                    fileName: option.fileName,
                    path: option.filePath,
                    accessKey: option.accessKey,
                    secretKey: option.secretKey,
                    setPercentage: setUploadPercent,
                }))
                    .then(r => {
                        // 완료처리
                        dispatch(imageUploadUpdate({
                            uid: loginData.uid,
                            jobGroupId: jobGroupId,
                            jobId: jobId,
                            jobSt: 'success',
                            jobPrg: 100,
                            fileSize: uploadTargetFile.size,
                            fileName: option.fileName,
                            filePath: option.filePath,
                            workflowId: workflowId,
                            menuId: menuId,
                        }))
                        setUploadState('success');
                    })
            }
        }
    }, [videoUploadStartResult])

    const handleUpload = () => {
        if(uploadTargetFile == null){
            ShowAlertModal({
                message: '파일을 선택해 주세요'
            })
        }
        else {
            ShowConfirmModal({
                message: '업로드 하시겠습니까?',
                onConfirm: (modal) => {
                    modal.current.hideModal();
                    setUploadPercent(null);
                    setUploadState('ready');
                    dispatch(imageUploadStart({
                        uid: loginData.uid,
                        fileName: uploadTargetFile.name.normalize('NFC').replace(/ +/g, ' '),
                        fileSize: uploadTargetFile.size,
                        workflowId: workflowId,
                        menuId: menuId,
                    }))

                }
            })
        }
    }

    const updateProgress = (percent, state) => {
        const option = videoUploadStartResult.data?.data?.data?.[0];
        if(option != null){
            setUploadState(state);
            uploadApi.imageUploadUpdate({
                fileId: option.fileId,
                jobGroupId: option.jobGroupId,
                jobId: option.jobId,
                jobSt: state,
                jobPrg: percent,
                workflowId: workflowId,
                menuId: menuId,
            })
        }
    }

    const handleCancel = () => {
        if(uploadState === 'working'){
            updateProgress(uploadPercent, 'cancel');
        }
        else {
            setUploadState(null);
        }
        setUploadPercent(null);
        setUploadFile(null)
        if(fileRef.current){
            fileRef.current.value = ''
        }
    }

    return <div className="order__item">
        <div className="content_body">
            <div className="div__table__area upload_form_table">
                <div className="row">
                    <dl>
                        <dt>등록날짜</dt>
                        <dd>
                            {
                                new Date().format('yyyy-MM-dd')
                            }
                        </dd>
                    </dl>
                </div>
                <div className="row">
                    <dl>
                        <dt>사진업로드</dt>
                        <dd>
                            <div className="file__upload__wrap">
                                <div className="file__upload">
                                    <div className="upload__inp">
                                        <input type="file" ref={fileRef} id="upload" accept="image/bmp, image/jpeg, image/png" onChange={handleFileChange}/>
                                        <label htmlFor="upload">
                                            <div className="placeholder">{uploadTargetFile?.name ?? '사진을 선택해주세요.'}</div>
                                        </label>
                                    </div>
                                    <div className="btn btn-default" onClick={e => fileRef.current?.click()}>사진 선택</div>
                                </div>
                                <div className="file__progress__area">
                                    {
                                        (uploadState == null || uploadState === 'ready') &&
                                        <span className="state__text">대기중</span>
                                    }
                                    {
                                        uploadState === 'working' &&
                                        <span className="state__text">업로드 중</span>
                                    }
                                    {
                                        uploadState === 'success' &&
                                        <span className="state__text">업로드 완료</span>
                                    }
                                    {
                                        uploadState === 'fail' &&
                                        <span className="state__text">업로드 실패</span>
                                    }
                                    {
                                        uploadState === 'cancel' &&
                                        <span className="state__text">업로드 취소</span>
                                    }
                                    <ProgressBar value={uploadPercent ?? 0}/>
                                </div>
                            </div>
                        </dd>
                    </dl>
                </div>
                <div className="row">
                    {/*<dl>*/}
                    {/*    <dt>파일명</dt>*/}
                    {/*    <dd><span id="fileType">{uploadTargetFile?.name}</span></dd>*/}
                    {/*</dl>*/}
                    <dl>
                        <dt>파일용량</dt>
                        <dd>
                            <div id="fileSize">{formatBytes(uploadTargetFile?.size ?? 0)}</div>
                        </dd>
                    </dl>
                </div>
            </div>
            <div className="content_controll">
                <div></div>
                <div className="group">
                    <button className="btn btn-cancel" onClick={handleCancel} disabled={uploadTargetFile == null && videoUploadStartResult.data == null}>취소</button>
                    <button className="btn submit" onClick={handleUpload} disabled={uploadTargetFile == null || uploadState === 'cancel' ||  uploadState === 'working' ||  uploadState === 'success' ||  uploadState === 'ready'}>업로드</button>
                </div>
            </div>
        </div>
    </div>;
}