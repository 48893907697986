import {useLocation, useParams, useSearchParams} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {findMenuWithDfs, getAsFlat} from "../../lib/menu/menuUtil";
import {useEffect, useMemo, useRef, useState} from "react";
import ImageContentItem from "../../apis/dto/ImageContentItem";
import {getImageList} from "../../modules/imageReducer";
import {ImageSearchParam} from "../../apis/dto/SearchParam";
import "../..//resource/css/imageboard.scss"
import {makePath, makeThumbnailUrl, scheme} from "../../util/url";
import queryString from "query-string";
import GalleryModal2 from "../../common/component/modal/GalleryModal2";
import ImageBoardSearchBox from "./component/ImageBoardSearchBox";
import GallerySearchResult from "../../common/component/gallery/GallerySearchResult";
import _ from "lodash";
import GalleryMain from "../../common/component/gallery/GalleryMain";

export default function ImageBoard({menu}) {
    const param = useParams();
    const [currentMenu, setCurrentMenu] = useState(menu);
    const category = param.category;
    const menus = useSelector(state => state.menuReducer.menus);

    useEffect(() => {
        if (menu == null) {
            const list = getAsFlat(menus.data);
            const item = list.find(it => it.menuId == param.category);
            setCurrentMenu(item);
        } else {
            setCurrentMenu(menu)
        }
    }, [menu, param.category])

    return <Body
        menu={currentMenu}
    />
}


function Body({menu}) {

    const searchBoxRef = useRef();
    const menus = useSelector(state => state.menuReducer.menus);
    const dispatch = useDispatch();
    const loginData = useSelector(state => state.userReducer.loginData);
    const imageList = useSelector(state => state.imageReducer.imageList) // useEffect hook 11

    const [showList, setShowList] = useState([]);
    const [originList, setOriginList] = useState([]); // modal next/prev
    const [currentImg, setCurrentImg] = useState(null); // modal current

    const [searchParam, setSearchParam] = useState(null);

    const location = useLocation();
    const [locationSearchParam, setLocationSearchParam] = useSearchParams();

    const canShareYn = () => {
        return loginData?.userType === 'M';
    }

    const shareYn = (old) => {
        return canShareYn() ? 'Y': null;
    }

    const _setSetSearchParam = (p, replace = false) => {
        console.log("setSearchParam: ", p);
        setLocationSearchParam(p?.toHistory(), {replace: replace});
    }

    const copyrightAc = () => {
        return loginData?.copyright_list_ac === 1 ? null : 'Y';
    }

    const isMain = useMemo(() => {
        const query = queryString.parse(location.search);
        console.log(searchParam, )
        return searchParam == null
            ||  _.isEqual(searchParam, new ImageSearchParam())
            || _.isEqual(searchParam, ImageSearchParam.fromHistory("", menus.data, copyrightAc(), canShareYn()))
            ;
    }, [searchParam]);


    useEffect(() => {
        // if(location.search !== ""){
        const query = queryString.parse(location.search);
        const searchParam = ImageSearchParam.fromHistory(query, menus.data, copyrightAc(), canShareYn())
        console.log(`from history : `,query, canShareYn())
        setSearchParam(searchParam);
        searchBoxRef.current?.changeParam(searchParam);
        // }
    }, [location]);

    useEffect(() => {
        console.log("menu changed: ", menu, shareYn(searchParam?.shareYn));
        if (location.search === "" && menu != null) {
            let curMenu = [menu.menuId]
            if(menu.menuNm === "갤러리") curMenu = []
            _setSetSearchParam( new ImageSearchParam({
                ...searchParam,
                menu: curMenu,
                menus: menus.data,
                copyright: copyrightAc(),
                shareYn: shareYn(searchParam?.shareYn)
            }))
        }
    }, [menu]);

    // SearchParam 변경 시 요청
    useEffect(() => {
        console.log("changed searchParam: ", searchParam)
        if (searchParam != null) {
            dispatch(getImageList(searchParam));
        }
    }, [searchParam])

    // 데이터 들어오면 가공
    useEffect(() => {
        if (menus.data != null) {
            if (imageList.data != null) {
                // const menuList = getAsFlat(menus.data);
                // console.log(searchList.data);
                const list = imageList.data?.data?.hits?.hits.map(it => new ImageContentItem(it._source));
                // console.log(list);
                // console.log(searchParam);
                setOriginList( [
                    // ...(page === 0 ? [] : showList),
                    ...list ?? [],
                ])
                const newList = [
                    // ...(page === 0 ? [] : showList),
                    ...list?.map(it => ({
                        thumbSrc: makeThumbnailUrl(it.imagecontent),
                        src: makePath(scheme,it.file?.thumb_cdnUrl,it.imagecontent.thumb_path,it.file.posterFile),
                        menuNm: it.imagecontent?.menuNm ?? '',
                        title: it.imagecontent?.photo_info ?? '',
                        ...it,
                    })) ?? [],
                ];
                setShowList(newList);
            }
        }
    }, [imageList.data, menus.data])

    // if (menu == null) {
    //     return <div
    //         style={{
    //             display: 'flex',
    //             minHeight: '300px',
    //             height: '50vh',
    //             alignItems: 'center',
    //             justifyContent: 'center',
    //         }}>
    //         알 수 없는 메뉴입니다.
    //     </div>
    // }

    const showDialog = (img, index) => {
        setCurrentImg(index);
    }

    const handleParamChange = (newParam) => {
        _setSetSearchParam( new ImageSearchParam({
            ...searchParam,
            ...newParam,
            menu: [...newParam.league ?? []],
            menus: menus.data,
            page: 0,
            copyright: copyrightAc(),
            shareYn: shareYn(newParam.shareYn),
        }))
    }

    const getImageMenu = (menu) => {
        return menu.children
    }

    const getTitle = () => {
        const currentMenu = getCurrentMenu();
        const menuName = findMenuWithDfs(menu, Number(currentMenu))?.menuNm;

        return menuName ?? menu?.menuNm;
    }

    const getCurrentMenu = () => {
        const query = queryString.parse(location.search);

        if(!query.menu || query.menu instanceof Array) return null;
        return query.menu;
    }

    return (
        <div className="m-4">
            <div className="flex">
                {/*<Header/>*/}
                <div className="flex-grow">
                    <ImageBoardSearchBox
                        ref={searchBoxRef}
                        menus={menu}
                        currMenu={menu}
                        initialParam={searchParam}
                        onApply={handleParamChange}
                        // onParamChanged={handleParamChange}
                    >
                        <div className="content_head">
                            <div className="head_title">
                                <div className="head_title">
                                    {/* 2023.01.27 검색 필터와 무관하게, 진입 메뉴 표시하도록 결정 */}
                                    <div className="txt_head2">{getTitle()}</div>
                                    <div className="txt_head6 txt_sub">{imageList?.data?.data?.hits?.total?.value}</div>
                                    {/*<div className="txt_head6 txt_sub">0</div>*/}
                                </div>
                            </div>
                        </div>
                    </ImageBoardSearchBox>
                </div>
            </div>

            {
                isMain &&
                <GalleryMain/>
            }

            {
                !isMain &&
                <>
                    <GallerySearchResult
                        showList={showList}
                        showDialog={showDialog}
                        searchParam={searchParam}
                        setSearchParam={_setSetSearchParam}
                        totalCount={imageList?.data?.data?.hits?.total?.value}
                    />

                    <div className="mb-48"/>
                </>
            }
            {
                currentImg != null &&
                <GalleryModal2
                    user={loginData}
                    index={currentImg}
                    list={originList}
                    onClose={e => setCurrentImg(null)}
                />
            }
        </div>
    )
}