

const authKeyKey = 'token';
const RECENT_SEARCH_KEYWORDS = 'RECENT_SEARCH_KEYWORDS';

export function getAuthKey(){
    return localStorage.getItem(authKeyKey);
}

export function setAuthKey(token) {
    if(token){
        localStorage.setItem(authKeyKey, token);
    }
    else {
        localStorage.removeItem(authKeyKey);
    }
}

export function fetchRecentKeywords() {
    return JSON.parse(localStorage.getItem(RECENT_SEARCH_KEYWORDS));
}

export function deleteRecentKeywords(keyword) {
    const storedKeywords = JSON.parse(localStorage.getItem(RECENT_SEARCH_KEYWORDS))
    let removeIndex = storedKeywords.findIndex(storedKeyword => storedKeyword === keyword)
    storedKeywords.splice(removeIndex, 1);

    localStorage.setItem(RECENT_SEARCH_KEYWORDS, JSON.stringify(storedKeywords));
}

export function saveRecentKeyword(keywords) {
    const keyword = keywords.join(' ');

    const storedKeywords = JSON.parse(localStorage.getItem(RECENT_SEARCH_KEYWORDS)) ?? [];

    const removedDuplicateKeywords = storedKeywords.filter(storedKeyword => storedKeyword !== keyword)
    const res = [keyword].concat(removedDuplicateKeywords);

    if(res.length > 5) res.pop();

    localStorage.setItem(RECENT_SEARCH_KEYWORDS, JSON.stringify(res));
}

export function clearRecentKeyword() {
    localStorage.setItem(RECENT_SEARCH_KEYWORDS, '[]');
}