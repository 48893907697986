
import CryptoJS from 'crypto-js';

export default function useAESCrypto() {
    const key = 'aes256-kfa-2022!';
    const iv = 'aes256-kfa-2022!';

    const _secret = CryptoJS.enc.Utf8.parse(key.padEnd(32, "\0"))
    const _iv = CryptoJS.enc.Utf8.parse(iv.padEnd(16, "\0"))

    const encrypt = (text) => {
        const _text = CryptoJS.enc.Utf8.parse(text);
        const enc = CryptoJS.AES.encrypt(
            _text,
            _secret,
            {
                // iv: iv,
                iv: _iv,
                padding: CryptoJS.pad.Pkcs7,
                mode: CryptoJS.mode.CBC
            },
        );

        return enc.toString();
    }

    const decrypt = (enc) => {
        const des = CryptoJS.AES.decrypt(
            enc,
            _secret,
            {
                // iv: iv,
                iv: _iv,
                padding: CryptoJS.pad.Pkcs7,
                mode: CryptoJS.mode.CBC
            },
        );

        return des.toString(CryptoJS.enc.Utf8);
    }

    const tryDecrypt = (enc) => {
        try {
            return decrypt(enc);
        }
        catch(e){
            return enc;
        }
    }

    return {
        encrypt,
        decrypt,
        tryDecrypt,
    }

}