import PreviewGridItem from "../../common/component/previewItem/PreviewGridItem";
import {useDispatch, useSelector} from "react-redux";
import {useEffect, useState} from "react";
import {getLiveList} from "../../modules/liveReducer";
import {LiveModel} from "../../common/component/home/Live";
import moment from "moment";
import Config from "../../config/config";
import FlexFillCenter from "../../common/component/FlexFillCenter";
import NotFound from "../../common/component/NotFound";

export default function LiveBoardPage() {

    const dispatch = useDispatch();
    const loginData = useSelector(state => state.userReducer.loginData);
    const liveList = useSelector(state => state.liveReducer.liveList);
    const [showList, setShowList] = useState([]);

    const copyrightAc = () => {
        return loginData?.copyright_list_ac === 1 ? null : 'Y';
    }

    useEffect(()=> {
        dispatch(getLiveList(copyrightAc()));
        const polling = setInterval(()=> {
            dispatch(getLiveList(copyrightAc()));
        }, Config.livePollingTime);
        return () => {
            clearInterval(polling);
        }
    }, [])

    useEffect(()=> {
        if(liveList.data != null){
            const list = liveList.data?.data?.hits?.hits?.map(it => it._source).map(it => new LiveModel(it));
            // console.log('liveList', list);
            setShowList(list);
        }
    }, [liveList.data])


    return <div className="inner">
        <article className="content__wrap">
            <div className="content_head">
                <div className="head_title">
                    <div className="txt_head2">LIVE</div>
                    <div className="txt_head6 txt_sub">{showList.length}</div>
                </div>
            </div>
            <article className="game__area mt_48">
                <div className="block__list">
                    {
                        showList.length === 0 &&
                        <FlexFillCenter children={<NotFound message={'진행중인 라이브가 없습니다.'}/>}/>
                    }
                    <ul>
                        {
                            showList.map((live) =>
                                <li key={`live_preview_${live.liveId}`}>
                                    <PreviewGridItem
                                        title={live.title}
                                        category={live.category}
                                        date={live.date}
                                        state={live.state}
                                        thumbnail={live.thumbnail}
                                        categoryOnTop
                                        link={live.link}
                                    >
                                        {
                                            live.state === 'onair' &&
                                            <>
                                                <div className="live_state onAir">ON-AIR</div>
                                                <div className="live_preview_button">
                                                    <div className="dim"/>
                                                    <div className="circle">
                                                        <i className="icon gas-play"></i>
                                                    </div>
                                                </div>
                                            </>
                                        }
                                        {
                                            live.state === 'ready' && live.encStartDt != null &&
                                            <div className="circle txt_head7">
                                                <span>{moment(live.encStartDt).format("MM/DD")}</span><span>{moment(live.encStartDt).format("A HH:mm")}</span>
                                            </div>
                                        }
                                        {
                                            live.state !== 'ready' && live.state !== 'onair'  &&
                                            <div className="circle txt_head7">
                                                <span>{live.stateStr}</span>
                                            </div>
                                        }
                                    </PreviewGridItem>
                                </li>
                            )
                        }
                    </ul>
                </div>
            </article>
        </article>
    </div>
}
