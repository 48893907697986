import Footer from "../component/footer";
import {Outlet} from "react-router-dom";
import LoadingModal from "../component/modal/LoadingModal";
import ConfirmModal from "../component/modal/ConfirmModal";
import AlertModal, {ShowAlertModal} from "../component/modal/AlertModal";
import PipPlayer from "../component/PipPlayer";
import {useEffect, useRef} from "react";
import SearchModal from "../component/modal/serachModal";
import SSOErrorModal from "../component/user/sso/SSOErrorModal";
import {useSelector} from "react-redux";
import useLocation2 from "../../util/useLocation2";

export default function RootContainer() {

    const pipRef = useRef();
    const loginResult = useSelector(state => state.userReducer.login);
    const loginData = useSelector(state => state.userReducer.loginData);
    const {pathname} = useLocation2();

    useEffect(() => {
        if(loginData != null){
            // permitYN 전역 검사
            if(loginData.permitYN !== 'Y'){
                if(pathname.startsWith('/video/') || pathname.startsWith('/event/video') || pathname.startsWith('/live/')){
                    return; // 상세페이지는 자체적으로 표시하므로, 팝업 표시 안함
                }
                ShowAlertModal({
                    message: '가입 승인 대기중입니다.',
                    onConfirm: (modal) => {
                        // dispatch(clearLogin());
                    }
                })
            }
        }
    }, [loginResult, loginData]);

    return (
        <>
            <main className="main-container flex flex-col">
                <Outlet/>
                <Footer/>
                <SSOErrorModal/>
                <LoadingModal/>
                <ConfirmModal/>
                <AlertModal/>
                <SearchModal/>
                <div className="whole-search-modal-portal"></div>
                <div className="gallery-modal-portal"></div>
                <div className="resign-modal-portal"></div>
                <div className="password-change-modal-portal"></div>
                <div className="alert-modal-portal"></div>
                <PipPlayer
                    ref={pipRef}
                />
            </main>
        </>
    )
}