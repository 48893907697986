import {searchEngineApiInstance} from '../utils/index';

export const getWatermark = ()=>{
    return searchEngineApiInstance.post(
        '',
        {
            "from": 0,
            "size": 999,
        },
        {
            headers: {
                'subUrl': `/watermark/_search`,
                "Content-Type": "application/json",
            }
        }
    )
}

export const getVideoWatermark = ()=>{
    return searchEngineApiInstance.post(
        '',
        {
            "query": {
                "bool": {
                    "must": {
                        "term": {
                            "resourceType": "WM-V"
                        }
                    }
                }
            },
            "from": 0,
            "size": 1,
        },
        {
            headers: {
                'subUrl': `/watermark/_search`,
                "Content-Type": "application/json",
            }
        }
    )
}

export const getImageWatermark = ()=>{
    return searchEngineApiInstance.post(
        '',
        {
            "query": {
                "bool": {
                    "must": {
                        "term": {
                            "resourceType": "WM-I"
                        }
                    }
                }
            },
            "from": 0,
            "size": 1,
        },
        {
            headers: {
                'subUrl': `/watermark/_search`,
                "Content-Type": "application/json",
            }
        }
    )
}
