import * as videoFileApi from "../apis/api/upload";
import {call, put, select, takeEvery} from 'redux-saga/effects';
import AWS from "aws-sdk";
import {videoUploadStart, videoUploadUpdate} from "../apis/api/upload";
import {useSelector} from "react-redux";

const ACTION = {
    // 파일 업로드 일괄 ACITON
    FILE_UPLOAD: 'FILE_UPLOAD',
    CHANGE_STATE: 'CHANGE_STATE',
    CHANGE_PERCENT: 'CHANGE_PERCENT',
    CHANGE_FILE_IDX: 'CHANGE_FILE_IDX',

    CANCEL_UPLOAD: 'CANCEL_UPLOAD',
    UPDATE_UPLOAD_STATE: 'UPDATE_UPLOAD_STATE',
    CLEAR_COMPLETE_UPLOAD_STATE: 'CLEAR_COMPLETE_UPLOAD_STATE',
}

const API_ACTION = {
    CLEAR_FILE_UPLOAD: 'CLEAR_FILE_UPLOAD',
}

Object.freeze(ACTION);
Object.freeze(API_ACTION);

export const cancelUpload = () => ({type: ACTION.CANCEL_UPLOAD});
export const clearFileUpload = () => ({type: ACTION.CLEAR_COMPLETE_UPLOAD_STATE});
export const uploadFileAction = (param) => ({type: ACTION.FILE_UPLOAD, param: param});

const initialState = {
    files: null,
    percent: 0,
    state: null,
    newFileIdx: null,
    isCanceled: false,
}

function readBlob(blob) {
    return new Promise((res, rej)=> {
        const reader = new FileReader()
        reader.onerror = function(error){
            rej(error);
        }
        reader.onload = function() {
            const arrayBuffer = this.result
            res(arrayBuffer);
        }
        reader.readAsArrayBuffer(blob)
    })
}

async function upload(S3, bucket, key, uploadId, blob, index) {
    const arrayBuffer = await readBlob(blob);
    // console.log(arrayBuffer);

    let uploadPromiseResult = await S3.uploadPart({
        Body: arrayBuffer,
        Bucket: bucket,
        Key: key,
        PartNumber: index,
        UploadId: uploadId,
    }).promise()
    // console.log(index, uploadPromiseResult.ETag);
    return uploadPromiseResult.ETag;

}

/**
 *
 * @returns {AsyncGenerator<*, void, *>}
 */
function* uploadFiles() {
    window.onbeforeunload = function () {
        return "파일 업로드 중입니다. 업로드를 중단하고 나가시겠습니까?";
    }

    const workflowId = 1;
    const loginData = yield select(state => state.userReducer.loginData);

    let isCanceled = yield select(state => state.fileUploadReducer.isCanceled);
    if(isCanceled){
        return yield put({type: ACTION.CHANGE_STATE, param: 'cancel'});
    }
    // 상태 변경
    yield put({type: ACTION.CHANGE_STATE, param: 'ready'});
    yield put({type: ACTION.CHANGE_PERCENT, param: 0});
    yield put({type: ACTION.CHANGE_FILE_IDX, param: null});

    const endPoint = "https://kr.object.ncloudstorage.com";
    const region = "kr-standard";
    const chunkSize = 1024 * 1024 * 100; // 100MB

    try {
        // 파일 조회
        const files = yield select(state => state.fileUploadReducer.files);
        let fileCount = files.length;
        let isSingle = files.length === 1;
        let fileMode = isSingle ? 'single' : 'merge';
        let fileTotalSize = files.reduce((p, f) => f.size + p, 0);
        let uploadedFileSize = 0;
        console.log('uploadFiles', fileCount, fileTotalSize);

        // 서버에 업로드 항목 생성
        const startRes = yield call(videoFileApi.videoUploadStart, {
            uid: loginData.uid,
            fileMode: fileMode,
            fileName: isSingle ? files[0].name.normalize('NFC').replace(/ +/g, ' ') : undefined,
            fileList: isSingle ? undefined : files.map(it => ({
                fileItem: it.name.normalize('NFC').replace(/ +/g, ' '),
                upPrg: 0,
            })),
            workflowId: workflowId,
        });

        yield put({type: ACTION.CHANGE_STATE, param: 'working'});

        console.log(startRes);
        const {accessKey, bucketNm: bucketName, fileId: newFileIdx, filePath, secretKey, jobGroupId, jobId} = startRes?.data?.data?.[0];
        yield put({type: ACTION.CHANGE_FILE_IDX, param: newFileIdx});
        let i = 0;
        for (i = 0; i < fileCount; i++) {

            let file = files[i];
            console.log('uploadFile', i, file);
            let fileName = file.name.normalize('NFC').replace(/ +/g, ' ')
            const fileSize = file.size;
            const key = (filePath + '/' + fileName).replaceAll("//", "/").replace(/^\//, "");

            const S3 = new AWS.S3({
                endpoint: endPoint,
                region: region,
                credentials: {
                    accessKeyId: accessKey,
                    secretAccessKey: secretKey,
                }
            });

            // AWS 분할 파일 업로드 시작 요청
            const multipartCreateResult = yield call(() => S3.createMultipartUpload({
                ACL: "public-read",
                Bucket: bucketName,
                Key: key,
            }).promise())
            const uploadId = multipartCreateResult.UploadId;


            let chunkCount = 1;  // AWS API에서 1부터 시작
            let currentFileUploadSize = 0;
            const uploadPartResults = []

            for (let i = 0; i < fileSize; i += chunkSize) {
                const blob = file.slice(i, i + chunkSize);
                console.log(blob);
                let uploadResult = yield call(() => upload(S3, bucketName, key, uploadId, blob, chunkCount++));
                uploadPartResults.push(uploadResult);
                uploadedFileSize += blob.size;
                const percent = Math.floor(uploadedFileSize/fileTotalSize * 100);
                yield put({type: ACTION.CHANGE_PERCENT, param: percent});

                isCanceled = yield select(state => state.fileUploadReducer.isCanceled);
                if(isCanceled){
                    yield call(videoFileApi.videoUploadUpdate, {
                        fileId: newFileIdx,
                        jobGroupId: jobGroupId,
                        jobId: jobId,
                        jobSt: 'cancel',
                        jobPrg: percent,
                        workflowId: workflowId,
                        fileMode: fileMode,
                        fileCount: fileCount,
                        fileList: isSingle ? undefined : files.map((file, index) => ({
                            fileItem: file.name.normalize('NFC').replace(/ +/g, ' '),
                            upPrg: index < i ? 100 : Math.floor(currentFileUploadSize/file.size * 100),
                        }))
                    });
                    return yield put({type: ACTION.CHANGE_STATE, param: 'cancel'});
                }

                yield call(videoFileApi.videoUploadUpdate, {
                    fileId: newFileIdx,
                    jobGroupId: jobGroupId,
                    jobId: jobId,
                    jobSt: 'working',
                    jobPrg: percent,
                    workflowId: workflowId,
                    fileMode: fileMode,
                    fileCount: fileCount,
                    fileList: isSingle ? undefined : files.map((file, index) => ({
                        fileItem: file.name.normalize('NFC').replace(/ +/g, ' '),
                        upPrg: index < i ? 100 : Math.floor(currentFileUploadSize/file.size * 100),
                    }))
                });
            }

            isCanceled = yield select(state => state.fileUploadReducer.isCanceled);
            if(isCanceled){
                yield call(videoFileApi.videoUploadUpdate, {
                    fileId: newFileIdx,
                    jobGroupId: jobGroupId,
                    jobId: jobId,
                    jobSt: 'cancel',
                    jobPrg: 100,
                    workflowId: workflowId,
                    fileMode: fileMode,
                    fileCount: fileCount,
                    fileList: isSingle ? undefined : files.map((file, index) => ({
                        fileItem: file.name.normalize('NFC').replace(/ +/g, ' '),
                        upPrg: index < i ? 100 : Math.floor(currentFileUploadSize/file.size * 100),
                    }))
                });
                return yield put({type: ACTION.CHANGE_STATE, param: 'cancel'});
            }

            const completeUploadResponse = yield call(() => S3.completeMultipartUpload({
                    Bucket: bucketName,
                    Key: key,
                    MultipartUpload: {
                        Parts: uploadPartResults.map((it, index) => ({
                            PartNumber: index + 1,
                            ETag: it,
                        })),
                    },
                    UploadId: uploadId,
                }).promise()
            );

            console.log(completeUploadResponse);
        }

        yield put({type: ACTION.CHANGE_PERCENT, param: 100});
        yield put({type: ACTION.CHANGE_STATE, param: 'success'});
        yield call(videoFileApi.videoUploadUpdate, {
            fileId: newFileIdx,
            jobGroupId: jobGroupId,
            jobId: jobId,
            jobSt: 'success',
            jobPrg: 100,
            workflowId: workflowId,
            fileMode: fileMode,
            fileCount: fileCount,
            fileList: isSingle ? undefined : files.map((file, index) => ({
                fileItem: file.name.normalize('NFC').replace(/ +/g, ' '),
                upPrg: 100,
            }))
        });
    } catch (e) {
        console.error(e)
        yield put({type: ACTION.CHANGE_STATE, param: 'error'});

    } finally {
        window.onbeforeunload = null;
    }
}
export function* uploadSaga() {
    yield takeEvery(ACTION.FILE_UPLOAD, uploadFiles);
}

export default function fileUploadReducer(state = initialState, action) {
    switch (action.type) {

        case ACTION.CHANGE_STATE: {
            return {
                ...state,
                state: action.param,
            }
        }

        case ACTION.CHANGE_FILE_IDX: {
            return {
                ...state,
                newFileIdx: action.param,
            }
        }

        case ACTION.CHANGE_PERCENT: {
            return {
                ...state,
                percent: action.param,
            }
        }

        case ACTION.FILE_UPLOAD: {
            return {
                ...state,
                files: action.param,
            }
        }

        case ACTION.CANCEL_UPLOAD: {
            return {
                ...state,
                isCanceled: true,
            }
        }

        case ACTION.CLEAR_COMPLETE_UPLOAD_STATE: {
            return {
                ...state,
                files: null,
                state: null,
                percent: 0,
                isCanceled: false,
            }
        }

        default:
            return state;

    }
}