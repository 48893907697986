import {createPromiseThunk, handleAsyncActions, reducerUtils,} from '../lib/asyncUtils';
import * as hotHashtagApi from "../apis/api/hotHashtag";
import {textApi} from "../apis/api/hotHashtag";

const API_ACTIONS = {
    GET_HOT_HASHTAG_LIST: 'GET_HOT_HASHTAG_LIST',
    GET_HOT_HASHTAG_LIST_SUCCESS: 'GET_HOT_HASHTAG_LIST_SUCCESS',
    GET_HOT_HASHTAG_LIST_ERROR: 'GET_HOT_HASHTAG_LIST_ERROR',
};

Object.freeze(API_ACTIONS);

export const getHotHashtagList = createPromiseThunk(API_ACTIONS.GET_HOT_HASHTAG_LIST, hotHashtagApi.textApi);

const initialState = {
    hotHashtagList: reducerUtils.initial(),
};

export default function homeReducer(state = initialState, action) {
    switch (action.type) {
        case API_ACTIONS.GET_HOT_HASHTAG_LIST:
        case API_ACTIONS.GET_HOT_HASHTAG_LIST_SUCCESS:
        case API_ACTIONS.GET_HOT_HASHTAG_LIST_ERROR:
            return handleAsyncActions(API_ACTIONS.GET_HOT_HASHTAG_LIST, 'hotHashtagList')(state, action);

        default:
            return state;
    }
}