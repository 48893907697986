
export default function BlurryPoster({ children, posterImage }) {

    return (
        <div
            style={{ backgroundColor: '#fafafa', position: 'relative' }}
            className="w-full h-full"
        >
            <div
                className="countdown absolute w-full h-full live_text_border flex flex-col items-center justify-center"
                style={{ zIndex: 99 }}
            >
                {children}
            </div>
            <div
                className="absolute w-full h-full aspect-video after:backdrop-blur-[3px] after: after:absolute after:w-full after:h-full after:brightness-75"
                style={{
                    backgroundImage: `url(${posterImage})`,
                    backgroundRepeat: 'no-repeat',
                    backgroundSize: 'cover',
                    backgroundPosition: 'center',
                }}
            ></div>
        </div>
    );
};
