import React from "react";
import {max, min} from "react-table/src/aggregations";


const showingPageCount = 5;

/**
 * @callback onMoveCallback
 * @param {number} page
 */


/**
 * PageableModel로 부터 페이지 인디케이터를 생성합니다.
 * 내부 로직은 0번부터, 사용자 표출 로직은 1번 부터 페이징됩니다.
 * @param {PageableModel} model
 * @param {onMoveCallback} onMove
 */

export default function GridPageIndicator({model, onMove}) {

    if (model == null) {
        return (
            <>
            </>
        )
    }

    let showingFirst = model.page - Math.floor(showingPageCount / 2);
    if (showingFirst < 0) {
        showingFirst = 0;
    }
    let showingLast = model.page + Math.ceil(showingPageCount / 2);
    if (showingLast > model.totalPage) {
        showingLast = model.totalPage;
    }
    if (model.totalPage >= showingPageCount) {
        const showPageCount = showingLast - showingFirst;
        const lackPage = showingPageCount - showPageCount;
        if (lackPage > 0) {
            if (showingFirst === 0) {
                showingLast += lackPage;
            } else {
                showingFirst -= lackPage;
            }
        }
    }

    const pageList = Array.from({length: showingLast - showingFirst}, (_, i) => i + showingFirst + 1)


    const handleNext = () => {
        onMove(min([model.totalPage - 1, model.page + 1]))
    }

    const handlePrev = () => {
        onMove(max([0, model.page - 1]))
    }

    const handleFirst = () => {
        onMove(0)
    }

    const handleLast = () => {
        onMove(model.totalPage - 1)
    }

    const handlePage = (page) => {
        onMove(page)
    }

    return (
        <div className="pagenate__area">
            <ul>
                <li>
                    <a onClick={handleFirst}><i className="icon gas-first"/></a>
                </li>
                <li>
                    <a onClick={handlePrev}><i className="icon gas-prev"/></a>
                </li>
                {
                    pageList.map((page, index) => (
                            <li key={index} className={page === model.page + 1 ? 'active' : null}>
                                <a onClick={() => {
                                    handlePage(page - 1)
                                }}>{page}</a>
                            </li>
                        )
                    )
                }
                <li>
                    <a onClick={handleNext}><i className="icon gas-next"/></a>
                </li>
                <li>
                    <a onClick={handleLast}><i className="icon gas-last"/></a>
                </li>
            </ul>
        </div>
    );
}


/**
 * PageableModel로 부터 페이지 인디케이터를 생성합니다.
 * 내부 로직은 0번부터, 사용자 표출 로직은 1번 부터 페이징됩니다.
 * @param {SimplePageableModel} model
 * @param {onMoveCallback} onMove
 */

export function SimpleGridPageIndicator({model, onMove}) {
    // console.log(model)

    if (model == null) {
        return (
            <>
            </>
        )
    }

    let showingFirst = model._pageNo - Math.floor(showingPageCount / 2);
    if (showingFirst < 0) {
        showingFirst = 0;
    }
    let showingLast = model._pageNo + Math.ceil(showingPageCount / 2);
    if (showingLast > model.totalPage) {
        showingLast = model.totalPage;
    }
    if (model.totalPage >= showingPageCount) {
        const showPageCount = showingLast - showingFirst;
        const lackPage = showingPageCount - showPageCount;
        if (lackPage > 0) {
            if (showingFirst === 0) {
                showingLast += lackPage;
            } else {
                showingFirst -= lackPage;
            }
        }
    }

    const pageList = Array.from({length: showingLast - showingFirst}, (_, i) => i + showingFirst + 1)

    const handleNext = () => {
        onMove(min([model.totalPage - 1, model._pageNo + 1]))
    }

    const handlePrev = () => {
        onMove(max([0, model._pageNo - 1]))
    }

    const handleFirst = () => {
        onMove(0)
    }

    const handleLast = () => {
        onMove(model.totalPage - 1)
    }

    const handlePage = (page) => {
        onMove(page)
    }

    return (
        <div className="pagenate__area">
            <ul>
                <li>
                    <a onClick={handleFirst}><i className="icon gas-first"/></a>
                </li>
                <li>
                    <a onClick={handlePrev}><i className="icon gas-prev"/></a>
                </li>
                {
                    pageList.map((page, index) => (
                            <li key={index} className={page === model._pageNo ? 'active' : null}>
                                <a onClick={() => {
                                    handlePage(page - 1)
                                }}>{page}</a>
                            </li>
                        )
                    )
                }
                <li>
                    <a onClick={handleNext}><i className="icon gas-next"/></a>
                </li>
                <li>
                    <a onClick={handleLast}><i className="icon gas-last"/></a>
                </li>
            </ul>
        </div>
    );
}