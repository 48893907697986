import {handleAsyncActions, reducerUtils,} from '../lib/asyncUtils';
import * as videoApi from "../apis/api/video";
import {call, put, select, takeEvery} from 'redux-saga/effects';
import * as eventApi from "../apis/api/event";
import {EventSearchParam, ImageSearchParam, VideoSearchParam} from "../apis/dto/SearchParam";
import {getAsFlat} from "../lib/menu/menuUtil";
import {PreviewListModel} from "../page/home/component/previewList";
import EventModel from "../page/play/model/EventModel";
import PreviewItemModel from "../common/component/previewItem/model";
import {makePreviewEventTitle} from "../util/eventUtil";
import VideoContentItem from "../apis/dto/VideoContentItem";
import * as imageApi from "../apis/api/image";
import ImageContentItem from "../apis/dto/ImageContentItem";
import {createRoot} from "react-dom/client";
import {makePath, makeThumbnailUrl, scheme} from "../util/url";
import queryString from "query-string";
import GalleryModal2 from "../common/component/modal/GalleryModal2";
import VideoOffsetUtil from "../lib/videoOffsetCalcUtil";

//액션 타입 정의
const API_ACTIONS = {
    GET_TOTAL_SEARCH: 'GET_TOTAL_SEARCH',
    GET_TOTAL_SEARCH_ERROR: 'GET_TOTAL_SEARCH_ERROR',
    GET_TOTAL_SEARCH_SUCCESS: 'GET_TOTAL_SEARCH_SUCCESS',
};

const SAGA_ACTIONS = {
    GET_TOTAL_SEARCH_SAGA: 'GET_TOTAL_SEARCH_SAGA',
}

const ACTIONS = {
    CLEAR_TOTAL_SEARCH: 'CLEAR_TOTAL_SEARCH',
}

Object.freeze(API_ACTIONS);
Object.freeze(SAGA_ACTIONS);
Object.freeze(ACTIONS);

export const getTotalSearch = (keywords, hashtag) => ({type: SAGA_ACTIONS.GET_TOTAL_SEARCH_SAGA, payload: keywords, hashtag: hashtag}); //  getVideoInEventOne 실행
export const clearTotalSearch = () => ({type: ACTIONS.CLEAR_TOTAL_SEARCH});

export function* totalSearchSaga() {
    yield takeEvery(SAGA_ACTIONS.GET_TOTAL_SEARCH_SAGA, searchSaga);
}

/**
 * @returns {Generator<*, *, Generator<*, *, *>>}
 */
function* searchSaga () {
    try{
        const keywords = yield select(state => state.totalSearchReducer.keyword);
        const hashtag = yield select(state => state.totalSearchReducer.hashtag);
        const linkKeyword = keywords == null ? '' : `?keyword=${keywords.join(`&keyword=`)}`
        yield put({type: API_ACTIONS.GET_TOTAL_SEARCH});
        const menus = yield select(state => state.menuReducer.menus);
        // 카테고리별 검색 결과 취합
        const loginData = yield select(state => state.userReducer.loginData);
        const menuList = getAsFlat(menus.data);
        const targetMenus = menus.data.filter(it => it.contentType !== 'live');
        const copyright = loginData?.copyright_list_ac === 1 ? null : 'Y';
        let result = {};
        for(let i in targetMenus){
            const targetMenu = targetMenus[i];
            switch (targetMenu.contentType){
                case 'video': {
                    let param = new VideoSearchParam({
                        size: 5,
                        page: 0,
                        keyword: keywords,
                        menuIds: getAsFlat([targetMenu]).map(it => it.menuId),
                        hashtag: hashtag,
                        copyright: copyright,
                    });
                    if(targetMenu.menuId === 5){
                        param = param.toRegDtDesc();
                    }

                    const data = yield call(videoApi.searchVideo, param);

                    const list2 = data?.data?.hits?.hits?.map(it => it._source)?.map(it => new VideoContentItem(it))

                    result[targetMenu.menuNm] = new PreviewListModel({
                        title: targetMenu.menuNm,
                        menuId: targetMenu.menuId,
                        link: `${targetMenu.menuUrl}${linkKeyword}`,
                        count: data?.data?.hits?.total?.value,
                        list: list2?.map(data => {
                            if(targetMenu.matchMappingYN === 'Y'){
                                return PreviewItemModel.fromGamePlayVideoContentItem(menuList, data);
                            }
                            else {
                                return PreviewItemModel.fromVideoContentItem(menuList, data);
                            }
                        })
                    })
                    // })
                }
                    break;
                case 'image': {
                    const data = yield call(imageApi.searchImage, new ImageSearchParam({
                        size: 5,
                        page: 0,
                        keyword: keywords ?? new Set(),
                        hashtag: hashtag,
                        copyright: copyright,
                    }));
                    const list = data?.data?.hits?.hits.map(it => new ImageContentItem(it._source));
                    // const imageDataList = list?.map(it => ({
                    //     thumbSrc: makeThumbnailUrl(it.imagecontent),
                    //     src: makePath(scheme,it.file?.thumb_cdnUrl,it.imagecontent.thumb_path,it.file.posterFile),
                    //     menuNm: it.imagecontent?.menuNm ?? '',
                    //     title: it.imagecontent?.title ?? '',
                    //     ...it,
                    // }));
                    // const imageModalAction = (index) => {
                    //     const container = document.querySelector(".gallery-modal-portal");
                    //     const modalRoot = createRoot(container);
                    //     modalRoot.render(
                    //         <GalleryModal2
                    //             user={loginData}
                    //             index={index}
                    //             list={imageDataList}
                    //             onClose={() => {
                    //                 modalRoot.render('');
                    //             }}
                    //         />
                    //     )
                    // }
                    result[targetMenu.menuNm] = new PreviewListModel({
                        title: targetMenu.menuNm,
                        link: `${targetMenu.menuUrl}${linkKeyword}`,
                        count: data?.data?.hits?.total?.value,
                        orgList: list,
                        list: list?.map((it, index) => new PreviewItemModel({
                            title: it.imagecontent?.title ?? '',
                            category: it.imagecontent?.menuNm ?? '',
                            // link: `${targetMenu.menuUrl}/${it.cid}`,
                            action: () => {
                                // imageModalAction(index);
                            },
                            thumbnail: makeThumbnailUrl(it.imagecontent),
                        }))
                    });
                    // console.log(data);
                }
                    break;
                case 'event': {
                    // const video = yield select(state => state.videoReducer.videoContent)
                    // let videoOffsetUtil = VideoOffsetUtil.fromPortalVideo(video);
                    const data = yield call(eventApi.searchEvent, new EventSearchParam({
                        size: 5,
                        page: 0,
                        keyword: keywords,
                        hashtag: hashtag,
                        copyright: copyright,
                    }));


                    result[targetMenu.menuNm] = new PreviewListModel({
                        title: targetMenu.menuNm,
                        link: `${targetMenu.menuUrl}${linkKeyword}`,
                        count: data?.data?.hits?.total?.value,
                        list: data?.data?.hits?.hits?.map(it => EventModel.of2(it._source)).map((it, index) => new PreviewItemModel({
                            title: makePreviewEventTitle(it),
                            category: menuList.find(m => m.menuId === it.menuId)?.menuNm ?? it.matchName,
                            date: it.videocontent.match_date,
                            link: `/event/video/${it.eventId}?boardParam=${encodeURIComponent(queryString.stringify({page: 0, size: 5, index: index, keyword: keywords}))}`,
                            state: {eventId: it.eventId},
                            thumbnail: it.thumbnail,
                        }))
                    });
                }
                    break;
                default:
                    break;
            }
        }
        // console.log(result);
        return yield put({type: API_ACTIONS.GET_TOTAL_SEARCH_SUCCESS, payload: result});
    }
    catch(e) {
        return yield put({type: API_ACTIONS.GET_TOTAL_SEARCH_ERROR, payload: e});
    }
}

const initialState = {
    searchResult: reducerUtils.initial(),
    keyword: null,
    hashtag: null,
};

/**
 * 영상업로드 관리에서 영상 목록을 불러오고 관리하거나, 영상관리에서 영상 파일 목록을 조회할때 사용
 * @param state
 * @param action
 * @returns {*|{videoFile: {data: null, loading: boolean, error: null}}}
 */
export default function totalSearchReducer(state = initialState, action) {
    switch (action.type) {
        case API_ACTIONS.GET_TOTAL_SEARCH:
        case API_ACTIONS.GET_TOTAL_SEARCH_ERROR:
        case API_ACTIONS.GET_TOTAL_SEARCH_SUCCESS:
            return handleAsyncActions(API_ACTIONS.GET_TOTAL_SEARCH, 'searchResult')(state, action);

        case SAGA_ACTIONS.GET_TOTAL_SEARCH_SAGA: {
            return {
                ...state,
                keyword: action.payload,
                hashtag: action.hashtag,
            }
        }

        case ACTIONS.CLEAR_TOTAL_SEARCH: {
            return {
                ...state,
                searchResult: reducerUtils.initial(),
                keyword: null,
                hashtag: null,
            }
        }

        default:
            return state;
    }
}