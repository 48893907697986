import {createPortal} from "react-dom";
import {ShowAlertModal} from "./AlertModal";
import React, {useEffect, useState} from "react";
import {
    addFavoriteContent,
    clearRequestVODDownload,
    clearUpdateFavoriteContentResult,
    deleteFavoriteContent,
    requestDownload
} from "../../../modules/userReducer";
import {useDispatch, useSelector} from "react-redux";
import {getImage} from "../../../modules/imageReducer";
import classNames from "classnames";
import ImageContentItem from "../../../apis/dto/ImageContentItem";
import {Util} from "../../../lib/util";
import {useLocation, useNavigate} from "react-router-dom";


const GalleryModal2 = ({index, list, onClose, user}) => {
    const container = document.querySelector('.gallery-modal-portal');
    return createPortal
    (<GalleryModalContent
            index={index}
            list={list}
            onClose={onClose}
            user={user}
        />,
        container,
    )
};


export default GalleryModal2;

/**
 *
 * @param index
 * @param {[ImageContentItem]} list
 * @param onClose
 * @param user
 * @returns {JSX.Element|string}
 * @constructor
 */
function GalleryModalContent({index, list, onClose, user}) {

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const location = useLocation();
    const requestDownloadResult = useSelector(state => state.userReducer.requestDownload);
    const image = useSelector(state => state.imageReducer.imageContent);
    const [currentIndex, setCurrentIndex] = useState(index);
    const [currentImage, setCurrentImage] = useState(null);
    const [canDownloadOrg, setCanDownloadOrg] = useState(false);
    const [isFavorite, setIsFavorite] = useState(false);
    const addFavoriteContentResult = useSelector(state => state.userReducer.addFavoriteContentResult);
    const deleteFavoriteContentResult = useSelector(state => state.userReducer.deleteFavoriteContentResult);

    useEffect(()=> {
        if(currentIndex != null && list != null && list[currentIndex]?.cid != null){
            dispatch(getImage(list[currentIndex]?.cid));
        }
    }, [currentIndex, list]);

    useEffect(() => {
        setCanDownloadOrg(user?.download_ac == 1 || user?.download_ac == 3 || user?.download_ac == 4)
    }, [user])

    useEffect(()=> {
        if(index != null){
            setCurrentIndex(index);
        }
    }, [index]);

    useEffect(() => {
        if(image.error) {

        }
        else if(image.data) {
            setCurrentImage(new ImageContentItem(image.data?.data?._source))
            setIsFavorite(image.data?.data?.faverYN === 'Y');
        }
        return () => {
            setCurrentImage(null)
        }
    }, [image])

    useEffect(() => {
        if(requestDownloadResult.error) {
            ShowAlertModal({
                message: '다운로드 요청에 실패하였습니다',
            });
            dispatch(clearRequestVODDownload());
        }
        else if(requestDownloadResult.data) {
            if(requestDownloadResult.data?.data?.code == "00"){
                ShowAlertModal({
                    message: '다운로드 요청을 보냈습니다',
                });
            }
            else if(requestDownloadResult.data?.data?.code == "41"){
                ShowAlertModal({
                    message: '다운로드 권한이 없습니다.',
                });
            }
            else if(requestDownloadResult.data?.data?.code == "91"){
                ShowAlertModal({
                    message: '다운로드 요청 등록 실패',
                });
            }
            else if(requestDownloadResult.data?.data?.code == "92"){
                ShowAlertModal({
                    message: '다운로드 작업 요청 중 실패',
                });
            }
            else {
                ShowAlertModal({
                    message: '다운로드 요청에 실패하였습니다',
                });
            }
            dispatch(clearRequestVODDownload());
        }
    }, [requestDownloadResult])

    const handleNext = () => {
        setCurrentIndex(current => Math.min(current + 1, list.length - 1));
    }

    const handlePrev = () => {
        setCurrentIndex(current => Math.max(current - 1, 0));
    }

    const handleDownloadWeb = () => {
        if(user == null){
            ShowAlertModal({
                message: '로그인이 필요합니다.',
                onConfirm: () => {
                    navigate("/user/login", {state: {redirectUrl: location.pathname}});
                },
            });
            return;
        }

        // if(user?.gallery_detail_ac !== 1){
        //     ShowAlertModal({
        //         message: '사진 열람 권한이 없습니다',
        //     });
        //     return;
        // }

        // fetch(currentImage.poster, { method: 'GET' })
        //     .then((res) => {
        //         return res.blob();
        //     })
        //     .then((blob) => {
        //         const url = window.URL.createObjectURL(blob);
        //         const a = document.createElement('a');
        //         a.href = url;
        //         a.download = currentImage?.file?.orgFile ?? '이미지';
        //         document.body.appendChild(a);
        //         a.click();
        //         setTimeout((_) => {
        //             window.URL.revokeObjectURL(url);
        //         }, 60000);
        //         a.remove();
        //         // setOpen(false);
        //     })
        //     .catch((err) => {
        //         console.error('err: ', err);
        //     });

        
        dispatch(requestDownload({
            image_cid: currentImage.cid,
            image_fileId: currentImage.file.fileId,
            uid: user.uid,
            sourceType: 'I',
            sourceSubType: 'Web',
        }))
    }

    const handleDownloadOrg = () => {
        if(user == null){
            ShowAlertModal({
                message: '로그인이 필요합니다.',
                onConfirm: () => {
                    navigate("/user/login", {state: {redirectUrl: location.pathname}});
                },
            });
            return;
        }

        // if(!(user?.download_ac === 1 || user?.download_ac === 3 || user?.download_ac === 4)){
        //     ShowAlertModal({
        //         message: '사진 원본 다운로드 권한이 없습니다',
        //     });
        //     return;
        // }
        console.log(currentImage);
        dispatch(requestDownload({
            image_cid: currentImage.cid,
            image_fileId: currentImage.file.fileId,
            uid: user.uid,
            sourceType: 'I',
            sourceSubType: 'Org',
        }))
    }

    const toggleFavorite = () => {
        if(user == null) {
            ShowAlertModal({
                message: '로그인이 필요합니다.',
                onConfirm: () => {
                    navigate("/user/login", {state: {redirectUrl: location.pathname}});
                },
            });
            return;
        }
        if (!isFavorite) {
            dispatch(addFavoriteContent({
                userId: user.uid,
                userLevelCd: 1,
                cid: currentImage.cid,
                contenttype: "I"
            }));
        } else {
            dispatch(deleteFavoriteContent({
                userId: user.uid,
                cid: currentImage.cid,
                contenttype: "I",
            }));
        }
    }

    useEffect(() => {
        if (addFavoriteContentResult.error) {
            ShowAlertModal({
                message: "찜 목록 추가에 실패했습니다.",
            });
            dispatch(clearUpdateFavoriteContentResult())

        } else if (addFavoriteContentResult.data) {
            if (addFavoriteContentResult.data.data?.code !== '00') {
                ShowAlertModal({
                    message: "찜 목록 추가에 실패했습니다.",
                });
            } else {
                ShowAlertModal({
                    message: "찜 목록에 추가되었습니다.",
                });
                setIsFavorite(true)
            }
            dispatch(clearUpdateFavoriteContentResult())
        }
    }, [addFavoriteContentResult])


    useEffect(() => {
        if (deleteFavoriteContentResult.error) {
            ShowAlertModal({
                message: "찜 목록 제거에 실패했습니다.",
            });
            dispatch(clearUpdateFavoriteContentResult())

        } else if (deleteFavoriteContentResult.data) {
            if (deleteFavoriteContentResult.data.data?.code !== '00') {
                ShowAlertModal({
                    message: "찜 목록 제거에 실패했습니다.",
                });
            } else {
                ShowAlertModal({
                    message: "찜 목록에서 제거되었습니다.",
                });
                setIsFavorite(false)
            }
            dispatch(clearUpdateFavoriteContentResult())
        }
    }, [deleteFavoriteContentResult])


    const handleClose = () => {
        setCurrentImage(null);
        onClose?.();
    }

    // if(user == null){
    //     ShowAlertModal({
    //         message: '로그인이 필요합니다.',
    //     });
    //     return '';
    // }

    // if(user?.gallery_detail_ac !== 1){
    //     ShowAlertModal({
    //         message: '사진 열람 권한이 없습니다',
    //     });
    //     return '';
    // }

    return (
        <div className="gallery-md-wrap" style={{display: 'block'}}>
            <div className='modal-dialog'>
                <div className="dim" onClick={handleClose}></div>
                <div className="content-area" style={{maxHeight: '90vh'}}>
                    <div className="center_area" style={{backgroundColor: '#161617'}}>
                        <div className="close" onClick={handleClose}><i className="icon gas-close-big"></i></div>
                        <div
                            className="swiper galley_silde swiper-container-fade swiper-container-initialized swiper-container-horizontal"
                            style={{height: '400px', maxHeight: '50vh'}}
                        >
                            {
                                list.length > 0 && currentIndex > 0 &&
                                <button className="swiper-button-prev" onClick={handlePrev} ></button>
                            }
                            {
                                currentImage != null &&
                                <img src={currentImage.thumbnail} />
                            }
                            {
                                list.length - currentIndex > 1 &&
                                <button className="swiper-button-next" onClick={handleNext}></button>
                            }

                        </div>
                        <div className="galley__info">
                            <div className="galley__head">
                                <div>
                                    <span className="txt_sub">이미지ID</span>
                                    <span>{currentImage?.cid}</span>
                                </div>
                                <div>
                                    <div className={classNames({
                                        "btn btn-sm w-max": true,
                                        "btn-default": !isFavorite,
                                    })} onClick={toggleFavorite}>
                                        <i className="icon gas-like"></i><span>찜하기</span></div>
                                </div>
                            </div>
                            <div className="galley__details">
                                <span className="txt_sub">{currentImage?.menuNm}</span>
                                {
                                    Util.isNotEmpty(currentImage?.imagecontent?.pictureDate) &&
                                    `${currentImage?.imagecontent?.pictureDate}`
                                }
                                {
                                    Util.isNotEmpty(currentImage?.imagecontent?.pictureDate) && Util.isNotEmpty(currentImage?.imagecontent?.photo_info) &&
                                    ' : '
                                }
                                {currentImage?.imagecontent?.photo_info ?? ''}
                                <div className="hashtag">
                                    {
                                        currentImage?.hash?.map(it => <span>#{it} </span>)
                                    }
                                </div>
                            </div>
                            <div className="galley__file">
                                <span className="txt_sub">이미지 파일</span>
                                <div className={"flex"}>
                                    <div className={"flex"}>
                                        <button onClick={handleDownloadWeb} className="btn btn-default">다운(Web)</button>
                                        <div className={"mr-2"}></div>
                                        <button onClick={handleDownloadOrg} className="btn btn-default">다운(원본)</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
};