import {searchEngineApiInstance} from '../utils/index';
// import moment from "moment";
import moment from "moment-timezone";

export const searchLive = (copyright = "Y")=>{
    let query = {
        "bool": {
            "must": [
                {
                    "range": { // 포탈 게시 시간이 지금 이전 이어야함
                        "live.serviceDt": {
                            "lte": moment.tz("Asia/Seoul").format('YYYY-MM-DD HH:mm:ss'),
                        }
                    }
                },
                {
                    "range": { // 방송 종료 시간이 지금 이후여야함
                        "live.encStopDt": {
                            "gte": moment.tz("Asia/Seoul").format('YYYY-MM-DD HH:mm:ss'),
                        }
                    }
                }
            ]
        }
    };
    if(copyright != null){
        query.bool.must.push(
            {
                "term": {
                    "videocontent.copyright_yn": copyright,
                }
            }
        )
    }
    return searchEngineApiInstance.post(
        ``,
        {
            "query": query,
            "aggs": {
                "total_count": {
                    "value_count": {
                        "field": "liveId"
                    }
                }
            },
            // "from": 0,
            // "size": 1,
            "sort": [
                {
                    "live.encStartDt": "asc"
                }
            ],
        },
        {
            headers: {
                'subUrl': `/live/_search`,
                "Content-Type": "application/json",
            }
        }
    )
}

export const getLive = (id, liveYN)=>{
    return searchEngineApiInstance.post(
        ``,
        {},
        {
            headers: {
                'subUrl': `/live/_doc/${id}`,
                'Content-Type': 'application/json',
                'liveYN': liveYN ?? 'N',
            }
        }
    );
}