import {Link, useLocation} from "react-router-dom";
import {useEffect, useMemo, useState} from "react";
import classNames from "classnames";
import queryString from "query-string";

/**
 *
 * @param {Menu} menu
 * @returns {JSX.Element}
 * @constructor
 */
export default function MobileMenu({menu}){

    const [showChild, setShowChild] = useState(false);
    const { pathname, search } = useLocation();
    const query = useMemo(() => queryString.parse(search), [search]);
    const league = useMemo(() => query.league, [query]);
    const queryMenu = useMemo(() => query.menu, [query]);
    const leagueParent = useMemo(() => query.leagueParent, [query]);
    const [hasChild, setHasChild] = useState(false);

    useEffect(()=> {
        if(menu != null){
            setHasChild(menu?.children.length > 0)
        }
    }, [menu])

    const handleChildToggle = () => {
        setShowChild(!showChild);
    }

    const getTargetParam = (contentType) => {
        let targetParam = '';
        if(contentType === 'image'){
            targetParam = queryMenu;
        }
        else if(contentType === 'event') {
            if(leagueParent != null)
                targetParam = leagueParent;
            else
                targetParam = league;
        }
        else {
            targetParam = league;
        }
        return targetParam;
    }

    const getIsActive = (m) => {
        return getTargetParam(m.contentType) === m.menuUrl
            || m.memucode?.map(it => it.code3)?.includes(league)
            || m.memucode.map(it => it.code3)?.some(it => league?.includes(it))
            ;
    }

    const getIsOpen = (m) => {
        let targetParam = getTargetParam(m.contentType);
        // if (m.contentType === 'event') {
        //     console.log(
        //         'getIsActive',
        //         m,
        //         m.children.reduce((a, c) => ([...a, ...c.memucode.map(it => it.code3)]), []),
        //         league,
        //         m.children.reduce((a, c) => ([...a, ...c.memucode.map(it => it.code3)]), [])?.some(it => league?.includes(it))
        //     )
        // }
        return targetParam === m.menuUrl
            || m.children.find(it => targetParam === it.menuUrl) != null
            || m.children.reduce((a, c) => ([...a, ...c.memucode.map(it => it.code3)]), [])?.includes(league)
            || m.children.reduce((a, c) => ([...a, ...c.memucode.map(it => it.code3)]), [])?.some(it => league?.includes(it))
    }

    return (
        <>
            <div
                className={classNames({
                    "m_gnb_tit": true,
                    "drop": hasChild,
                    "active": pathname.startsWith(menu.menuUrl),
                    "txt_primary": pathname === menu.menuUrl,
                })}
            >
                <Link onClick={handleChildToggle} to={menu.menuUrl}>{menu.menuNm}</Link>
            </div>
            {
                hasChild &&
                <div
                    className={classNames({
                        'm_depth': true,
                        'active': showChild,
                    })}
                >
                    <ul>
                        {
                            menu.children.map(it => {
                                    const hasChild = it.children.length > 0;
                                    let url = '';
                                    if(it.contentType === 'image'){
                                        url = `${menu.menuUrl}/?menu=${it.menuUrl}`;
                                    }
                                    else if(it.contentType === 'event') {
                                        url = `${menu.menuUrl}?leagueParent=${it.menuUrl}`;
                                    }
                                    else {
                                        url = `${menu.menuUrl}/?league=${it.menuUrl}`;
                                    }

                                    let isOpen = getIsOpen(it);
                                    let isActive = getIsActive(it);

                                    // console.log('contentType', it, url, it, isOpen, isActive)

                                    if (hasChild) {
                                        return (
                                            <li>
                                                <div
                                                    className={
                                                        classNames({
                                                            "m_gnb_sub drop": true,
                                                            "txt_primary": isActive,
                                                            "color": isActive,
                                                            "active": isOpen,
                                                        })}
                                                >
                                                    <Link to={url}>{it.menuNm}</Link>
                                                </div>
                                                <div className="m_depth_sub drop_item">
                                                    <ul>
                                                        {
                                                            it.children.map(it => <li
                                                                    className={classNames({
                                                                        'active': getIsActive(it),
                                                                    })}
                                                                >
                                                                    {
                                                                        it.contentType === 'image' &&
                                                                        <Link to={`${menu.menuUrl}/?menu=${it.menuUrl}`}>{it.menuNm}</Link>
                                                                    }
                                                                    {
                                                                        it.contentType === 'event' &&
                                                                        <Link to={`${menu.menuUrl}?${it.memucode?.map(it => `league=${it.code3}`).join('&')}`}>{it.menuNm}</Link>
                                                                    }
                                                                    {
                                                                        (it.contentType !== 'image' && it.contentType !== 'event') &&
                                                                        <Link to={`${menu.menuUrl}/?league=${it.menuUrl}`}>{it.menuNm}</Link>
                                                                    }

                                                                </li>
                                                            )
                                                        }
                                                    </ul>
                                                </div>
                                            </li>
                                        )
                                    } else {
                                        return <li>
                                            <div
                                                className={classNames({
                                                    "m_gnb_sub": true,
                                                    "txt_primary": isActive,
                                                })}
                                            >
                                                <Link to={url}>{it.menuNm}</Link>
                                            </div>
                                        </li>
                                    }
                                }
                            )
                        }
                    </ul>
                </div>
            }
        </>
    )
}