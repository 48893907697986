/**
 *
 * @param []menuList
 */


export class Menu{
    constructor({
                    menuId,
                    menOrder,
                    depth,
                    useYN,
                    parentMenuId,
                    menuNm,
                    matchMappingYN,
                    contentType,
                    parent,
                    menuUrl,
                    children = [],
                }) {
        this.menuId = menuId;
        this.menOrder = menOrder;
        this.depth = depth;
        this.useYN = useYN;
        this.parentMenuId = parentMenuId;
        this.menuNm = menuNm;
        this.matchMappingYN = matchMappingYN;
        this.menuUrl = menuUrl;
        // this.parent = parent;
        this.contentType = contentType;
        this.children = children;
    }

}

/**
 *
 * @param {[Menu]}menuList
 * @returns [Menu]
 */
export const menuClassify = (menuList) => {
    const copy = JSON.parse(JSON.stringify(menuList));
    const menus1 = copy.filter(it => it.depth === 1);
    const menus2 = copy.filter(it => it.depth === 2);
    const menus3 = copy.filter(it => it.depth === 3);

    // 2depth 자식 할당
    menus1.forEach(parent => {
        const children = menus2.filter(it => it.parentMenuId === parent.menuId);
        parent.children = children;
        // children.forEach(it => it.parent = parent);
    })

    // 2depth 자식 할당
    menus2.forEach(parent => {
        const children = menus3.filter(it => it.parentMenuId === parent.menuId);
        parent.children = children;
        // children.forEach(it => it.parent = parent);
    })
    return menus1;
}

export const getAllChildrenIncludeSelf = (menu) => {
    const res = [];
    res.push(menu);
    menu.children?.forEach(it => {
        res.push(it)
        it.children?.forEach(it => {
            res.push(it)
        })
    });
    return res;
}

/**
 *
 * @param {Menu} menu
 * @returns {[Menu]}
 */
export const getAllChildren = (menu) => {
    const res = [];
    menu.children?.forEach(it => {
        res.push(it)
        it.children?.forEach(it => {
            res.push(it)
        })
    });
    return res;
}

/**
 *
 * @param {Menu} menu
 * @returns {[Menu]}
 */
export const getOnlyChildren = (menu) => {
    const res = [];
    menu.children?.forEach(it => {
        it.children?.forEach(it => {
            res.push(it)
        })
    });
    return res;
}


/**
 *
 * @param {[Menu]} menus
 * @returns {[Menu]}
 */
export const getAsFlat = (menus) => {
    const res = [...menus];
    menus.forEach(it => {
        res.push(...getAllChildren(it));
    })
    return res;
}




/**
 *
 * @param {number} menuId
 * @param {[Menu]} menus
 * @returns {Menu}
 */
export const findMenuById = (menuId, menus) => {
    const totalList = getAsFlat(menus);
    return totalList.find(it => it.menuId === menuId);
}

export function findMenuWithMenusAndDfs(menus, findId) {
    for (let i = 0; i < menus.length; i++) {
        const res = findMenuWithDfs(menus[i], findId);
        if(res) return res;
    }
    return null;
}

export function findMenuWithMenusAndCode(menus, code) {
    for (let i = 0; i < menus.length; i++) {
        const res = findMenuWithCode(menus[i], code);
        if(res) return res;
    }
    return null;
}

/**
 * menu 및 하위 menu에서 가장 먼저 발견한 메뉴를 반환
 * 깊이 우선 탐색
 * 찾는 값이 없으면 null 반환
 */
export function findMenuWithDfs(menu, findId) {
    if(!menu) return null;
    if(!findId) return null;
    if(findId === menu.menuId) return menu;

    if(menu.children == null) return null;

    let findMenu = null;
    for(let i = 0; i < menu.children.length; ++i) {
        findMenu = findMenuWithDfs(menu.children[i], findId);

        if(findMenu) return findMenu;
    }

    return findMenu;
}

/**
 * menu 및 하위 menu에서 가장 먼저 발견한 메뉴를 반환
 * 깊이 우선 탐색
 * 찾는 값이 없으면 null 반환
 */
export function findMenuWithCode(menu, code) {
    if(!menu) return null;
    if(!code) return null;
    if(hasCode(menu.memucode, code)) return menu;

    if(menu.children == null) return null;

    let findMenu = null;
    for(let i = 0; i < menu.children.length; ++i) {
        findMenu = findMenuWithCode(menu.children[i], code);

        if(findMenu) return findMenu;
    }

    return findMenu;

    function hasCode(menucodes, code) {
        for(let i = 0; i < menucodes.length; i++) {
            if(menucodes[i].code3 === code) return true;
        }
        return false;
    }
}