import BoardSearchBox from "../../../board/component/BoardSearchBox";
import {useEffect, useState} from "react";
import SearchParam from "../../../../apis/dto/SearchParam";
import {useDispatch, useSelector} from "react-redux";
import {deleteDownload, setDownloadImageLogSearchParam} from "../../../../modules/userReducer";
import ImageDownloadLogTable from "./ImageDownloadLogTable";
import useDownloadRequestDeleteWithModal from "./useDownloadRequestDelete";

class DownloadLogSearchParam extends SearchParam {
    constructor({
                    uid,
                    userType,
                    sourceType,
                    _pageNo,
                    _pageSize
                }) {
        super();
        this.uid = uid;
        this.userType = userType;
        this.sourceType = sourceType;
        this._pageNo = _pageNo;
        this._pageSize = _pageSize;
    }

    static toDownloadLogSearchParam({
                                        keyword, startDate, endDate, sourceType, uid, userType, _pageNo, _pageSize,
                                    }) {
        return {
            keyword: keyword,
            _fromDt: startDate,
            _toDt: endDate,
            sourceType,
            uid,
            userType,
            _pageNo,
            _pageSize
        }
    }
}

const jobStatuses = [
    {
        name: "대기중",
        value: "ready"
    },
    {
        name: "업로드중",
        value: "working"
    },
    {
        name: "업로드 중지",
        value: "stop"
    },
    {
        name: "업로드 실패",
        value: "fail"
    },
    {
        name: "업로드 성공",
        value: "success"
    }
];

export default function ImageLog() {
    const loginData = useSelector(state => state.userReducer.loginData);
    const [searchParam, setSearchParam] = useState(new DownloadLogSearchParam({
        "uid": loginData.uid,
        "userType": loginData.userType,
        "sourceType": "I",
        "_pageNo": 1,
        "_pageSize": 8,
        "keyword": "",
        "startDate": "",
        "endDate": "",
    }))
    useDownloadRequestDeleteWithModal(searchParam);

    const {data: downloadImageLogList,} = useSelector(state => state.userReducer.downloadImageLogList);

    const dispatch = useDispatch();

    const deleteResult = useSelector(state => state.userReducer.deleteDownloadResult);

    useEffect(() => {
        dispatch(setDownloadImageLogSearchParam(searchParam))
    }, [searchParam, deleteResult?.loading === false]);

    const changePage = (page) => {
        setSearchParam(s => ({
            ...s,
            _pageNo: page+1,
        }))
    }

    return <div className="order__item">
        <BoardSearchBox
            initialParam={searchParam}
            menus={jobStatuses}
            menuValueText="value"
            menuNameText="name"
            useMediaSearchFilter={false}
            onParamChanged={(param) => {
                console.log('다운로드 페이지 검색: ',DownloadLogSearchParam.toDownloadLogSearchParam(param))
            }}
        />
        <div className="content_body">
            {
                downloadImageLogList != null ?
                    <ImageDownloadLogTable
                        downloadLogList={downloadImageLogList}
                        onPageMove={changePage}
                        onDelete={(ids) => {
                            dispatch(deleteDownload(ids))
                        }}
                    /> : null
            }
        </div>
    </div>
}