import React from "react";
import FlexFillCenter from "./FlexFillCenter";


export class MessageError extends Error {
    constructor(message) {
        super(message);
        this.customMessage = message;
    }
}


class ErrorBoundary extends React.Component {
    constructor(props) {
        super(props);
        this.state = { hasError: false };
    }

    static getDerivedStateFromError(error) {
        console.log(error);
        return { hasError: true, error: error };
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if(prevProps.data !== this.props.data){
            this.setState({
                hasError: false,
            })
        }
    }

    componentDidCatch(error, errorInfo) {
        // console.log(error);
    }

    render() {
        if (this.state.hasError) {
            if(this.props.fill === true){
                return <FlexFillCenter absolute={true}>
                    <h1>{this.state.error.customMessage ?? '오류가 발생하였습니다.'}</h1>
                </FlexFillCenter>
            }
            else {
                return <h1>{this.state.error.customMessage ?? '오류가 발생하였습니다.'}</h1>
            }
        }

        return this.props.children;
    }
}

export default ErrorBoundary;