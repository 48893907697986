export const transformLevels = (levels) => {
    if (levels != null)
        return levels.map((level) => ({text: level.userLevelNm, value: level.userLevelCd}))
    else return [{text: "레벨을 불러올 수 없습니다.", value: null}]
}

export const transformTypes = (types) => {
    if (types != null)
        return types.map((type) => ({text: type.codeNm, value: type.itemCd}))
    else return [{text: "사용자 유형을 불러올 수 없습니다.", value: null}];
}

export const formatBytes = (bytes, decimals = 2) => {
    if (bytes === 0) return '0 Bytes';

    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

    const i = Math.floor(Math.log(bytes) / Math.log(k));

    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
}

export const userFileDownload = (url, fileName) => {
    if(url == null || url == ''){
        return;
    }
    const a = window.document.createElement('a');
    const id = `user_download_${new Date().format('yyyyMMddHHmmss')}`
    a.id = id
    a.href = url
    if(fileName != null){
        a.download = fileName
    }
    a.style.display = "none"
    window.document.body.appendChild(a)
    const dummy = window.document.querySelector(`#${id}`);
    dummy?.click();
    dummy?.remove();
}