export const ChangeInfoPreset = {
    _emailTitle: "KFA 아카이브 이메일 변경 인증메일 입니다.",
    _emailBody_html: "<div\n" +
        "    style=\"padding: 48px;background-color: white;border-radius: 8px;border-style: solid;border-width: 1px;border-style: solid;border-color: rgba(225, 225, 225, 1); display: inline-flex;flex-direction: row;align-items: flex-start;justify-content: flex-start;\">\n" +
        "    <table>\n" +
        "        <tr>\n" +
        "            <td>\n" +
        "                <div style=\"width: 82px;position: relative;\">\n" +
        "                  <a href=\"https://plus.kfa.or.kr\">\n" +
        "                    <img height=\"40px\"\n" +
        "                        src=\"https://plus.kfa.or.kr/image/asset/logo/logo_mark_dark.png\">\n" +
        "                  </a>\n" +
        "                </div>\n" +
        "\n" +
        "            </td>\n" +
        "        </tr>\n" +
        "        <tr>\n" +
        "            <td>\n" +
        "                <p style=\"font-size: 34px; font-weight: 700; line-height: 52px;\">KFA 아카이브<br><span\n" +
        "                        style=\"color: #E6002D;\">이메일 변경 인증메일</span>입니다.</p>\n" +
        "            </td>\n" +
        "        </tr>\n" +
        "        <tr>\n" +
        "            <td>\n" +
        "                <div style=\"width: 394px;height: 1px;background-color: rgba(18, 18, 18, 1);\"></div>\n" +
        "                <table>\n" +
        "                    <tr>\n" +
        "                        <td>\n" +
        "                            <p\n" +
        "                                style=\"font-size: 16px;line-height: 24px;color: rgba(65.87, 65.87, 65.87, 1);margin: 0px;margin-top: 40px;margin-bottom: 20px;\">\n" +
        "                                KFA 아카이브 서비스 이용 신청에 감사드립니다.\n" +
        "                                <br>아래 인증코드로 인증 완료하시면 이메일 변경이 완료됩니다.\n" +
        "                            </p>\n" +
        "                        </td>\n" +
        "                    </tr>\n" +
        "                    <tr>\n" +
        "                        <td>\n" +
        "                            <div>\n" +
        "                                <table>\n" +
        "\n" +
        "                                    <tr>\n" +
        "                                        <td>\n" +
        "                                            <p\n" +
        "                                                style=\"font-size: 16px; font-weight: 700; line-height: 24px; color: rgba(18, 18, 18, 1);\">\n" +
        "                                                인증번호\n" +
        "                                            </p>\n" +
        "                                        </td>\n" +
        "                                    </tr>\n" +
        "                                    <tr>\n" +
        "                                        <td>\n" +
        "                                            <div\n" +
        "                                                style=\"width: 394px;height: 64px;background-color: rgba(230, 0, 45, 1);border-radius: 4px;display: inline-flex;\">\n" +
        "                                                <table width=\"100%\" border=\"0\" cellspacing=\"0\" cellpadding=\"0\">\n" +
        "                                                    <tr>\n" +
        "                                                        <td align=\"center\">\n" +
        "                                                            <p\n" +
        "                                                                style=\"font-size: 18px; font-weight: 700; line-height: 26px; color: white;\">\n" +
        "                                                                %AUTH_KEY%\n" +
        "                                                            </p>\n" +
        "                                                        </td>\n" +
        "                                                    </tr>\n" +
        "                                                </table>\n" +
        "                                            </div>\n" +
        "                                        </td>\n" +
        "                                    </tr>\n" +
        "                                </table>\n" +
        "                                <p\n" +
        "                                    style=\"width: 394px;font-size: 14px;line-height: 22px;color: rgba(153, 153, 153, 1);margin: 0px;margin-top: 40px;\">\n" +
        "                                    이 이메일 주소는 KFA아카이브 이메일 변경 시 인증을 위해 회원이 직접 입력한 이메일 주소로 인증 코드를 보내는 발신전용\n" +
        "                                    메일입니다.</p>\n" +
        "                            </div>\n" +
        "                        </td>\n" +
        "                    </tr>\n" +
        "                </table>\n" +
        "            </td>\n" +
        "        </tr>\n" +
        "    </table>\n" +
        "</div>",
    _emailBody_text: "KFA 아카이브 이메일 변경 인증메일입니다.\nKFA 아카이브 서비스 이용 신청에 감사드립니다.\n아래 인증코드로 인증 완료하시면 이메일 변경이 완료됩니다.\n인증번호\n%AUTH_KEY%\n이 이메일 주소는 KFA아카이브 이메일 변경 시 인증을 위해 회원이 직접 입력한 이메일 주소로 인증 코드를 보내는 발신전용 메일입니다.'",
}