import {LabelRadioButton} from "../LabelComponents";
import {useState} from "react";
import {createPortal} from "react-dom";
import classNames from "classnames";

export default function ResignModal({onSubmit, onClose}) {
    const container = document.querySelector('.resign-modal-portal');
    return createPortal(<ResignModalContent onSubmit={onSubmit} onClose={onClose}/>, container)
}

export function ResignModalContent({onSubmit, onClose}) {
    const reasons = [
        {
            value: "service",
            text: "원하는 서비스가 없습니다.",
        },
        {
            value: "noNeed",
            text: "더이상 서비스가 필요 없습니다.",
        },
        {
            value: "privacy",
            text: "개인정보 보안이 걱정됩니다.",
        },
        {
            value: "otherService",
            text: "다른 사이트를 이용할 계획입니다.",
        },
        {
            value: "other",
            text: "기타. 다른 이유가 있습니다.",
        }
    ];

    const [checkedValue, setCheckedValue] = useState(null);
    const [password, setPassword] = useState("");

    return <div className="modal-alert-wrap">
        <div
            className={classNames({
                'modal-dialog': true,
                'overflow-y-auto': false,
            })}
        >

            <div className="dim"></div>
            <div className="modal-resign">
                <div>
                    <form>
                        <div className="txt_head2 mb_48">회원탈퇴</div>
                        <div className="txt_head5 txt_primary mb_12">현재 비밀번호</div>
                        <div className="field__inp mb_12">
                            <input
                                type="password"
                                value={password}
                                onChange={({target}) => setPassword(target.value)}
                            />
                        </div>

                        <div className="mb_32">
                            탈퇴하시는 가장 큰 이유가 무엇인가요?<br/>
                            해당 항목을 선택해주시면 서비스 개선에 더욱 노력하겠습니다.
                        </div>

                        {
                            // Todo: CSS 정리할 필요 있습니다 !!!
                        }
                        <LabelRadioButton className="signout_checkup mb_32 br-0" radioLabelData={reasons}
                                          checkedValue={checkedValue}
                                          onChange={(value) => setCheckedValue(value)}/>

                        <div className="txt_sub mb_32">
                            탈퇴 시, 회원 정보와 등록하신 모든 정보가 삭제됩니다.<br/>
                            탈퇴 신청 후 삭제까지는 1~2 영업일이 소요될 수 있습니다.<br/>
                            탈퇴할까요?
                        </div>
                    </form>
                </div>
                <div className="btn_area">
                    <div className="field__btn">
                        <div className="btn btn-cancle" onClick={onClose}>취소</div>
                        <div className="btn sibmit"
                             onClick={() => {
                                 onClose()
                                 onSubmit({password, reason: checkedValue});
                                 console.log(`안녕히계세요 여러분 저는 이 세상의 굴레와 속박을 벗어던지고 이 이유로 인해 떠납니다: ${checkedValue} 여러분도 행복하세요~`)
                             }
                             }>탈퇴하기
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

}