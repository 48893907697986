import {useEffect, useMemo, useState} from "react";
import {ShowSearchModal} from "../../../common/component/modal/serachModal";
import {getAllChildren, getAsFlat} from "../../../lib/menu/menuUtil";
import {LabelCombobox2} from "../../../common/component/LabelCombobox2";
import {makeSearchKeywords} from "../../../util/BoardSearchQueryUtil";
import {useSelector} from "react-redux";
import KFASwitch from "../../../common/component/KFASwitch";

/**
 * @param key currentMenu.menuId와 같이 route가 바뀌면서 변하는 값을 넣어 페이지가 변해도 필터가 유지되는 것을 막아줍니다.
 * @param {SearchParam} initialParam 추후 route에서 param state를 가져오는 것을 구현하기 위해 만들었습니다.
 * @param onParamChanged
 * @param {boolean} detail 상세검색 모드인지?
 * @param {Array<Menu>} menus
 * @return {JSX.Element}
 * @constructor
 */
const BoardSearchBox = ({currMenu, id, initialParam, onParamChanged, detail, menus, menuComboboxTransformer, menuValueText, menuNameText, useMediaSearchFilter = true}) => {
    const [keywordInput, setKeywordInput] = useState("");
    const [localParam, setLocalParam] = useState(initialParam);
    const [showingLeague, setShowingLeague] = useState([]);
    const loginData = useSelector(state => state.userReducer.loginData);
    const userType = useMemo(() => {
        return loginData?.userType
    }, [loginData])

    const addKeyword = (keyword) => {
        //setKeywords((prevKeywords) => new Set(keyword !== "" ? [...prevKeywords, keyword] : [...prevKeywords]));
        // 추가할 키워드가 있는 경우 localParam의 keywords에 추가 (useEffect에 의해 자동 reload)
        if (keyword !== "") {
            const keywordList = makeSearchKeywords(keyword);
            const yearList = keywordList.filter(it => it.length === 4 && !isNaN(Number(it))).map(it => Number(it));
            const yearRemovedKeywordList = keywordList.filter(it => it.length !== 4 || isNaN(Number(it)));
            setLocalParam((prevState) => ({
                ...prevState,
                keyword: new Set([...(prevState?.keyword ?? []), ...yearRemovedKeywordList]),
                year: new Set([...(prevState?.year ?? []), ...yearList])
            }))
            setKeywordInput("");
            // 그 외에는 이벤트 emit (검색버튼 동작을 위함)
        } else emitChange();
    }
    const removeKeyword = (keyword) => {
        setLocalParam((prevState) => {
            if (prevState?.keyword.delete(keyword)) {
                return ({...prevState, keyword: new Set([...(prevState?.keyword ?? [])]),})
            } else return prevState;
        })
    }

    const removeShareYn = () => {
        setLocalParam((prevState) => ({...prevState, shareYn: null}))
    }

    const removeYear = (year) => {
        setLocalParam((prevState) => {
            if (prevState?.year.delete(year)) {
                return ({...prevState, year: new Set([...(prevState?.year ?? [])]),})
            } else return prevState;
        })
    }

    const removeLeague = (league) => {
        setLocalParam((prevState) => {
            if (prevState?.league.delete(league)) {
                return ({...prevState, league: new Set([...(prevState?.league ?? [])]),})
            } else return prevState;
        })
    }

    const handleMenuChange = (value, _) => {
        console.log(value, localParam);
        setLocalParam(prevState => ({...prevState, selectValue: value}));
    }

    const emitChange = () => {
        console.log(localParam)
        const params = {
            ...initialParam, ...localParam, menuId: [localParam?.selectValue],
        };
        if (initialParam != null
            && (params?.keyword != initialParam.keyword
                || params?.year != initialParam.year
                || params?.league != initialParam.league
                || params?.shareYn != initialParam.shareYn
            )
        ) {
            console.log("emitting params: ", params)
            onParamChanged(params);
        }

        if (params?.league != null) {
            const flatMenu = getAsFlat(menus);
            setShowingLeague([...params.league].map(league => flatMenu.find(it => it.menuId === league)));
        }
    }

    useEffect(() => emitChange(), [localParam]);

    useEffect(() => {
        if (initialParam != null) {
            setLocalParam({
                ...initialParam
            })
        }
    }, [initialParam])

    useEffect(() => {
        setKeywordInput("");
        setLocalParam(null);
    }, [currMenu]);

    return <article className="search__wrap mb_64">
        <div className="search__field__group">
            <div className="item__gorup space">
                <div className="search__inp item">
                    <input
                        type="text"
                        placeholder="리스트 내 검색"
                        value={keywordInput}
                        onChange={({target}) => setKeywordInput(target.value)}
                        onKeyDown={({key, nativeEvent}) => {
                            if (!nativeEvent.isComposing && key === "Enter" && keywordInput !== '') {
                                addKeyword(keywordInput);
                            }
                        }}
                    />
                    {
                        detail
                            ? <button className="searchBtn" onClick={() => {
                                if (keywordInput !== '') {
                                    addKeyword(keywordInput);
                                }
                            }}><i className="icon gas-search"></i></button>
                            : null
                    }

                </div>
                {
                    detail ?
                        menus != null ? <div
                                className="btn btn-default btn-lg"
                                onClick={
                                    () => {
                                        let twoDepthMenus = getAsFlat(menus).filter(menu => menu.contentType === "video" && menu.depth === 2);
                                        console.log("depth 2 menu: ", twoDepthMenus)
                                        twoDepthMenus = twoDepthMenus.map(menu => ({
                                            menuNm: menu.menuNm,
                                            menuId: menu.menuId,
                                            itemList: getAllChildren(menu).map(({menuNm, menuId}) => ({menuNm, menuId}))
                                        }))
                                        console.log("Hierarchy menu: ", twoDepthMenus)
                                        ShowSearchModal(
                                            {
                                                keyword: localParam?.keyword ?? new Set(),
                                                year: localParam?.year ?? new Set(),
                                                league: localParam?.league ?? new Set(),
                                                shareYn: localParam?.shareYn == null ? new Set() : new Set([localParam?.shareYn]),
                                            },
                                            (modalRef, params) => {
                                                modalRef.current.hideModal();
                                                console.log("Finally, SearchModal returned: ", params);
                                                setLocalParam({
                                                    keyword: new Set(params.keyword),
                                                    year: new Set(params.year),
                                                    league: new Set(params.league),
                                                    shareYn: params.shareYn?.has('Y') ? 'Y' : null,
                                                })
                                            },
                                            () => '',
                                            userType,
                                            id,
                                            twoDepthMenus,
                                            false,
                                            currMenu === 'KFA경기',
                                        )
                                    }
                                }
                            >상세검색</div>
                            : null
                        : <>
                            <div className="dateTimer_inp item">
                                <input
                                    type="date"
                                    pattern="\d{4}-\d{2}-\d{2}"
                                    value={localParam?.startDate}
                                    onChange={({target}) => setLocalParam((param) => ({
                                        ...param,
                                        startDate: target.value
                                    }))}
                                />
                                <span>~</span>
                                <input
                                    type="date"
                                    pattern="\d{4}-\d{2}-\d{2}"
                                    value={localParam?.endDate}
                                    onChange={({target}) => setLocalParam((param) => ({
                                        ...param,
                                        endDate: target.value
                                    }))}
                                />
                            </div>
                            {
                                menus != null ? <LabelCombobox2
                                    comboboxData={menuComboboxTransformer != null ? menuComboboxTransformer(menus) : menus}
                                    valueText={menuValueText ?? "menuId"}
                                    nameText={menuNameText ?? "menuNm"}
                                    selectValue={localParam?.selectValue}
                                    onChange={handleMenuChange}
                                    className="typeSelect"
                                /> : null
                            }
                        </>
                }


            </div>
            {
                // addKeyword 를 호출하면 keywords 가 변경되면서 onParamChanged emit!
                !detail
                    ? <div className="btn btn-default" onClick={() => addKeyword(keywordInput)}>
                        검색
                    </div>
                    : null
            }

        </div>
        <hr className={"mt_16 mb_16"}/>
        <div className="search__tag__area">
            {/* {
                userType === 'M' && useMediaSearchFilter &&
                <div
                    className="btn btn-default btn-lg mr-4"
                    onClick={e => {
                        setLocalParam(p => ({
                            ...p,
                            shareYn: p?.shareYn !== 'Y' ? 'Y': null,
                        }))}
                    }
                >
                    미디어 공유 항목만
                    <KFASwitch
                        checked={localParam?.shareYn === 'Y'}
                        onChange={e =>{
                            setLocalParam(p => ({
                                ...p,
                                shareYn: e.target.checked ? 'Y': null,
                            }))

                        }}/>
                </div>
            } */}

            <div className="u_label" onClick={() => setLocalParam(state => ({
                keyword: new Set(),
                year: new Set(),
                league: new Set(),
            }))}>전체삭제
            </div>
            <div className="search__tag">
                {
                    [...(localParam?.keyword ?? [])].map((keyword) => <>
                            <div className="text">{keyword}</div>
                            <div className="delete mr-4" onClick={() => removeKeyword(keyword)}><i
                                className="icon gas-del-circle"></i></div>
                        </>
                    )
                }
                {
                    [...(localParam?.year ?? [])].map((year) => <>
                            <div className="text">{year}</div>
                            <div className="delete mr-4" onClick={() => removeYear(year)}><i
                                className="icon gas-del-circle"></i></div>
                        </>
                    )
                }
                {
                    [...(showingLeague ?? [])].map((league) => <>
                            <div className="text">{league?.menuNm}</div>
                            <div className="delete mr-4" onClick={() => removeLeague(league.menuId)}><i
                                className="icon gas-del-circle"></i></div>
                        </>
                    )
                }

            </div>
        </div>
    </article>
}

const ChipGroup = ({data, onRemoved}) => [...data].map((keyword) => <>
        <div className="text">{keyword}</div>
        <div className="delete mr-4" onClick={() => onRemoved(keyword)}><i
            className="icon gas-del-circle"></i></div>
    </>
)

export default BoardSearchBox