

// https://developer-talk.tistory.com/248
export const debounceFunction = (callback, delay) => {
    let timer;
    return (...args) => {
        clearTimeout(timer);
        timer = setTimeout(() => callback(...args), delay);
    };
};

export const Util =
    {
        isEmpty : function ( str )
        {
            return (str == '' || str == undefined || str == null || str == 'null' );
        },

        isNotEmpty : function(str)
        {
            return !this.isEmpty(str);
        }
    };