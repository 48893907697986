import {useEffect, useState} from "react";
import FavoriteMediaTable from "../../../../common/component/user/FavoriteMediaTable";
import {useDispatch, useSelector} from "react-redux";
import {
    clearUpdateFavoriteContentResult,
    deleteFavorite,
    setFavoriteVideoSearchParam
} from "../../../../modules/userReducer";
import {FavorSearchParam} from "./index";
import {ShowAlertModal} from "../../../../common/component/modal/AlertModal";
import MyPageSearchBox from "../../../board/component/MyPageSearchBox";


export default function FavoriteVideoBoard() {
    const dispatch = useDispatch();
    const loginData = useSelector(state => state.userReducer.loginData);
    const [searchParam, setSearchParam] = useState(new FavorSearchParam({
        userId: loginData.uid,
        contentType: 'V',
        _pageNo: 1,
        _pageSize: 8,
    }));
    const favoriteContents = useSelector(state => state.userReducer.favoriteContents);
    const deleteFavoriteContentResult = useSelector(state => state.userReducer.deleteFavoriteContentResult);

    const submit = (params) => {
        setSearchParam(new FavorSearchParam({
                ...searchParam,
                _fromDt: params.startDate,
                _toDt: params.endDate,
                _keyword: params.keyword,
            }
        ));
    }

    useEffect(() => {
        if (searchParam != null) {
            dispatch(setFavoriteVideoSearchParam(searchParam))
        }
    }, [searchParam]);

    const handleDelete = (rows) => {
        dispatch(deleteFavorite([...rows].map(it => ({
            cid: it,
            contenttype: "V",
        }))))
    }

    const handlePageMove = (event) => {
        setSearchParam(s => ({
            ...s,
            _pageNo: event + 1,
        }))
    }

    useEffect(() => {
        if (deleteFavoriteContentResult.error) {
            ShowAlertModal({
                message: "찜 목록 제거에 실패했습니다.",
            });
            dispatch(clearUpdateFavoriteContentResult())
            dispatch(setFavoriteVideoSearchParam({
                ...searchParam,
                _pageNo: 1,
            }))
        } else if (deleteFavoriteContentResult.data) {
            ShowAlertModal({
                message: "찜 목록에서 제거되었습니다.",
            });
            dispatch(clearUpdateFavoriteContentResult())
            dispatch(setFavoriteVideoSearchParam({
                ...searchParam,
                _pageNo: 1,
            }))
        }
    }, [deleteFavoriteContentResult])

    return <div className="order__item">
        <MyPageSearchBox initialParam={searchParam.toSearchBoxParam()} onSubmit={submit}/>
        {
            favoriteContents != null ? <>
                <div className="content_head mb_40 block_abled">
                    <div className="head_title">
                        <div className="txt_head2">검색 결과</div>
                        <div className="txt_sub"></div>
                    </div>

                    {
                        // Todo: 셀렉트 컴포넌트로 빼고 작동하게 하기
                    }
                    <div className="select__wrap">
                        <div className="selectBox tableSelect">
                            <div className="select">
                                <div className="select_text">
                                    <div className="option">
                                        <div className="option_text">
                                            <span>날짜 최신순</span>
                                        </div>
                                    </div>
                                </div>
                                <ul className="select_option">
                                    <li className="selected">
                                        <div className="option">
                                            <div className="option_text">
                                                <span>날짜 최신순</span>
                                            </div>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="option">
                                            <div className="option_text">
                                                <span>날짜 과거순</span>
                                            </div>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="option">
                                            <div className="option_text">
                                                <span>등록 최신순</span>
                                            </div>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="option">
                                            <div className="option_text">
                                                <span>등록 과서순</span>
                                            </div>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="content_body">
                    <FavoriteMediaTable
                        mode="video"
                        favoriteMedia={favoriteContents?.data}
                        onDelete={handleDelete}
                        onPageMove={handlePageMove}
                    />
                </div>
            </> : null
        }

    </div>
}