import {useDispatch, useSelector} from "react-redux";
import {useEffect, useState} from "react";
import {getLiveList} from "../../../modules/liveReducer";
import {Link} from "react-router-dom";
import {Swiper, SwiperSlide} from "swiper/react";
import {makeLivePoster, makeThumbnailUrl} from "../../../util/url";
import ThumbnailImage from "../ThumbnailImage";
import useWindowDimensions from "../../useWindowDimensions";
import classNames from "classnames";
import moment from "moment-timezone";
import Config from "../../../config/config";

/**
 * 홈화면에서 나오는 라이브입니다!
 * @return {JSX.Element}
 * @constructor
 */
export default function Live(){

    const dispatch = useDispatch();

    const loginData = useSelector(state => state.userReducer.loginData);
    const liveList = useSelector(state => state.liveReducer.liveList);
    const [showList, setShowList] = useState([]);

    const { width } = useWindowDimensions();
    const [isMobile, setIsMobile] = useState(false);


    const copyrightAc = () => {
        return loginData?.copyright_list_ac === 1 ? null : 'Y';
    }

    useEffect(()=> {
        setIsMobile( width <= 1024);
    }, [width]);

    // useEffect(()=> {
    //     if(option == null){
    //         return;
    //     }
    //     setOption({
    //         ...option,
    //         isMobile: isMobile,
    //     })
    // }, [isMobile])


    useEffect(()=> {
        dispatch(getLiveList(copyrightAc()));
        const polling = setInterval(()=> {
            dispatch(getLiveList(copyrightAc()));
        }, Config.livePollingTime);
        return () => {
            clearInterval(polling);
        }
    }, [])

    useEffect(()=> {
        if(liveList.data != null){
            const list = liveList.data?.data?.hits?.hits?.map(it => it._source);
            console.log('liveList', list);
            setShowList(list);
        }
    }, [liveList.data])

    // useLoadingModal([liveList])

    if(isMobile){

        return (
            <article className="onAir__slide__wrap">
                {
                    showList.length === 0 &&
                    <div
                        style={{
                            height: '50px',
                        }}>
                    </div>
                }

                <Swiper
                    className={
                        'onAir__slide card__slide'
                    }
                    slidesPerView={'auto'}
                    slidesPerGroup={1}
                    centeredSlides={false}
                >
                    {
                        showList.map(it =>
                            <SwiperSlide>
                                <HomeLivePreview
                                    data={it}
                                />
                            </SwiperSlide>
                        )
                    }
                </Swiper>
            </article>
        )
    }
    else {
        return (
            <article className="onAir__slide__wrap">
                {
                    showList.length === 0 &&
                    <div
                        style={{
                            marginBottom: '50px',
                        }}>
                    </div>
                }
                <div className="swiper onAir__slide card__slide">
                    <div className="swiper-wrapper justify-end">
                        <Swiper
                            slidesPerView={'auto'}
                            slidesPerGroup={1}
                        >
                            {
                                showList.map(it =>
                                    <SwiperSlide>
                                        <HomeLivePreview
                                            data={it}
                                        />
                                    </SwiperSlide>
                                )
                            }
                        </Swiper>
                    </div>
                </div>
            </article>
        )
    }

}

export class LiveModel {
    constructor({
                    liveId,
                    live,
                    videocontent,
                    category,
                    attach,
                    hash,
                    tokenUrl,
                } = {}) {

        const ownLive = live?.find(l => l.liveId === liveId);
        this.tokenUrl = tokenUrl;
        this.hash = hash?.map(it => it.hashNm);
        this.videocontent = videocontent?.[0];
        this.ownLive = ownLive;
        this.attach = attach;
        this.liveId = liveId;
        this.link = `/live/${liveId}`;
        this.date = ownLive?.encStartDt?.split(" ")[0];
        this.encStartDt = ownLive?.encStartDt;
        this.title = this.ownLive?.title ?? videocontent?.[0]?.title;
        if(this.videocontent?.match_type_name == null || this.videocontent?.match_type_name.trim() === ""){
            this.category = category;
        }
        else {
            this.category = this.videocontent?.match_type_name;
        }

        // testTimeZone();
        if(ownLive != null){
            if(ownLive.encStopDt == null) {
                if(ownLive.encStartDt == null){
                    this.stateStr = 'WAITING LIVE';
                    this.state = 'ready'
                }
                else {
                    this.stateStr = 'ON-AIR';
                    this.state = 'onair'
                }
            }
            else {
                const encStopDt = moment.tz(ownLive.encStopDt, "Asia/Seoul");
                const encStartDt = moment.tz(ownLive.encStartDt, "Asia/Seoul");
                const now = moment.tz("Asia/Seoul");
                if(encStopDt > now){
                    if(encStartDt > now){
                        this.stateStr = 'WAITING LIVE';
                        this.state = 'ready'
                    }
                    else {
                        this.stateStr = 'ON-AIR';
                        this.state = 'onair'
                    }
                }
                else {
                    this.stateStr = 'CLOSED';
                    this.state = 'end' // end 혹은 오류
                }
            }
        }
        else {
            this.stateStr = 'WAITING LIVE';
            this.state = 'ready'
        }

        this.thumbnail = makeThumbnailUrl(this.ownLive)
        this.poster = makeLivePoster(this.ownLive);
    }
}

function testTimeZone() {
    let timeZoneStr = 'Asia/Seoul';
    let beforeStr = '2023-10-16 00:00:00'
    let afterStr = '2023-10-16 14:00:00'
    let beforeDefault = moment(beforeStr);
    let afterDefault = moment(afterStr);
    let beforeTz = moment.tz(beforeStr, timeZoneStr);
    let afterTz = moment.tz(afterStr, timeZoneStr);
    let now = moment.tz(timeZoneStr);
    console.log(now, new Date());
    console.log(`${beforeStr} > now`, beforeDefault > now);
    console.log(`${beforeStr} < now`, beforeDefault < now);
    console.log(`${afterStr} < now`, afterDefault < now);
    console.log(`${afterStr} > now`, afterDefault > now);
    console.log(`${beforeStr} tz > now`, beforeTz > now);
    console.log(`${beforeStr} tz < now`, beforeTz < now);
    console.log(`${afterStr} tz < now`, afterTz < now);
    console.log(`${afterStr} tz > now`, afterTz > now);
}

function HomeLivePreview({data}) {
    const model = new LiveModel(data);
    return (
        <Link to={model.link}>
            <div className={classNames({
                "onAir_card": true,
                "active": model.state === 'onair',
            })}>
                <div
                    className={classNames({
                        "live_state": true,
                        "onAir": model.state === 'onair',
                    })}
                >
                    {model.stateStr ?? 'Waiting live'}
                </div>
                <div className="txt_sub">{model.category}</div>
                <div className="txt_head4 mb_32">{model.title}</div>

                <div className="home_live_thumb video_placeholder">
                    <ThumbnailImage src={model.thumbnail}/>
                </div>
            </div>
        </Link>
    )
}