const ACTIONS = {
    PUT_LOADING: 'PUT_LOADING',
    POP_LOADING: 'POP_LOADING',
}

Object.freeze(ACTIONS);

//외부에서 dispatch할 때 사용
export const putLoading = (id) => ({type: ACTIONS.PUT_LOADING, id})
export const popLoading = (id) => ({type: ACTIONS.POP_LOADING, id})

const initialState = {
    loadingStack: [],
};

/**
 * 영상업로드 관리에서 영상 목록을 불러오고 관리하거나, 영상관리에서 영상 파일 목록을 조회할때 사용
 * @param state
 * @param action
 * @returns {*|{videoFile: {data: null, loading: boolean, error: null}}}
 */
export default function loadingReducer(state = initialState, action) {
    switch (action.type) {
        case ACTIONS.PUT_LOADING:
            let stack = [...new Set([...state.loadingStack, action.id])]
            return {
                ...state,
                loadingStack: stack,
            }

        case ACTIONS.POP_LOADING:
            return {
                ...state,
                loadingStack: [...state.loadingStack.filter(it => it !== action.id)],
            }

        default:
            return state;
    }
}