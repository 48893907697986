import {useEffect, useState} from "react";

/**
 * @param {SearchParam} initialParam 추후 route에서 param state를 가져오는 것을 구현하기 위해 만들었습니다.
 * @param onSubmit 검색 버튼을 눌렀을 때 같이 실행할 함수. 함수 인자로 상태를 받습니다.
 * @return {JSX.Element}
 * @constructor
 */
const MyPageSearchBox = ({
                             initialParam,
                             onSubmit,
                         }) => {
    const [localParam, setLocalParam] = useState(initialParam);

    useEffect(() => {
        if (initialParam != null) {
            setLocalParam({
                ...initialParam
            });
        }
    }, [initialParam])

    const onChange = (field) => (e) => {
        console.log(field, e.target.value);

        setLocalParam({
            ...localParam,
            [field]: e.target.value
        });
    }

    const submit = () => {
        onSubmit(localParam);
    }

    return <article className="search__wrap mb_64">
        <div className="search__field__group">
            <div className="item__gorup space">
                <div className="search__inp item">
                    <input
                        type="text"
                        placeholder="리스트 내 검색"
                        value={localParam?.keyword ?? ''}
                        onChange={onChange('keyword')}
                        onKeyDown={({key, nativeEvent}) => {
                            if (!nativeEvent.isComposing && key === "Enter") {
                                submit();
                            }
                        }}
                    />

                    <button className="searchBtn" onClick={submit}>
                        <i className="icon gas-search"></i>
                    </button>

                </div>

                <div className="dateTimer_inp item">
                    <input
                        type="date"
                        pattern="\d{4}-\d{2}-\d{2}"
                        value={localParam?.startDate}
                        onChange={onChange('startDate')}
                    />
                    <span>~</span>
                    <input
                        type="date"
                        pattern="\d{4}-\d{2}-\d{2}"
                        value={localParam?.endDate}
                        onChange={onChange('endDate')}
                    />
                </div>
            </div>

            <div className="btn btn-default" onClick={submit}>
                검색
            </div>
        </div>
        <hr className={"mt_16 mb_16"}/>
    </article>
}

export default MyPageSearchBox;