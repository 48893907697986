import NotFound from "../../../../common/component/NotFound";
import FlexFillCenter from "../../../../common/component/FlexFillCenter";
import Config from "../../../../config/config";
import {useDispatch, useSelector} from "react-redux";
import {useEffect, useState} from "react";
import {useLoading} from "../../../../common/component/LoadingState";
import {getImageRelation} from "../../../../modules/relationReducer";
import PreviewItemModel from "../../../../common/component/previewItem/model";
import ThumbnailImage from "../../../../common/component/ThumbnailImage";
import PageIndicator from "../../../../common/component/PageIndicator";
import ImageContentItem from "../../../../apis/dto/ImageContentItem";
import GalleryModal2 from "../../../../common/component/modal/GalleryModal2";
import {makePath, makeThumbnailUrl, scheme} from "../../../../util/url";

export default function RelativeImageList({list}, ) {
    const pageSize = Config.videoSideContentPageSize;
    const dispatch = useDispatch();

    // 넘겨받은 전체 항목
    const [totalList, setTotalList] = useState();
    // 넘겨받은 항목 중 현재 페이지 부분
    const [currentRefList, setCurrentRefList] = useState([]);

    const loginData = useSelector(state => state.userReducer.loginData);

    // join 검색 한 결과
    const relationList = useSelector(state => state.relationReducer.imageRelationList);


    // 로딩 상태
    const {isLoading} = useLoading([relationList])

    const [currentImageIndex, setCurrentImageIndex] = useState(null);

    // 현재 체이지
    const [page, setPage] = useState();

    // 가공된 join 검색 결과
    const [showList, setShowList] = useState([]);
    const [originList, setOriginList] = useState([]);

    useEffect(()=> {
        setTotalList(list);
    }, [list])

    useEffect(() => {
        if(totalList != null){
            setPage(0);
        }
    }, [totalList])

    useEffect(() => {
        if(page != null && totalList != null){
            const copy = [
                ...totalList,
            ]
            const targetList = copy.splice(page * pageSize, pageSize);
            setCurrentRefList(
                targetList
            )
        }
    }, [page])

    useEffect(()=> {
        if(currentRefList.length === 0){
            setShowList([]);
        }
        else {
            dispatch(getImageRelation(currentRefList.map(it => it.refcid)))
        }
    }, [currentRefList])

    const showImageModal = (index) => {
        setCurrentImageIndex(index);
    }

    useEffect(() => {
        if(relationList.data != null) {
            const list = relationList.data?.data?.hits?.hits?.map(it => new ImageContentItem(it._source));
            setOriginList(list);
            const newList = [];
            currentRefList.forEach( it =>{
                const item = list.find(data => data.imagecontent.cid === it.refcid);
                if(item != null){
                    newList.push(PreviewItemModel.forImageRelation(item))
                }
                else {
                    newList.push(new PreviewItemModel({
                        title: '항목을 찾을 수 없습니다',
                    }))
                }
            })
            setShowList(newList);
        }
    }, [relationList])

    return (
        <article className="game__area">
            <div className="block__list size__mb">
                {
                    (showList.length ?? 0) === 0 &&
                    <FlexFillCenter children={<NotFound message={'관련 사진이 없습니다.'}/>}/>
                }
                <ul>
                    {
                        showList?.map((it, index) =>
                            <li>
                                <button onClick={e => showImageModal(index)}>
                                    <div className="video__card">
                                        <div className="video__prev">
                                            <div className="video_thum">
                                                <ThumbnailImage src={it.thumbnail}/>
                                            </div>
                                        </div>
                                        <div className="video__info">
                                            <div className="txt_bold txt_primary">{it.category}</div>
                                            <div className="txt_subject">{it.title}</div>
                                            <div className="txt_sub">{it.date}</div>
                                        </div>
                                    </div>
                                </button>
                            </li>
                        )
                    }
                </ul>

            </div>
            <PageIndicator
                pageSize={pageSize}
                page={page}
                setPage={setPage}
                itemCount={totalList?.length ?? 0}
                showPageSize={3}
            />

            {
                currentImageIndex != null &&
                <GalleryModal2
                    user={loginData}
                    list={originList?.map(it => ({
                        thumbSrc: makeThumbnailUrl(it.imagecontent),
                        src: makePath(scheme,it.file?.thumb_cdnUrl,it.imagecontent.thumb_path,it.file.posterFile),
                        menuNm: it.imagecontent?.menuNm ?? '',
                        title: it.imagecontent?.title ?? '',
                        ...it,
                    }))}
                    index={currentImageIndex}
                    onClose={()=> {
                        setCurrentImageIndex(null);
                    }}
                />

            }
        </article>
    )
}