import classNames from "classnames";
import React, {useEffect, useState} from "react";
import {makeSearchKeywords} from "../../../../../util/BoardSearchQueryUtil";


const SearchFilterBodyWrapper = ({id, visible, children}) =>
    <div
        className={classNames('filter_options', visible ? 'expanded' : 'collapsed')}
        id={id}>
        {children}
    </div>

const isEmptyString = (str) => str == null || str.trim() === "";

/**
 * @param active
 * @param item
 * @param valueText
 * @param nameText
 * @param onRemoved
 * @return {JSX.Element}
 * @constructor
 */
const SearchItem = ({idx, active, item, valueText, nameText, onRemoved}) => <div
    onClick={() => onRemoved(item[valueText])}
    className={classNames('option', {active})}
    key={`search_item_${idx}_${nameText}`}>
    <span>{item[nameText]}</span>
</div>


export default class SearchFilterBody {

    static CheckBoxYN = ({id, visible, text, filters, onFiltersChanged}) => {
        const _setChecked = (check) => {
            // setChecked(check);
            onFiltersChanged(id, check ? new Set(['Y']) : null);
        }

        return <SearchFilterBodyWrapper id={id} visible={visible}>
            <div className="checkbox box__type01">
                <input type="checkbox"
                       checked={filters === 'Y' || filters?.has('Y')}
                       onChange={e => _setChecked(e.target.checked)}/>
                <label>{text ?? ''}</label>
            </div>
        </SearchFilterBodyWrapper>
    }

    static Keyword = ({id, visible, filters, onFiltersChanged}) => {
        const [keyword, setKeywordInput] = useState("");

        const addKeyword = (keyword) => {
            const keywordList = makeSearchKeywords(keyword);
            addKeywords(keywordList);
        }

        const addKeywords = (keywords) => {
            keywords.forEach(it => filters.add(it));
            onFiltersChanged(filters);
            setKeywordInput("");
        }

        return <SearchFilterBodyWrapper id={id} visible={visible}>
            <div className="select__wrap">
                <div className="selectBox fitlerSelect">
                    <div className="select">
                        <div className="select_text">
                            <input
                                placeholder="리스트 내 검색"
                                value={keyword}
                                onChange={({target}) => setKeywordInput(target.value)}
                                onKeyDown={({key, nativeEvent}) => {
                                    if (!nativeEvent.isComposing && key === 'Enter' && !isEmptyString(keyword)) {
                                        addKeyword(keyword);
                                    }
                                }}
                            />
                        </div>
                        <div className="select_option"></div>
                    </div>
                    <div className="btn btn-default"
                         onClick={() => addKeyword(keyword)}>검색어 추가
                    </div>
                </div>
            </div>
        </SearchFilterBodyWrapper>
    }

    static Date = ({id, visible, date, onFiltersChanged}) => {
        const [param, setParam] = useState({
            startDate: date?.startDate,
            endDate: date?.endDate,
        })

        useEffect(() => {
            onFiltersChanged?.('date',param);
        }, [param])

        return <SearchFilterBodyWrapper id={id} visible={visible}>
            <div className="select__wrap flex" >
                <div className="dateTimer_inp item">
                    <input
                        type="date"
                        pattern="\d{4}-\d{2}-\d{2}"
                        value={param.startDate}
                        onChange={({target}) => {
                            setParam( p => ({
                                ...p,
                                startDate: target.value,
                            }))
                        }}
                    />
                    <span>~</span>
                    <input
                        type="date"
                        pattern="\d{4}-\d{2}-\d{2}"
                        value={param.endDate}
                        onChange={({target}) => {
                            setParam( p => ({
                                ...p,
                                endDate: target.value
                            }))
                        }}
                    />
                </div>
                <div className="mr-6" />
                <div
                    className="btn btn-default btn-lg"
                    onClick={() => {
                        setParam({
                            startDate: '',
                            endDate: '',
                        })
                    }
                    }
                >
                    초기화
                </div>
            </div>
        </SearchFilterBodyWrapper>
    }



    /**
     * @param items
     * @param nameText 표시할 이름과 쌍을 이룬 key
     * @param valueText 실제 값의 key
     * @return {JSX.Element}
     * @constructor
     */
    static ItemList = ({id, visible, items, filters, onFiltersChanged, nameText = "name", valueText = "value"}) => {
        const [menus, setMenus] = useState({singleMenus: [], groupMenus: []});
        const handleRemoved = (value) => {
            if (filters.has(value)) {
                filters.delete(value)
            } else {
                filters.add(value)
            }
            console.log(filters)
            onFiltersChanged(filters)
        }

        useEffect(() => {
            if (items != null) {
                const singleMenus = [];
                const groupMenus = [];
                items.forEach(item => {
                        if (item.itemList?.length > 0) {
                            groupMenus.push(item)
                        } else {
                            singleMenus.push(item)
                        }
                    }
                )
                setMenus(prevState => ({...prevState, singleMenus, groupMenus}));
            }
        }, [items])
        return <SearchFilterBodyWrapper id={id} visible={visible}>
            {menus.groupMenus?.map((item, idx) =>
                <>
                    {
                        item[nameText] != null ?
                            <div className="title cursor-pointer">
                                <SearchItem
                                    idx={idx}
                                    active={filters.has(item[valueText])}
                                    onRemoved={handleRemoved}
                                    nameText={nameText}
                                    valueText={valueText}
                                    item={item}
                                />
                            </div>: null
                    }
                    <div className="item__list">
                        {
                            item.itemList?.map((item, idx) =>
                                <SearchItem
                                    idx={idx}
                                    active={filters.has(item[valueText])}
                                    onRemoved={handleRemoved}
                                    nameText={nameText}
                                    valueText={valueText}
                                    item={item}
                                />
                            )
                        }
                    </div>
                </>
            )}
            <div className="title">
                <div className="item__list">
                    {
                        menus.singleMenus.map((menu, idx) =>
                            <SearchItem
                                idx={idx}
                                active={filters.has(menu[valueText])}
                                onRemoved={handleRemoved}
                                nameText={nameText}
                                valueText={valueText}
                                item={menu}
                            />
                        )
                    }
                </div>
            </div>

        </SearchFilterBodyWrapper>
    }
}
