import {searchEngineApiInstance} from '../utils/index';

export const getBannerList = ()=>{
    return searchEngineApiInstance.post(
        '',
        {
            "query": {
                "bool": {
                    "must": [
                        {
                            "bool": {
                                "must": [
                                    {
                                        "term": {
                                            "use_yn": "Y"
                                        }
                                    }
                                ]
                            }
                        }
                    ]
                }
            },
            "from": 0,
            "size": 999,
        },
        {
            headers: {
                'subUrl': `/banner/_search`,
                "Content-Type": "application/json",
            }
        }
    )
}
