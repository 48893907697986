import {Link} from "react-router-dom";
import ThumbnailImage from "../ThumbnailImage";

/**
 * @param title
 * @param category
 * @param categoryOnTop
 * @param date
 * @param thumbnail
 * @param link
 * @param state
 * @return {JSX.Element}
 * @constructor
 */
export default function PreviewListItem({
                                            id,
                                            title,
                                            category,
                                            categoryOnTop,
                                            date,
                                            year,
                                            time,
                                            home,
                                            away,
                                            thumbnail,
                                            link,
                                            state,
                                            children,
                                        }) {

    return (
        <tr>
            <td>{id}</td>
            <td>
                <div className="prev_thum">
                    <ThumbnailImage src={thumbnail}/>
                </div>
            </td>
            <td>{year}</td>
            <td><Link to={link}>{title}</Link></td>
            <td>{date?.substring(5)}</td>
            <td>{time}</td>
            <td>{home}</td>
            <td>{away}</td>
        </tr>
    )
}