import DataTable from "../grid/DataTable";
import {useEffect, useState} from "react";

/**
 * @param {SimplePageableModel} notiData
 * @param onPageMove
 * @param {function(Set<string>) : void} onDelete
 * @return {JSX.Element}
 * @constructor
 */
export default function AlertListTable({notiData, onPageMove, onDelete}) {
    const rowKeyGetter = (row) => row.itemId;
    const [selectedRows, setSelectedRows] = useState(new Set([]));
    const [rows, setRows] = useState([]);
    const columns = [
        {key: 'itemDt', name: "날짜", width: '20%'},
        {key: 'itemTitle', name: "제목", width: '20%'},
        {key: 'itemData', name: "내용", width: '60%'},
    ];

    useEffect(()=> {
        if(notiData != null){
            setRows(createRows());
        }
    }, [notiData])

    const createRows = () => {
        return notiData.content.map(data => ({
            ...data,
            itemDt: new Date(data.itemDt).format("yyyy-MM-dd HH:mm")
        }))
    }

    return <div className="order__item">
        <div className="content_head mb_40">
            <div className="head_title">
                <div className="txt_head2">알림 내역</div>
                <div className="txt_sub">{notiData._itemTotal}</div>
            </div>
        </div>
        <div className="content_body">
            <DataTable
                pageableModel={notiData}
                rows={rows}
                rowKeyGenerate={rowKeyGetter}
                selectedRows={selectedRows}
                setSelectedRows={setSelectedRows}
                onMovePage={onPageMove}
                columns={columns}
                checkBoxInFirstColumn={false}
            >
                <button
                    className={`btn btn-default ${selectedRows.size === 0 ? "inactive" : ""}`}
                    onClick={() => {
                        onDelete(selectedRows)
                    }}
                >
                    삭제
                </button>
            </DataTable>
        </div>
    </div>
}