import {makeVideoThumbnailUrl} from "../../../util/url";

export default class PreviewItemModel{
    /**
     *
     * @param {string} thumbnail
     * @param {string} title
     * @param {string} category
     * @param {string} date
     * @param {string} link
     * @param {string} state
     */
    constructor({
                    id,
                    thumbnail,
                    title,
                    titleForList,
                    category,
                    date,
                    year,
                    time,
                    home,
                    away,
                    event,
                    player,
                    link,
                    state,
                    action,
                }) {
        this.id = id;
        this.thumbnail = thumbnail;
        this.title = title;
        this.titleForList = titleForList;
        this.category = category;
        this.date = date;
        this.year = year;
        this.time = time;
        this.event = event;
        this.player = player;
        this.home = home;
        this.away = away;
        this.link = link;
        this.state = state;
        this.action = action;
    }

    /**
     *
     * @param {[Menu]}menuList
     * @param {VideoContentItem} item
     * @returns {PreviewItemModel}
     */
    static fromGamePlayVideoContentItem(menuList, item){
        return new PreviewItemModel({
            id: item.cid,
            title: item.videocontent.title,
            thumbnail: makeVideoThumbnailUrl(item),
            category: item.videocontent.ma_title,
            date: item.videocontent.match_date,
            year: item.videocontent.match_year,
            time: item.videocontent.match_time,
            home: item.videocontent.team_home,
            away: item.videocontent.team_away,
            // link: `${url}/${menuId}/${video.videocontent.cid}`,
            link: `/video/${item.videocontent.cid}`,
        })
    }

    /**
     *
     * @param {[Menu]}menuList
     * @param {VideoContentItem} item
     * @returns {PreviewItemModel}
     */
    static fromVideoContentItem(menuList, item){
        return new PreviewItemModel({
            id: item.cid,
            title: item.videocontent.title,
            thumbnail: makeVideoThumbnailUrl(item),
            category: menuList.find(m => m.menuId === item.videocontent.menuId)?.menuNm ?? item.videocontent.match_type_name,
            date: item.videocontent.match_date,
            year: item.videocontent.match_year,
            time: item.videocontent.match_time,
            home: item.videocontent.team_home,
            away: item.videocontent.team_away,
            // link: `${url}/${menuId}/${video.videocontent.cid}`,
            link: `/video/${item.videocontent.cid}`,
        })
    }

    /**
     *
     * @param {VideoContentItem} item
     * @param rel
     * @returns {PreviewItemModel}
     */
    static forVideoRelation(item, title){
        return new PreviewItemModel({
            title: title,
            thumbnail: makeVideoThumbnailUrl(item),
            category: item.videocontent.match_type_name != "" ? item.videocontent.match_type_name : item.videocontent.category,
            date: item.videocontent.match_date,
            // link: `${url}/${menuId}/${video.videocontent.cid}`,
            link: `/video/${item.videocontent.cid}`,
        })
    }
    /**
     *
     * @param {ImageContentItem} item
     * @returns {PreviewItemModel}
     */
    static forImageRelation(item){
        return new PreviewItemModel({
            title: item.imagecontent.title,
            thumbnail: item.thumbnail,
            category: item.imagecontent.menuNm,
            date: item.imagecontent.pictureDate,
            link: item.imagecontent.cid,
        })
    }
}
