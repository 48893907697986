import WhiteLogo from "../../../resource/img/asset/logo/logo_white.png";
import {useDispatch, useSelector} from "react-redux";
import {Link, useLocation, useNavigate} from "react-router-dom";
import {useEffect, useState} from "react";
import classNames from "classnames";
import useWindowDimensions from "../../useWindowDimensions";
import PCMenu from "./PCMenu";
import MobileMenu from "./MobileMenu";
import WholeSearchModal from "../modal/WholeSearchModal";
import {clearLogin, getAlertCount, loginRefresh} from "../../../modules/userReducer";

export default function Menu() {

    const location = useLocation();
    const menus = useSelector(state => state.menuReducer.menus)
    const groupMenus = useSelector(state => state.menuReducer.groupMenus);
    const mobileGroupMenus = useSelector(state => state.menuReducer.mobileGroupMenus);
    const loginData = useSelector(state => state.userReducer.loginData);
    const alertCountResult = useSelector(state => state.userReducer.alertCount);
    const [isSidebarOpen, setIsSidebarOpen] = useState(false);
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [alertCount, setAlertCount] = useState(0);

    const { width } = useWindowDimensions();
    const [isMobile, setIsMobile] = useState(false);

    const [showWholeSearchModal, setShowWholeSearchModal] = useState(false);

    useEffect(()=> {
        setIsMobile( width <= 1024);
    }, [width]);

    useEffect(() => {
        dispatch(getAlertCount());
    }, [loginData, location]) // 로그인 정보나 url 변경 시 업데이트

    useEffect(() => {
        // console.log('alertCount', alertCountResult);
        if(alertCountResult.data){
            const alert = alertCountResult.data?.data?.alert;
            // console.log('alert', alert);
            if(alert != null){
                const alertNum = Number(alert);
                if(alert > 0){
                    setAlertCount(alertNum);
                }
                else {
                    setAlertCount(0);
                }
            }
        }
    }, [alertCountResult])

    // useEffect(()=> {
    //     console.log(menus)
    // }, [menus]);


    const handleMobileMenuClick = () => {
        setIsSidebarOpen(!isSidebarOpen);
    }

    const handleLogout = () => {
        dispatch(clearLogin());
        navigate('/');
    }

    const goToHome = () => {
        dispatch(loginRefresh());
    }

    return (
        <>
            <nav
                className={classNames({
                    'mobile___gnb__wrap': true,
                    'active': isSidebarOpen && isMobile,
                })}
            >
                <div className="dim" onClick={handleMobileMenuClick}></div>
                <div className="m__gnb">
                    <div className="gnb__area">
                        {
                            menus.data?.map((it) => <MobileMenu menu={it}/>)
                            // mobileGroupMenus.data?.map((it) => <MobileMenu menu={it}/>)
                        }
                    </div>
                </div>
            </nav>
            <header className="header__">
                <div className="inner">
                    <div className="hd__gng__area">
                        <div className="mbile_buger_btn" onClick={handleMobileMenuClick}></div>
                        <Link onClick={goToHome} to="/" className="logo">
                            <img className="w-full h-[56px] object-contain" src={WhiteLogo}/>
                        </Link>
                        <div className="gnb__menu">
                            <ul>
                                {
                                    groupMenus.data?.map((it, index) => <PCMenu data={it} padding={(index - 1) * 80}/>)
                                }
                            </ul>
                        </div>
                    </div>
                    <div className="hd__tool__area">
                        <div>
                            <a
                                onClick={e => setShowWholeSearchModal(true)}
                                className="cursor-pointer"
                            >
                                <i className="icon gas-search"></i>
                            </a>
                            {
                                showWholeSearchModal && <WholeSearchModal onClose={e => setShowWholeSearchModal(false)}/>
                            }
                        </div>
                        <div>
                            <a onClick={() => {
                                if (loginData != null)
                                    navigate("/mypage/notification");
                                else navigate("/user/login", { state: { redirectUrl: '/mypage/notification' }});
                            }} className="notice__bl cursor-pointer">
                                <i className={classNames({
                                    'icon gas-notice': true,
                                    'badge': alertCount > 0,
                                })} data-count={alertCount}></i>
                            </a>
                        </div>
                        <div>
                            {
                                loginData == null && <Link to="/user/login">로그인</Link>
                            }
                            {
                                loginData != null &&
                                <Link to="/mypage">
                                    { isMobile && <i className="fa-regular fa-user"></i> }
                                    { !isMobile && `${loginData.userNm}(${loginData.userComp})`}
                                </Link>
                            }
                        </div>
                        <div>
                            {
                                loginData != null && <button onClick={handleLogout}><i
                                    className="fa-solid fa-right-from-bracket"></i></button>
                            }
                        </div>
                    </div>
                </div>
            </header>
        </>
    )
}