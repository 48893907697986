import {forwardRef, useEffect, useImperativeHandle, useMemo, useState} from "react";
import {ShowSearchModal2} from "../../../common/component/modal/serachModal";
import {getAllChildren, getAsFlat} from "../../../lib/menu/menuUtil";
import {Util} from "../../../lib/util";
import {useSelector} from "react-redux";
import {ImageSearchParam} from "../../../apis/dto/SearchParam";
import classNames from "classnames";
import {LabelCombobox2} from "../../../common/component/LabelCombobox2";
import {makeSearchKeywords} from "../../../util/BoardSearchQueryUtil";
import KFASwitch from "../../../common/component/KFASwitch";

/**
 * @param key currentMenu.menuId와 같이 route가 바뀌면서 변하는 값을 넣어 페이지가 변해도 필터가 유지되는 것을 막아줍니다.
 * @param {SearchParam} initialParam 추후 route에서 param state를 가져오는 것을 구현하기 위해 만들었습니다.
 * @param onParamChanged
 * @param {boolean} detail 상세검색 모드인지?
 * @param {Array<Menu>} menus
 * @return {JSX.Element}
 * @constructor
 */
const ImageBoardSearchBox = forwardRef(({currMenu, initialParam, onParamChanged, onApply, menus, children}, ref) => {

    const menuData = useSelector(state => state.menuReducer.menus);
    const [keywordInput, setKeywordInput] = useState("");
    const [localParam, setLocalParam] = useState(initialParam);
    const [showingLeague, setShowingLeague] = useState([]);
    const [oldSelectedMenu, setOldSelectedMenu] = useState(null);
    const [selectedMenu, setSelectedMenu] = useState(null);
    const loginData = useSelector(state => state.userReducer.loginData);
    const userType = useMemo(() => {
        return loginData?.userType
    }, [loginData])
    // console.log("initialParams: ", initialParam)

    useImperativeHandle(ref, () => ({
        changeParam(param){
            const totalMenus = getAsFlat(menuData.data);
            const menus = [...param.menu].map(menu => totalMenus.find(it => it.menuId === menu));
            setShowingLeague(menus)
            setLocalParam({
                keyword: param.keyword,
                league: new Set(menus.map(it => it.menuId)),
                startDate: param.startDate,
                endDate: param.endDate,
                sort: param.sort ?? ImageSearchParam.defaultSort,
                sortTarget: param.sortTarget ?? ImageSearchParam.defaultSortField,
            });
        },
    }))

    const addKeyword = (keyword) => {
        //setKeywords((prevKeywords) => new Set(keyword !== "" ? [...prevKeywords, keyword] : [...prevKeywords]));
        // 추가할 키워드가 있는 경우 localParam의 keywords에 추가 (useEffect에 의해 자동 reload)
        if (keyword !== "") {
            const keywordList = makeSearchKeywords(keyword);
            setLocalParam((prevState) => {
                const state = {
                    ...prevState,
                    keyword: new Set([...(prevState?.keyword ?? []), ...keywordList])
                };
                onApply?.(state);
                return state;
            })
            setKeywordInput("");
            // 그 외에는 이벤트 emit (검색버튼 동작을 위함)
        } else emitChange();
    }
    const removeKeyword = (keyword) => {
        setLocalParam((prevState) => {
            const state = ({...prevState, keyword: new Set([...(prevState.keyword ?? [])].filter(it => it !== keyword))});
            onApply?.(state);
            return state;
        })
    }

    const removeShareYn = () => {
        setLocalParam((prevState) => {
            const state = ({...prevState, shareYn: null});
            onApply?.(state);
            return state;
        })
    }

    const removeYear = (year) => {
        setLocalParam((prevState) => {
            const state = ({...prevState, year: new Set([...(prevState.year ?? [])].filter(it => it !== year))});
            onApply?.(state);
            return state;
        })
    }

    const removeDate = () => {
        setLocalParam((prevState) => {
            const state = ({...prevState, startDate: '', endDate: ''});
            onApply?.(state);
            return state;
        })
    }

    const removeLeague = (league) => {
        setLocalParam((prevState) => {
            const state = ({...prevState, league: new Set([...(prevState.league ?? [])].filter(it => it !== league))});
            // setShowingLeague([...state.league])
            onApply?.(state);
            return state;
        })
    }


    const emitChange = (p) => {
        const params = {
            ...initialParam,
            ...p,
            menuId: getAsFlat([selectedMenu ?? currMenu ?? []]).map(it => it.menuId),
        };
        if(
            initialParam != null
            && (
                localParam?.keyword != initialParam?.keyword
                || selectedMenu != oldSelectedMenu
            )
        ){
            console.log("emitting params: ", params)
            onParamChanged?.(params);
        }

        if(params?.league != null){
            const flatMenu = getAsFlat([menus]);
            setShowingLeague([...params.league].map(league => flatMenu.find(it => it.menuId === league)));
        }
    }

    const handleSortChange = (e) => {
        setLocalParam(p => {
            let param = {
                ...p,
                sortTarget: e?.split(' ')?.[0] ?? ImageSearchParam.defaultSortField,
                sort: e?.split(' ')?.[1] ?? ImageSearchParam.defaultSort,
            }
            onApply?.(param);
            return param;
        });
    }


    useEffect(() => emitChange(), [
        localParam,
        // localParam?.keyword,
        // localParam?.year,
        // localParam?.startDate, localParam?.endDate,
        // localParam?.league,
        selectedMenu,
    ]);

    useEffect(()=> {
        if(initialParam != null){
            setLocalParam(l => ({
                ...l,
                ...initialParam,
            }))
        }
    }, [initialParam]);


    useEffect(() => {
        setKeywordInput("");
        setLocalParam(null);
        setSelectedMenu(currMenu);
        setOldSelectedMenu(null);
    }, [currMenu]);

    const makeDateRangeTag = () => {
        const startDate = localParam?.startDate;
        const endDate = localParam?.endDate;
        if(Util.isEmpty(startDate) && Util.isEmpty(endDate)){
            return '';
        }
        let targetText = `${startDate ?? ''} ~ ${endDate ?? ''}`;

        return <>
            <div className="text">{targetText}</div>
            <div className="delete mr-4" onClick={() => removeDate()}><i
                className="icon gas-del-circle"></i></div>
        </>
    }

    const reset = () => {
        setLocalParam(state => ({
            keyword: new Set(),
            year: new Set(),
            startDate: '',
            endDate: '',
            league: new Set(),
            shareYn: null,
        }))
        onApply?.({
            keyword: new Set(),
            year: new Set(),
            startDate: '',
            endDate: '',
            league: new Set(),
            shareYn: null,
        })
    }

    const SearchTag = ({isLeft = false}) => {
        return <div className="search__tag__wrap">
            <div className={classNames(({
                'search__tag__area': true,
                'flex-grow': !isLeft,
            }))}
                 style={{justifyContent: isLeft ? 'unset' : 'flex-end'}}
            >
                {/* {
                    userType === 'M' &&
                    <div
                        className="btn btn-default btn-lg mr-4 !w-auto"
                        onClick={e => {
                            setLocalParam(p =>{
                                let state = {
                                    ...p,
                                    shareYn: p?.shareYn !== 'Y' ? 'Y': null,
                                };
                                onApply?.(state);
                                return state;
                            })
                        }}
                    >
                        미디어 공유 항목만
                        <KFASwitch
                            checked={localParam?.shareYn === 'Y'}
                            onChange={e =>{
                                setLocalParam(p => {
                                    let state = {
                                        ...p,
                                        shareYn: e.target.checked ? 'Y': null,
                                    }
                                    onApply?.(state);
                                    return state;
                                })
                            }}/>
                    </div>
                } */}
                <div className="u_label" onClick={reset}>
                    전체삭제
                </div>
            </div>
            <div className="search__tag" style={{justifyContent: 'flex-end'}}>
                {/*{*/}
                {/*    localParam?.shareYn === 'Y' && <>*/}
                {/*        <div className="text">미디어 공유 항목 필터</div>*/}
                {/*        <div className="delete mr-4" onClick={() => removeShareYn()}><i*/}
                {/*            className="icon gas-del-circle"></i></div>*/}
                {/*    </>*/}
                {/*}*/}
                {
                    [...(localParam?.keyword ?? [])].map((keyword) => <>
                            <div className="text">{keyword}</div>
                            <div className="delete mr-4" onClick={() => removeKeyword(keyword)}><i
                                className="icon gas-del-circle"></i></div>
                        </>
                    )
                }
                {
                    [...(localParam?.year ?? [])].map((year) => <>
                            <div className="text">{year}</div>
                            <div className="delete mr-4" onClick={() => removeYear(year)}><i
                                className="icon gas-del-circle"></i></div>
                        </>
                    )
                }
                {
                    makeDateRangeTag()
                }
                {
                    [...(showingLeague ?? [])].map((league) => <>
                            <div className="text">{league.menuNm}</div>
                            <div className="delete mr-4" onClick={() => removeLeague(league.menuId)}><i
                                className="icon gas-del-circle"></i></div>
                        </>
                    )
                }
            </div>
        </div>
    }

    const DetailButton = () => {
        return <div
            className="btn btn-default btn-lg"
            onClick={
                () => {
                    let twoDepthMenus = getAsFlat(menuData.data).filter(menu => menu.contentType === "image" && menu.depth === 2);
                    console.log("depth 2 menu: ", twoDepthMenus)
                    twoDepthMenus = twoDepthMenus.map(menu => ({
                        menuNm: menu.menuNm,
                        menuId: menu.menuId,
                        itemList: getAllChildren(menu).map(({menuNm, menuId}) => ({menuNm, menuId}))
                    }))
                    console.log("Hierarchy menu: ", twoDepthMenus)
                    ShowSearchModal2(
                        {
                            initialSearchParam: {
                                keyword: localParam?.keyword ?? new Set(),
                                date: {
                                    startDate: localParam?.startDate,
                                    endDate: localParam?.endDate,
                                },
                                league: localParam?.league ?? new Set(),
                                shareYn: localParam?.shareYn == null ? new Set() : new Set([localParam?.shareYn]),
                            },
                            onConfirm: (modalRef, params) => {
                                modalRef.current.hideModal();
                                console.log("Finally, SearchModal returned: ", params);
                                setLocalParam(s => ({
                                    ...s,
                                    keyword: params.keyword,
                                    year: params.year,
                                    league: params.league,
                                    startDate: params.date?.startDate,
                                    endDate: params.date?.endDate,
                                    shareYn: params.shareYn?.has('Y') ? 'Y' : null,
                                }))

                                onApply({
                                    keyword: params.keyword,
                                    year: params.year,
                                    league: params.league,
                                    startDate: params.date?.startDate,
                                    endDate: params.date?.endDate,
                                    shareYn: params.shareYn?.has('Y') ? 'Y' : null,
                                })
                            },
                            onCancel: () => '',
                            userType,
                            id: 'image',
                            menuSelects: twoDepthMenus,
                            isEvent: false,
                            isLeague: false,
                            isImage: true,
                        }
                    )
                }
            }
        >
            상세검색
        </div>
    }

    const SortBox = () => {
        return <LabelCombobox2
            selectValue={`${localParam?.sortTarget ?? ImageSearchParam.defaultSortField} ${localParam?.sort ?? ImageSearchParam.defaultSort}`}
            comboboxData={[
                {
                    text: '촬영 최신순',
                    id: 'imagecontent.pictureDate desc',
                },
                {
                    text: '촬영 과거순',
                    id: 'imagecontent.pictureDate asc',
                },
                {
                    text: '등록 최신순',
                    id: 'imagecontent.regDt desc',
                },
                {
                    text: '등록 과거순',
                    id: 'imagecontent.regDt asc',
                },
            ]}
            onChange={handleSortChange}
        />
    }

    const SearchInput = () => {
        console.log('rerender')
        return <div className="search__inp item">
            <input
                type="text"
                placeholder="리스트 내 검색"
                value={keywordInput}
                onChange={({target}) => setKeywordInput(target.value)}
                onKeyDown={({key, nativeEvent}) => {
                    if (!nativeEvent.isComposing && key === "Enter" && keywordInput !== '') {
                        addKeyword(keywordInput);
                    }
                }}
            />
            <button className="searchBtn" onClick={() => {
                if (keywordInput !== '') {
                    addKeyword(keywordInput);
                }
            }}><i className="icon gas-search"></i></button>
        </div>
    }

    return <>
        <div className="reactive pc flex">
            {children}
            <article className="search__wrap mb_28 flex-grow">
                <div className="search__field__group">
                    <div className="item__gorup items-center">
                        <SearchTag/>
                        {/*<SearchInput/>*/}
                        <div className="search__inp item">
                            <input
                                type="text"
                                placeholder="리스트 내 검색"
                                value={keywordInput}
                                onChange={({target}) => setKeywordInput(target.value)}
                                onKeyDown={({key, nativeEvent}) => {
                                    if (!nativeEvent.isComposing && key === "Enter" && keywordInput !== '') {
                                        addKeyword(keywordInput);
                                    }
                                }}
                            />
                            <button className="searchBtn" onClick={() => {
                                if (keywordInput !== '') {
                                    addKeyword(keywordInput);
                                }
                            }}><i className="icon gas-search"></i></button>
                        </div>
                        <DetailButton/>
                        <SortBox/>
                    </div>
                </div>
            </article>
        </div>
        <div className="reactive mobile">

            <article className="search__wrap mb_28 flex-grow">
                <div className="search__field__group">
                    <div className="item__gorup space">
                        <DetailButton/>
                        {/*input은 내부 컴포넌트 선언시 rerender문제로 매 입력마다 포커스를 잃는 문제가 있음 */}
                        <div className="search__inp item">
                            <input
                                type="text"
                                placeholder="리스트 내 검색"
                                value={keywordInput}
                                onChange={({target}) => setKeywordInput(target.value)}
                                onKeyDown={({key, nativeEvent}) => {
                                    if (!nativeEvent.isComposing && key === "Enter" && keywordInput !== '') {
                                        addKeyword(keywordInput);
                                    }
                                }}
                            />
                            <button className="searchBtn" onClick={() => {
                                if (keywordInput !== '') {
                                    addKeyword(keywordInput);
                                }
                            }}><i className="icon gas-search"></i></button>
                        </div>
                    </div>
                </div>
            </article>

            <hr className="mt_16 mb_16"/>

            <SearchTag
                isLeft={true}
            />

            <div className="flex justify-between mt_64">
                {children}
                <SortBox/>
            </div>
        </div>
    </>
})

export default ImageBoardSearchBox