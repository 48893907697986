import {createPromiseThunk, handleAsyncActions, reducerUtils,} from '../lib/asyncUtils';
import * as menuApi from "../apis/api/menu";
import SearchEngineResponse from "../apis/dto/SearchEngineResponse";
import {menuClassify} from "../lib/menu/menuUtil";
import * as watermarkApi from "../apis/api/watermark";

//액션 타입 정의
const API_ACTIONS = {
    GET_WATERMARK: 'GET_WATERMARK',
    GET_WATERMARK_SUCCESS: 'GET_WATERMARK_SUCCESS',
    GET_WATERMARK_ERROR: 'GET_WATERMARK_ERROR',
};

const ACTIONS = {
    // CLEAR_PATCH: 'CLEAR_PATCH',
    // CLEAR_DELETE: 'CLEAR_DELETE',
}

Object.freeze(API_ACTIONS);
Object.freeze(ACTIONS);

//외부에서 dispatch할 때 사용
export const getWatermark = createPromiseThunk(API_ACTIONS.GET_WATERMARK, watermarkApi.getWatermark);

const initialState = {
    watermarkResult: reducerUtils.initial(),
    videoWatermark: null,
    imageWatermark: null,
};

/**
 * 영상업로드 관리에서 영상 목록을 불러오고 관리하거나, 영상관리에서 영상 파일 목록을 조회할때 사용
 * @param state
 * @param action
 * @returns {*|{videoFile: {data: null, loading: boolean, error: null}}}
 */
export default function watermarkReducer(state = initialState, action) {
    switch (action.type) {
        case API_ACTIONS.GET_WATERMARK:
        case API_ACTIONS.GET_WATERMARK_ERROR:
            return handleAsyncActions(API_ACTIONS.GET_WATERMARK, 'watermarkResult')(state, action);


        case API_ACTIONS.GET_WATERMARK_SUCCESS:
            console.log(action.payload);
            // const data = new SearchEngineResponse(action.payload.data).content;
            return {
                ...state,
                watermarkResult: reducerUtils.success(action.payload),
                videoWatermark: action.payload?.data?.hits?.hits?.find(it => it?._source?.resourceType == "WM-V"),
                imageWatermark: action.payload?.data?.hits?.hits?.find(it => it?._source?.resourceType == "WM-I"),
            }

        default:
            return state;
    }
}