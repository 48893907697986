import {searchEngineApiInstance} from '../utils/index';

export const searchImage = ({
                                query = { "match_all": {} } ,
                                size,
                                from = 0,
                                sort,
                                sortTarget,
                            } = {})=>{
    return searchEngineApiInstance.post(
        ``,
        {
            "track_total_hits": true,
            "query": query,
            "from": from,
            "size": size,
            "aggs": {
                "total_count": {
                    "value_count": {
                        "field": "cid"
                    }
                }
            },
            "sort": [
                {
                    [sortTarget]: sort,
                },
                {
                    "imagecontent.cid": "desc",
                },
            ]
        },
        {
            headers: {
                'subUrl': `/image/_search`,
                "Content-Type": "application/json",
            }
        }
    );
}

export const getImage = (cid)=>{
    return searchEngineApiInstance.post(
        ``,
        {},
        {
            headers: {
                'subUrl': `/image/_doc/${cid}`,
                "Content-Type": "application/json",
            }
        }
    );
}


export const getImageBlob = (url)=>{
    return searchEngineApiInstance.get(
        url,
        {
            headers: {
                // Accept: 'blob',
                // responseType: 'blob',
                // 'sec-fetch-dest': 'image',
                // 'sec-fetch-mode': 'no-cors',
                // 'sec-fetch-site': 'cross-site',
            },
            withCredentials: true,
            crossdomain: true,
        },
    );
}

/**
 * 복수의 항목을 검색. 관련사진 조회용
 * @param {[number]} cids
 * @returns {Promise<AxiosResponse<any>>}
 */
export const getImages = (cids)=>{
    return searchEngineApiInstance.post(
        ``,
        {
            "query": {
                "bool": {
                    "filter": {
                        "terms": {
                            "_id": cids,
                        }
                    }
                }
            },
            "from": 0,
            "size": cids.length,
        },
        {
            headers: {
                'subUrl': `/image/_search`,
                "Content-Type": "application/json",
            }
        }
    );
}