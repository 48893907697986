import {makeAttachUrl} from "../../util/url";
import ErrorBoundary from "./ErrorBoundary";

export default function Attachments({list}) {
    return (
        <article className="edit_item mb_64">
            <div className="content_head mb_32">
                <div className="head_title">
                    <div className="txt_head4">첨부파일 정보</div>
                </div>
            </div>

            <ErrorBoundary
                fill={false}
            >
                <List
                    list={list}/>
            </ErrorBoundary>
        </article>
    )
}

function List({list}){
    return (
        <div className="div__table__area attach_list">
            {
                (list?.length ?? 0) === 0 &&
                <div className="row">
                    <dl>
                        <dd>
                            <div className="fl_top_center">
                                <span className="txt_sub">첨부파일이 없습니다.</span>
                            </div>
                        </dd>
                    </dl>
                </div>
            }
            {
                list?.map(it =>
                    <div className="row">
                        <dl>
                            <dd>
                                <div className="fl_top_center">
                                    <span>{it.file_name}</span>
                                    <a href={makeAttachUrl(it)} target={"_blank"} className="btn btn-default btn-tiny ml_20" download={true}>다운로드</a>
                                </div>
                            </dd>
                        </dl>
                    </div>
                )
            }
        </div>
    )
}