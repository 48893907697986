import {searchEngineApiInstance} from '../utils/index';
import {EventSearchParam} from "../dto/SearchParam";


export const searchEvent = ({query, size, from})=>{
    return searchEngineApiInstance.post(
        ``,
        {
            "query": query,
            "aggs": {
                "total_count": {
                    "value_count": {
                        "field": "eventId"
                    }
                }
            },
            "from": from,
            "size": size,
            // time_misec를 분 3자리로 만들어서 정렬
            "sort": [
                {
                    "videocontent.match_date": "desc"
                },
                {
                    "event.regDt": "desc"
                },
                {
                    "event.eventId": "desc"
                }
            ],
            'track_total_hits': true,
        },
        {
            headers: {
                'subUrl': `/event/_search`,
                "Content-Type": "application/json",
            }
        }
    );
}

export const getEventList = (cid)=>{
    return searchEngineApiInstance.post(
        ``,
        {
            "query": {
                "bool": {
                    "must": [
                        {
                            "bool": {
                                "must": [
                                    {
                                        "term": {
                                            "event.cid": cid
                                        }
                                    }
                                ]
                            }
                        }
                    ]
                }
            },
            "aggs": {
                "total_count": {
                    "value_count": {
                        "field": "eventId"
                    }
                }
            },
            "from": 0,
            "size": 9999,
            'track_total_hits': true,
            // time_misec를 분 3자리로 만들어서 정렬
            "sort": {
                "_script": {
                    "script": "return String.format('%6s',new def[] {doc['event.time_misec'].value}).replace(' ', '0')",
                    "type": "string"
                }
            }
        },
        {
            headers: {
                'subUrl': `/event/_search`,
                "Content-Type": "application/json",
            }
        }
    );
}

export const getEventOne = (cid)=>{
    return searchEngineApiInstance.post(
        ``,
        {},
        {
            headers: {
                'subUrl': `/event/_doc/${cid}`,
                "Content-Type": "application/json",
            }
        }
    );
}

export const getRecentEventList = (copyright)=>{
    let param = new EventSearchParam({
        copyright
    })
    return searchEngineApiInstance.post(
        ``,
        {
            "query": param.query,
            "from": 0,
            "size": 10,
            "sort": [
                {
                    "videocontent.match_date": "desc"
                },
                {
                    "event.regDt": "desc"
                },
                {
                    "event.eventId": "desc"
                }
            ]
        },
        {
            headers: {
                'subUrl': `/event/_search`,
                "Content-Type": "application/json",
            }
        }
    );
}

export const getEventCodes = async () => {
    const res = await searchEngineApiInstance.post(
        ``,
        {
            "query": {
                "bool": {
                    "must": [
                        {
                            "bool": {
                                "must": [
                                    {
                                        "term": {
                                            "use_yn": "Y"
                                        }
                                    }
                                ]
                            }
                        }
                    ]
                }
            },
            "from": 0,
            "size": 100
        },
        {
            headers: {
                'subUrl': `/eventcode/_search`,
                "Content-Type": "application/json",
            }
        }
    );

    return res.data;
}