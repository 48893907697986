import {Link, Outlet} from "react-router-dom";
import WhiteLogo from "../../resource/img/asset/logo/logo_white.png";

export default function UserMenuWrapper() {
    return (
        <>
            <header className="header__ black">
                <div className="inner">
                    <div className="hd__gng__area">
                        <div className="mbile_buger_btn"></div>
                        <Link to="/" className="logo">
                            <img className="w-full h-[56px] object-contain" src={WhiteLogo}/>
                        </Link>
                    </div>
                </div>
            </header>
            <div className="container__">
                <Outlet/>
            </div>
        </>
    )
}