import {useDispatch, useSelector} from "react-redux";
import {
    clearDownloadDelete,
    clearRequestVODDownload,
    deleteDownload,
    setDownloadVideoLogSearchParam
} from "../../../../modules/userReducer";
import {useEffect} from "react";
import {ShowAlertModal} from "../../../../common/component/modal/AlertModal";

export default function useDownloadRequestDeleteWithModal(searchParam) {

    const dispatch = useDispatch();
    const deleteResult = useSelector(state => state.userReducer.deleteDownloadResult);

    useEffect(()=> {
        if(deleteResult?.error != null){
            ShowAlertModal({
                message: '다운로드 요청 삭제중 오류가 발생했습니다',
            })
            dispatch(clearDownloadDelete())
        }
        else if(deleteResult?.data != null){
            ShowAlertModal({
                message: '삭제 되었습니다.',
                onConfirm: () => {
                    // if(searchParam != null){
                    //     dispatch(setDownloadVideoLogSearchParam(searchParam))
                    // }
                }
            })
            dispatch(clearDownloadDelete());
        }
    }, [deleteResult])
}