import {createPromiseThunk, handleAsyncActions, reducerUtils,} from '../lib/asyncUtils';
import * as menuApi from "../apis/api/menu";
import SearchEngineResponse from "../apis/dto/SearchEngineResponse";
import {Menu, menuClassify} from "../lib/menu/menuUtil";

//액션 타입 정의
const API_ACTIONS = {
    GET_MENU_LIST: 'GET_MENU_LIST',
    GET_MENU_LIST_SUCCESS: 'GET_MENU_LIST_SUCCESS',
    GET_MENU_LIST_ERROR: 'GET_MENU_LIST_ERROR',
};

const ACTIONS = {
    // CLEAR_PATCH: 'CLEAR_PATCH',
    // CLEAR_DELETE: 'CLEAR_DELETE',
}

Object.freeze(API_ACTIONS);
Object.freeze(ACTIONS);

//외부에서 dispatch할 때 사용
export const getMenus = createPromiseThunk(API_ACTIONS.GET_MENU_LIST, menuApi.getMenus);
// export const clearPatch = () => ({type: ACTIONS.CLEAR_PATCH});
// export const clearDelete = () => ({type: ACTIONS.CLEAR_DELETE});

const initialState = {
    menus: reducerUtils.initial(),
    groupMenus: reducerUtils.initial(),
    mobileGroupMenus: reducerUtils.initial(),
};

/**
 * 영상업로드 관리에서 영상 목록을 불러오고 관리하거나, 영상관리에서 영상 파일 목록을 조회할때 사용
 * @param state
 * @param action
 * @returns {*|{videoFile: {data: null, loading: boolean, error: null}}}
 */
export default function menuReducer(state = initialState, action) {
    switch (action.type) {
        case API_ACTIONS.GET_MENU_LIST:
        case API_ACTIONS.GET_MENU_LIST_ERROR:
            return handleAsyncActions(API_ACTIONS.GET_MENU_LIST, 'menus')(state, action);


        case API_ACTIONS.GET_MENU_LIST_SUCCESS:
            const data = new SearchEngineResponse(action.payload.data).content;
            const menu = menuClassify(data);
            const groupMenus = [];
            const mobileGroupMenus = [];

            menu.forEach(it => {
                switch(it.contentType) {
                    case 'image': {
                        groupMenus.push(it);
                        mobileGroupMenus.push(it);
                    }
                    break;
                    case 'live': {
                        groupMenus.push(it);
                        mobileGroupMenus.push(it);
                    }
                    break;
                    case 'event':
                    case 'video': {
                        groupMenus.push(it);

                        let videoGroupMenu = mobileGroupMenus.find(it => it.contentType === '_group');
                        if(videoGroupMenu == null) {
                            let firstVideoMenu = menu.find(it => it.contentType === 'video');
                            videoGroupMenu = new Menu({
                                menuId: null,
                                menuNm: '영상',
                                depth: 0,
                                menuUrl: firstVideoMenu?.menuUrl,
                                contentType: '_group',
                                children: [it],
                            });
                            mobileGroupMenus.push(videoGroupMenu);
                        }
                        else {
                            videoGroupMenu.children.push(it);
                        }
                    }
                    break;
                    default: {
                        groupMenus.push(it);
                        mobileGroupMenus.push(it);
                    }
                }
            })

            console.log('contentType', menu);
            return {
                ...state,
                menus: reducerUtils.success(menu),
                groupMenus: reducerUtils.success(groupMenus),
                mobileGroupMenus: reducerUtils.success(mobileGroupMenus),
            }

        default:
            return state;
    }
}