import React, {useEffect, useState} from "react";
import GridPageIndicator, {SimpleGridPageIndicator} from "./GridPageIndicator";
import classNames from "classnames";
import {SimplePageableModel} from "../../../page/user/mypage/AlertListPage";
import NotFound from "../NotFound";

/**
 * Column Class
 * @param {string} key
 * @param {string} name
 * @param {boolean} [isClickable=false] - 테이블은 기본적으로 클릭하면 디테일 페이지로 가는데, 이걸 무시할지 여부 (체크박스, 버튼 등)
 */
export class Column {
    constructor(key, name, isClickable = false, width = null) {
        this.key = key;
        this.name = name;
        this.isClickable = isClickable;
        this.width = width;
    }
}

/**
 * PageableModel로 부터 페이지 인디케이터를 생성합니다.
 *
 * 내부 로직은 0번부터, 사용자 표출 로직은 1번 부터 페이징됩니다.
 *
 * columns의 요소에서 name이 일치하는 것들은 하나의 thread에 묶어 표시합니다.
 *
 * ⚠️ 묶어표시하는 것은 무조건 순차적으로 붙여서 명시해주세요!
 *
 * @param {PageableModel} pageableModel page를 위한 모델
 * @param {onMoveCallback} onMovePage 페이지 이동 이벤트
 * @param {[Column]} columns 컬럼 설정
 * @param {[T]} rows 컬럼에 맵핑되는 Row 정보
 * @param {function(T): K} rowKeyGenerate row가 되는 tr의 key 설정, 항목 선택시 사용됨
 * @param {function(T), Column} onRowClick row 클릭 시 이벤트, checkBox를 사용중이면 선책시에는 이벤트 전달 안함
 * @param {boolean} useCheckBox 선택용 checkBox 사용 유무
 * @param useClickSelect
 * @param {boolean} checkBoxInFirstColumn checkBox 사용 시 별도의 컬럼 추가 없이 첫번째 컬럼에 끼워넣는 유무
 * @param {Array<boolean>} initialCheckbox checkbox state의 초기값을 정의할 수 있습니다. index에 해당하는 체크박스에 체크할 수 있습니다.
 * @param {Set<K>} selectedRows
 * @param {function(Set<K>)} setSelectedRows
 * @param {React.Children} children 이 친구는 명시하는 prop이 아닙니다. 그냥 태그를 열고 넣어주면 돼요.
 * @param className 테이블 별로 사용하는 클래스가 다른 경우가 많습니다. 꼭 넣어주세요!
 * @template T, K
 */
export default function DataTable({
                                      pageableModel,
                                      onMovePage,
                                      columns,
                                      rows,
                                      rowKeyGenerate,
                                      onRowClick,
                                      useCheckBox = true,
                                      useClickSelect = true,
                                      checkBoxInFirstColumn = true,
                                      initialCheckbox = [],
                                      selectedRows,
                                      setSelectedRows,
                                      children,
                                      className = 'notice_table'
                                  }) {
    const [checkBox, setCheckBox] = useState(initialCheckbox);
    const [clickedRow, setClickedRow] = useState(null);
    const [initialized, setInitialized] = useState(false);

    const keyGenerate = (index, row) => {
        if (rowKeyGenerate) {
            return rowKeyGenerate(row)
        } else {
            return index;
        }
    }

    useEffect(() => {
        // row 정보 변경시 체크박스 초기화
        setCheckBox([])
        setSelectedRows(new Set([]));
    }, [rows]);

    const handleRowClick = (row, column) => {
        if (useCheckBox && column === columns[0]) {
            return
        }
        if (onRowClick) {
            if (useClickSelect) {
                if (clickedRow === row) {
                    setClickedRow(null);
                    onRowClick(null, null);
                } else {
                    setClickedRow(row);
                    onRowClick(row, column);
                }
            } else {
                onRowClick(row, column);
            }
        }
    }

    // useEffect(() => {
    //     // console.log(clickedRow);
    // }, [clickedRow]);

    const handleCheckAll = (e) => {
        const checked = e.target.checked;
        // console.log(rowKeys);
        if (checked) {
            setCheckBox(rows.map(row => true));
            if (setSelectedRows) {
                setSelectedRows(new Set(rows.map((row, index) => keyGenerate(index, row))));
            }
        } else {
            setCheckBox(rows.map(row => false));
            if (setSelectedRows) {
                setSelectedRows(new Set());
            }
        }
    }

    const handleCheck = (e, index) => {
        const key = rowKeyGenerate(rows[index]);
        const checked = e.target.checked;
        let newSelectedRows = new Set(selectedRows)

        const newCheckBox = [...checkBox];
        newCheckBox[index] = checked;
        setCheckBox(newCheckBox);

        if (checked) {
            newSelectedRows.add(key)
        } else {
            newSelectedRows.delete(key)
        }

        if (setSelectedRows) {
            setSelectedRows(
                newSelectedRows
            );
        }
    }

    const getValue = (key, row) => {
        const keyList = key.split(".");
        let keyIndex = 0;
        let value = row;
        while (true) {
            const nowKey = keyList[keyIndex];
            if (nowKey != null) {
                value = value?.[nowKey];
            } else {
                return value;
            }
            keyIndex++;
        }
    }

    const processSimpleModel = () => {
        if (pageableModel instanceof SimplePageableModel) {
            const model = {...pageableModel};
            model.totalPage = rows.length > 0 ? rows[0]._itemTotal / model._pageNo : 0;
            return model;
        } else {
            return pageableModel
        }
    }

    if (rows.length == 0) {
        return (
            <div className="box_inner h-full flex items-center justify-center">
                <div className="grid_box search_none">
                    <NotFound/>
                </div>
            </div>
        )
    }

    const filteredColumnNames = [...new Set(columns.map(column => column.name))];

    const table = <div className={`trans_table__area ${className} data-table`}>
        <div className="table__head">
            <dl>
                {
                    useCheckBox && !checkBoxInFirstColumn ? <dt>
                        <div className="checkbox box__type01">
                            <input type="checkbox" checked={!checkBox.includes(false) && checkBox.length === rows.length}
                                   onChange={handleCheckAll}/>
                            <label></label>
                        </div>
                    </dt> : <></>
                }
                {
                    filteredColumnNames.map((colName, index) =>
                        <dt
                            key={`header_${index}`}
                            style={{
                                width: columns.find(it => it.name == colName)?.width ?? 'unset',
                            }}
                        >
                            {
                                useCheckBox && checkBoxInFirstColumn && index === 0 ?
                                    <div className="checkbox box__type01">
                                        <input type="checkbox" checked={!checkBox.includes(false) && checkBox.length === rows.length}
                                               onChange={handleCheckAll}/>
                                        <label></label>
                                    </div>
                                    : <></>
                            }
                            {colName}
                        </dt>
                    )
                }
            </dl>

        </div>
        <div className="table__body">
            {
                rows.map((row, rowIndex) =>
                    <dl
                        key={keyGenerate(rowIndex, row)}
                        className={classNames({
                            'cursor-pointer': onRowClick != null,
                            'selected': row === clickedRow,
                        })}
                    >
                        {
                            useCheckBox && !checkBoxInFirstColumn ? <dd>
                                <div className="checkbox box__type01">
                                    <input type="checkbox" className="input_check" checked={checkBox[rowIndex]}
                                           onChange={(e) => handleCheck(e, rowIndex)}/>
                                    <label/>
                                </div>

                            </dd> : <></>
                        }

                        {
                            columns.map((column, columnIndex) =>
                                <dd key={`columnIndex_${columnIndex}`}
                                    onClick={() => {
                                        if (!column.isClickable) handleRowClick(row, column);
                                    }}

                                    style={{
                                        width: column.width,
                                        justifyContent: 'center',
                                    }}
                                >
                                    {
                                        useCheckBox && checkBoxInFirstColumn && columnIndex === 0 ?
                                            <input type="checkbox" className="input_check"
                                                   checked={checkBox[rowIndex]}
                                                   onChange={(e) => handleCheck(e, rowIndex)}/> : <></>
                                    }
                                    {getValue(column.key, row)}
                                    {/*{row[column.key]}*/}
                                </dd>
                            )
                        }
                    </dl>
                )
            }
        </div>
    </div>

    return (
        <div className="flex flex-col">
            <div style={{
                flex: '1',
            }}>
                {table}
            </div>
            <div className="content_controll">
                {children}
                <SimpleGridPageIndicator
                    model={pageableModel}
                    onMove={onMovePage}/>
            </div>
        </div>
    );
}