import DateExtension from './DateExtension'


export const validateEmail = (email) => {
    return email.match(
        /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
};

export const validatePassword = (password) => {
    return password.match(
        /(?=.*\d)(?=.*[A-Za-z]).{8,}/
    );
};

export const filterExceptNumbers = (str) => str.replaceAll(/[a-zA-Z!@#$%^&*+();?,.<>`~\\|=-]/g, "")

export const setsAreEqual = (a, b) => {
    let aa = a;
    let bb = b;
    if(!aa){
        aa = new Set();
    }
    if(!bb){
        bb = new Set();
    }
    if (aa.size !== bb.size) {
        return false;
    }

    return Array.from(aa).every(element => {
        return bb.has(element);
    });
}


export const arrayAreEqual = (a, b) => {
    let aa = a;
    let bb = b;
    if(!aa){
        aa = [];
    }
    if(!bb){
        bb = [];
    }
    if (aa.length !== bb.length) {
        return false;
    }

    return Array.from(aa).every(element => {
        return bb.includes(element);
    });
}
