import {apiInstance} from "../utils";

export const videoUploadStart = (param)=>{
    return apiInstance.post(
        `/api/cms/user/videoUploadStart`,
        param,
    );
}

export const videoUploadUpdate = (param)=>{
    return apiInstance.post(
        `/api/cms/user/videoUploadUpdate`,
        param,
    );
}


export const imageUploadStart = (param)=>{
    return apiInstance.post(
        `/api/cms/user/imageUploadStart`,
        param,
    );
}

export const imageUploadUpdate = (param)=>{
    return apiInstance.post(
        `/api/cms/user/imageUploadUpdate`,
        param,
    );
}