import {createPromiseSaga, createPromiseThunk, handleAsyncActions, reducerUtils,} from '../lib/asyncUtils';
import * as videoApi from "../apis/api/video";
import {takeEvery, select, put, takeLatest, call} from 'redux-saga/effects';
import * as eventApi from "../apis/api/event";

//액션 타입 정의
const API_ACTIONS = {
    GET_VIDEO_CONTENT: 'GET_VIDEO_CONTENT',
    GET_VIDEO_CONTENT_SUCCESS: 'GET_VIDEO_CONTENT_SUCCESS',
    GET_VIDEO_CONTENT_ERROR: 'GET_VIDEO_CONTENT_ERROR',

    GET_EVENT_LIST: 'GET_EVENT_LIST',
    GET_EVENT_LIST_ERROR: 'GET_EVENT_LIST_ERROR',
    GET_EVENT_LIST_SUCCESS: 'GET_EVENT_LIST_SUCCESS',

    GET_EVENT_LIST_FOR_EVENT_PLAY: 'GET_EVENT_LIST_FOR_EVENT_PLAY',
    GET_EVENT_LIST_FOR_EVENT_PLAY_ERROR: 'GET_EVENT_LIST_FOR_EVENT_PLAY_ERROR',
    GET_EVENT_LIST_FOR_EVENT_PLAY_SUCCESS: 'GET_EVENT_LIST_FOR_EVENT_PLAY_SUCCESS',

};

const SAGA_ACTIONS = {
    GET_VIDEO_BY_EVENT: 'GET_VIDEO_BY_EVENT', // GET_VIDEO_CONTENT 로 응답
    SET_EVENT_ONE: 'SET_EVENT_ONE', // state.eventOne 값 지정
}

const ACTIONS = {
    CLEAR_VIDEO: 'CLEAR_VIDEO',
    SET_SELECT_EVENT: 'SET_SELECT_EVENT',
    // CLEAR_PATCH: 'CLEAR_PATCH',
    // CLEAR_DELETE: 'CLEAR_DELETE',
}

Object.freeze(API_ACTIONS);
Object.freeze(SAGA_ACTIONS);
Object.freeze(ACTIONS);

//외부에서 dispatch할 때 사용
export const getVideo = createPromiseThunk(API_ACTIONS.GET_VIDEO_CONTENT, videoApi.getVideo);
export const getEventList = createPromiseThunk(API_ACTIONS.GET_EVENT_LIST, eventApi.getEventList);
export const getEventListForEventPlayer = createPromiseThunk(API_ACTIONS.GET_EVENT_LIST_FOR_EVENT_PLAY, eventApi.searchEvent);
export const clearVideo = ()=> ({type: ACTIONS.CLEAR_VIDEO});
export const selectEvent = (event)=> ({type: ACTIONS.SET_SELECT_EVENT, event});

export const getVideoByEvent = (eventId) => ({type: SAGA_ACTIONS.GET_VIDEO_BY_EVENT, payload: eventId}); //  getVideoInEventOne 실행

export function* videoSaga() {
    yield takeEvery(SAGA_ACTIONS.GET_VIDEO_BY_EVENT, getVideoInEventOne);
}

/**
 * 이벤트 플레이어를 위한 정보 조회
 * 이벤트 ID로 경기 정보 조회
 * @returns {Generator<*, *, Generator<*, *, *>>}
 */
function* getVideoInEventOne () {
    yield put({type: API_ACTIONS.GET_VIDEO_CONTENT});
    yield put({type: API_ACTIONS.GET_EVENT_LIST});
    const eventId = yield select(state => state.videoReducer.eventId);
    if(eventId == null){
        return yield put({type: API_ACTIONS.GET_VIDEO_CONTENT_ERROR, payload: '해당 이벤트 정보를 찾을 수 없습니다.'})
    }
    const eventData = yield call(eventApi.getEventOne, eventId); // 이벤트 단건 조회
    yield put({type: SAGA_ACTIONS.SET_EVENT_ONE, payload: eventData});
    const cid = eventData?.data?._source?.cid;
    // console.log('eventData', cid, eventData);
    if(cid == null){
        // FIXME handleContentCode를 위해 response를 넣었지만, 오류를 명백하게 리턴.
        return yield put({type: API_ACTIONS.GET_VIDEO_CONTENT_SUCCESS, payload: eventData?.data})
    }
    const video = yield call(videoApi.getVideo, cid);
    const videoData = video?.data?._source;
    // console.log('video', video);
    if(videoData == null){
        // FIXME handleContentCode를 위해 response를 넣었지만, 오류를 명백하게 리턴.
        return yield put({type: API_ACTIONS.GET_VIDEO_CONTENT_SUCCESS, payload: video?.data})
    }

    return yield put({type: API_ACTIONS.GET_VIDEO_CONTENT_SUCCESS, payload: video});
}


const initialState = {
    videoContent: reducerUtils.initial(),
    eventList: reducerUtils.initial(),
    eventListForEventPlay: reducerUtils.initial(),
    eventOne: null,
    eventId: null, // GET_VIDEO_BY_EVENT
    selectEvent: null,
};

/**
 * 영상업로드 관리에서 영상 목록을 불러오고 관리하거나, 영상관리에서 영상 파일 목록을 조회할때 사용
 * @param state
 * @param action
 * @returns {*|{videoFile: {data: null, loading: boolean, error: null}}}
 */
export default function videoReducer(state = initialState, action) {
    switch (action.type) {
        case API_ACTIONS.GET_VIDEO_CONTENT:
        case API_ACTIONS.GET_VIDEO_CONTENT_ERROR:
        case API_ACTIONS.GET_VIDEO_CONTENT_SUCCESS:
            return handleAsyncActions(API_ACTIONS.GET_VIDEO_CONTENT, 'videoContent')(state, action);

        case API_ACTIONS.GET_EVENT_LIST:
        case API_ACTIONS.GET_EVENT_LIST_ERROR:
        case API_ACTIONS.GET_EVENT_LIST_SUCCESS:
            return handleAsyncActions(API_ACTIONS.GET_EVENT_LIST, 'eventList')(state, action);

        case API_ACTIONS.GET_EVENT_LIST_FOR_EVENT_PLAY:
        case API_ACTIONS.GET_EVENT_LIST_FOR_EVENT_PLAY_ERROR:
        case API_ACTIONS.GET_EVENT_LIST_FOR_EVENT_PLAY_SUCCESS:
            return handleAsyncActions(API_ACTIONS.GET_EVENT_LIST_FOR_EVENT_PLAY, 'eventListForEventPlay')(state, action);

        case ACTIONS.SET_SELECT_EVENT:
            return {
                ...state,
                selectEvent: action.event,
            }

        case SAGA_ACTIONS.SET_EVENT_ONE: {
            return {
                ...state,
                eventOne: action.payload,
            }
        }

        case ACTIONS.CLEAR_VIDEO:
            return {
                ...state,
                videoContent: reducerUtils.initial(),
                eventList: reducerUtils.initial(),
                eventListForEventPlay: reducerUtils.initial(),
                eventOne: null,
                eventId: null,
                selectEvent: null,
            }


        case SAGA_ACTIONS.GET_VIDEO_BY_EVENT: {
            return {
                ...state,
                eventId: action.payload,
            }
        }

        // case SAGA_ACTIONS.GET_EVENT_ONE:
        // case SAGA_ACTIONS.GET_EVENT_ONE_ERROR:
        // case SAGA_ACTIONS.GET_EVENT_ONE_SUCCESS:
        //     return handleAsyncActions(SAGA_ACTIONS.GET_EVENT_ONE, 'eventOne')(state, action);


        default:
            return state;
    }
}