import AlertListTable from "../../../common/component/user/AlertListTable";
import {useDispatch, useSelector} from "react-redux";
import {useEffect, useState} from "react";
import {clearAlertDelete, deleteAlert, setAlertSearchParam} from "../../../modules/userReducer";
import {ShowAlertModal} from "../../../common/component/modal/AlertModal";

/**
 * @param _pageNo
 * @param _pageSize
 * @param content
 * @property totalPage
 */
export class SimplePageableModel {
    constructor({_pageNo, _pageSize, _itemTotal, content}) {
        this._pageNo = _pageNo;
        this._pageSize = _pageSize;
        this._itemTotal = _itemTotal;
        this.content = content;
        this.totalPage = content != null && content.length > 0 ? content[0]._itemTotal / _pageSize : 0;
    }
}

export class AlertSearchParam {
    constructor({_pageNo = 1, _pageSize = 1, uid}) {
        this._pageNo = _pageNo;
        this._pageSize = _pageSize;
        this.uid = uid;
    }
}

export default function AlertListPage() {
    const {data, loading, error} = useSelector(state => state.userReducer.alertList);
    const alertDeleteResult = useSelector(state => state.userReducer.alertDeleteResult);
    const loginData = useSelector(state => state.userReducer.loginData);
    const [page, setPage] = useState(null);
    const dispatch = useDispatch();

    useEffect(() => {
        if (loginData != null) {
            setPage(1);
        }
    }, [loginData]);

    useEffect(()=> {
        if(page == null || page < 1){
            return;
        }
        dispatch(setAlertSearchParam(new AlertSearchParam({
            uid: loginData.uid,
            _pageNo: page,
            _pageSize: 10,
        })));
    }, [page])

    useEffect(() => {
        console.log("alertList: ", data)
    }, [data])

    useEffect(() => {
        if(alertDeleteResult.error){
            ShowAlertModal({
                message: '알림 삭제에 실패하였습니다',
            });
            dispatch(clearAlertDelete());
        }
        else if(alertDeleteResult.data) {
            ShowAlertModal({
                message: '알림이 삭제되었습니다.',
            });
            dispatch(clearAlertDelete());
            dispatch(setAlertSearchParam(new AlertSearchParam({
                uid: loginData.uid,
                _pageNo: page,
                _pageSize: 10,
            })));
        }
    }, [alertDeleteResult])

    const onPageMove = (event) => {
        setPage(event+1);
    }
    const onDelete = (rows) => {
        dispatch(deleteAlert([...rows]))
    }

    return <>
        {
            data && !loading && !error && <AlertListTable notiData={data} onPageMove={onPageMove} onDelete={onDelete}/>
        }
    </>
}